import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Globals } from '../../globals';
import { LabelSettings, LabelFn } from '@progress/kendo-angular-progressbar';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { AssessmentServiceService } from 'src/app/services/assessment-service.service'; 
import { ConfigurationService } from '../../services/configuration.service';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { SortDescriptor, process } from '@progress/kendo-data-query';
//import { Label } from '@amcharts/amcharts4/core';
declare var $, introJs, Swal: any;

@Component({
  selector: 'app-assessment',
  templateUrl: './assessment.component.html',
  styleUrl: './assessment.component.css'
})
export class AssessmentComponent implements OnInit {
  renewAssesmentId:any;


  @ViewChild('mainBlock', { static: false }) mainBlock!: ElementRef;

  public surveyQuestionOptions: Array<string> = ['General Medical', 'General Surgical', 'Cardiovascular', 'Respiratory', 'Gastrointestinal', 'Orthopedic', 'Neurological', 'Oncology', 'Infection Control', 'Long-Term Acute Care'];
  public value = 0;
  public max = 0;
  public dmnlabel: LabelSettings = {};
  public domainFormattedLabels = [];
  public percentage: any = 0;
  IsGridView: boolean = true;
  public formatter: LabelFn = (value: number): string => {

    // 
    return `${value}/${this.assessmentDetails.no_of_question} Completed (${this.assessmentDetails.progress}%)`;
    // return `${value}/${this.assessmentDetails.no_of_question} Assessment Completed (${this.assessmentDetails.progress}%)`;
    // return `Your overall progress: ${this.assessmentDetails.progress}%`;
  }

  public opened = false;

  public close(status: string): void {
    this.opened = false;
    this.renderer.removeClass(document.body, 'k-overflow-hidden');
  }

  public open(): void {
    this.opened = true;
    this.renderer.addClass(document.body, 'k-overflow-hidden');
  }

  createLabelFormatter(no_of_question: number, progress: number): LabelSettings {
    return {
      format: (value: number): string => {
        return `${value}/${no_of_question} Completed (${progress}%)`;
      },
      position: 'center'
    }
  }


  public label: LabelSettings = {
    format: this.formatter,
    position: 'center'
  };

  public label1: LabelSettings = {
    visible: true,
    format: 'percent',
    position: 'center'
  };

  domain_block: boolean = true;
  subdomain_block: boolean = false;
  item_block: boolean = false;
  assessmentData: any = [];
  rowAssessmentData: any;
  currentDomain: any = { 'subdomain': [] };
  domain_index = 0;
  domainData: any;
  assessmentDetails: any = { 'id': null, 'attempt_question': 0, 'no_of_question': 0, 'progress': 0, 'valid': false };
  ratingList: any;
  ratingEntity: any = [];
  HideCompleted = false;
  SurveyQuestions: any = [];
  isEditAssesment: any = false;

  constructor(public globals: Globals, private router: Router, private route: ActivatedRoute, private formBuilder: UntypedFormBuilder, private AssessmentService: AssessmentServiceService, private ConfigurationService: ConfigurationService, private renderer: Renderer2) { }

  ngOnInit(): void {

    var currentUrl = this.router.url;

    if (currentUrl.includes('/assessment/edit-assessment/')) {
      this.isEditAssesment = true;
    }
    else {
      this.isEditAssesment = false;
    }


    this.dmnlabel = {
      format: this.formatter,
      position: "center",
    };

    this.assessmentDetails.id = this.route.snapshot.paramMap.get('id');
    $("#domain_block").hide();
    $("#subdomain_block").hide();
    $("#item_block").hide();
    $("#help_btn").hide();
    this.getConfigData();
    this.getData();

    // this.getLocalData();
    $('.rate_stars input[type="radio"]').change(function (e) {
      $('.rating_input').val(this.value);
    });

    // introJs().setOptions({
    //   exitOnOverlayClick: false,
    //   exitOnEsc: false,
    //   doneLabel: 'Close',
    //   showBullets: false,
    //   showProgress: true
    // }
    // ).start();
    if (screen.width < 991) {
      $('.mobile_subdomain').attr('data-title', 'Subdomains');
    //   $('.mobile_subdomain').attr('data-intro', 'This displays your overall progress for each of the subdomains within this domain.');
    }
    else {
      $('#thumbs-block').attr('data-title', 'Subdomains');
    //   $('#thumbs-block').attr('data-intro', 'This displays your overall progress for each of the subdomains within this domain.');
    }

  }

  help(currentItem) {
    if (currentItem == null) {
      currentItem = parseInt($('#thumbs-block').find('.synced > div:first-child').attr('id'));
    }
    $('.subdomain_items').removeAttr('data-title');
    $('.subdomain_items').removeAttr('data-intro',);
    let id = '#item_' + currentItem + '_' + 0;
    $(id).attr('data-title', 'Knowledge Statements');
    // $(id).attr('data-intro', 'For each knowledge statement, a 5-point proficiency rating scale is provided. Please use the scale to honestly rate each knowledge statement.');

    $('.domain_items').removeAttr('data-title');
    $('.domain_items').removeAttr('data-intro',);
    let id1 = '#assdomain_0';
    $(id1).attr('data-title', 'Domains');
    // $(id1).attr('data-intro', 'This demonstrates your progress to completion of the domain.');

    $('.rating_item').removeAttr('data-title');
    $('.rating_item').removeAttr('data-intro',);

    console.log(currentItem)
    //alert(currentItem);
    //currentItem = currentItem + 1;
    // alert(currentItem);
    for(var i=0;i<=this.ratingList.length;i++){
      // console.log(this.ratingList[i].display_text)
      let id2 = '#rating' + currentItem + '_' + i;
      //  alert(id2);
    
        $(id2).attr('data-title', this.ratingList[i]?.display_text);
        $(id2).attr('data-intro', this.ratingList[i]?.description);
    }
  //   let id2 = '#rating' + currentItem + '_' + 0;
  // //  alert(id2);

  //   $(id2).attr('data-title', 'Rating1');
  //   $(id2).attr('data-intro', 'description1');

    setTimeout(() => {
      var sync1 = $("#main-block");
      var current = currentItem;
      $("#thumbs-block")
        .find(".owl-item")[0]
        .removeClass("synced")
        .eq(current)
        .addClass("synced");
      sync1.trigger("owl.goTo", currentItem);
    }, 100);
    introJs().setOptions({
      exitOnOverlayClick: false,
      exitOnEsc: false,
      doneLabel: 'Close',
      showBullets: false,
      showProgress: true
    }
    ).start();
  }

  getData() {
    this.AssessmentService.getQuestions()
      .then((data: any) => {
  
        this.rowAssessmentData = data['data'];
        this.globals.isLoading = false;
        this.rowAssessmentData.domain = this.rowAssessmentData.domain;
        this.rowAssessmentData.domain.forEach((d_element, d_index, d_arr) => {
          d_element.subdomain = d_element.subdomain;
        });
        this.prepareData();
      },
        (error) => {
          this.globals.isLoading = false;
          this.router.navigate(['/assessment/my-assessments']);
        });

  }



  
//   getData() {
//     this.rowAssessmentData = 
//         {
//             "institute_name": null,
//             "assign_date": "2024-11-13T12:37:06Z",
//             "expire_date": "2025-11-13T12:37:06Z",
//             "no_of_question": 629,
//             "attempt_question": 0,
//             "domain": [
//                 {
//                     "id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                     "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                     "name": "Leadership & Strategies",
//                     "domain_sequence": 1,
//                     "start_time": null,
//                     "complete_time": null,
//                     "updated_at": "2024-04-05T15:45:13Z",
//                     "subdomain": [
//                         {
//                             "id": "dbff2144-6902-435a-a503-c9fd09de3bba",
//                             "subdomain_id": "d8d27cb8-df8e-40dd-ac7a-472fcc1176a1",
//                             "start_time": null,
//                             "complete_time": null,
//                             "updated_at": "2024-11-14T05:58:09Z",
//                             "name": "Strategic planning and alignment with the demand organization",
//                             "subdomain_sequence": 1
//                         },
//                         {
//                             "id": "ca9203c7-ec01-4e80-824f-d3a566e38f79",
//                             "subdomain_id": "82964658-2d6e-4cf6-bc2b-ff1a90b7c4c7",
//                             "start_time": null,
//                             "complete_time": null,
//                             "updated_at": "2024-11-14T05:58:09Z",
//                             "name": "Leadership",
//                             "subdomain_sequence": 2
//                         },
//                         {
//                             "id": "16f84455-1890-4f44-8929-67d418a14148",
//                             "subdomain_id": "4dd23013-72a4-47fd-bd1d-31508d184c94",
//                             "start_time": null,
//                             "complete_time": null,
//                             "updated_at": "2024-11-14T05:58:09Z",
//                             "name": "Change management",
//                             "subdomain_sequence": 3
//                         },
//                         {
//                             "id": "f33f3a2f-4c51-4b95-9cbc-53989bd40aa9",
//                             "subdomain_id": "6aa34898-b28e-4d54-9cc9-a086e4a42b95",
//                             "start_time": null,
//                             "complete_time": null,
//                             "updated_at": "2024-11-14T05:58:09Z",
//                             "name": "Relationship management and conflict management",
//                             "subdomain_sequence": 4
//                         },
//                         {
//                             "id": "2971bcaa-fced-4a51-938f-defaed89e9a7",
//                             "subdomain_id": "0e3053af-6db4-4f51-8470-da811aa01fb7",
//                             "start_time": null,
//                             "complete_time": null,
//                             "updated_at": "2024-11-14T05:58:09Z",
//                             "name": "Individual and team leadership management",
//                             "subdomain_sequence": 5
//                         },
//                         {
//                             "id": "f136877e-ea1c-4bcb-9177-fbf0810f5816",
//                             "subdomain_id": "4dc6d34e-2df4-4928-88ad-66c0f7b5dc8f",
//                             "start_time": null,
//                             "complete_time": null,
//                             "updated_at": "2024-11-14T05:58:09Z",
//                             "name": "Political, social, economic, and industry factors affecting facility management",
//                             "subdomain_sequence": 6
//                         }
//                     ]
//                 },
//                 {
//                     "id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                     "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                     "name": "Facility Operations",
//                     "domain_sequence": 2,
//                     "start_time": null,
//                     "complete_time": null,
//                     "updated_at": "2024-04-05T15:45:24Z",
//                     "subdomain": [
//                         {
//                             "id": "1ea75936-126f-45b1-93d5-fbc891743c0e",
//                             "subdomain_id": "e1dc01a6-b63d-478d-9ab5-d2c95a6692a7",
//                             "start_time": null,
//                             "complete_time": null,
//                             "updated_at": "2024-11-14T05:58:09Z",
//                             "name": "Asset management",
//                             "subdomain_sequence": null
//                         },
//                         {
//                             "id": "3a2aed52-64ef-43c7-95d8-8614a78181c5",
//                             "subdomain_id": "5d740cb0-c2c7-4cd5-99b0-ef1112a30429",
//                             "start_time": null,
//                             "complete_time": null,
//                             "updated_at": "2024-11-14T05:58:09Z",
//                             "name": "Buildings, Building Systems, and Infrastructure and Grounds",
//                             "subdomain_sequence": null
//                         },
//                         {
//                             "id": "3a65027e-c842-4361-a933-28440f30173c",
//                             "subdomain_id": "32829d45-7ee4-4e62-b993-0fa21f49db23",
//                             "start_time": null,
//                             "complete_time": null,
//                             "updated_at": "2024-11-14T05:58:09Z",
//                             "name": "Furniture, fixtures and equipment (FF&E)",
//                             "subdomain_sequence": null
//                         },
//                         {
//                             "id": "6363dadd-bf37-47bc-94be-a0adc2a14289",
//                             "subdomain_id": "dfe64514-aa79-42b4-991c-cc0a41cf2ab3",
//                             "start_time": null,
//                             "complete_time": null,
//                             "updated_at": "2024-11-14T05:58:09Z",
//                             "name": "Work management systems",
//                             "subdomain_sequence": null
//                         },
//                         {
//                             "id": "6e378175-fe60-44b9-a6f1-d28dc7310d52",
//                             "subdomain_id": "561cc4ce-3614-411d-b0a4-384177e2bddd",
//                             "start_time": null,
//                             "complete_time": null,
//                             "updated_at": "2024-11-14T05:58:09Z",
//                             "name": "Operations processes",
//                             "subdomain_sequence": null
//                         },
//                         {
//                             "id": "9007f6f9-6dbf-4830-861a-226efde7d645",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "start_time": null,
//                             "complete_time": null,
//                             "updated_at": "2024-11-14T05:58:09Z",
//                             "name": "Occupant Health, Safety, and Security",
//                             "subdomain_sequence": null
//                         },
//                         {
//                             "id": "eeb5a648-5507-470d-9d25-031c9164e4b8",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "start_time": null,
//                             "complete_time": null,
//                             "updated_at": "2024-11-14T05:58:09Z",
//                             "name": "Maintenance Processes",
//                             "subdomain_sequence": null
//                         }
//                     ]
//                 },
//                 {
//                     "id": "92b8217d-7870-47c4-8234-251277657ec8",
//                     "domain_id": "92b8217d-7870-47c4-8234-251277657ec8",
//                     "name": "Risk Management",
//                     "domain_sequence": 3,
//                     "start_time": null,
//                     "complete_time": null,
//                     "updated_at": "2024-04-05T15:45:43Z",
//                     "subdomain": [
//                         {
//                             "id": "b78f45ca-8dee-4d3e-afd4-24372c628802",
//                             "subdomain_id": "64c16135-488a-487a-bcad-9c47661a16da",
//                             "start_time": null,
//                             "complete_time": null,
//                             "updated_at": "2024-11-14T05:58:09Z",
//                             "name": "Emergency preparedness, response and recovery",
//                             "subdomain_sequence": null
//                         },
//                         {
//                             "id": "ddf8c36a-3d65-4575-a19e-3f19b0e01cf5",
//                             "subdomain_id": "326c6bc1-f4aa-41d7-8171-b73d6487ac7e",
//                             "start_time": null,
//                             "complete_time": null,
//                             "updated_at": "2024-11-14T05:58:09Z",
//                             "name": "Risk management planning",
//                             "subdomain_sequence": null
//                         },
//                         {
//                             "id": "f08d9216-f741-4304-b3d0-7f857c787ccd",
//                             "subdomain_id": "33f5e305-8d63-435a-991c-76f44b23399a",
//                             "start_time": null,
//                             "complete_time": null,
//                             "updated_at": "2024-11-14T05:58:09Z",
//                             "name": "Facility resilience and business continuity",
//                             "subdomain_sequence": null
//                         }
//                     ]
//                 },
//                 {
//                     "id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                     "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                     "name": "Finance & Business",
//                     "domain_sequence": 4,
//                     "start_time": null,
//                     "complete_time": null,
//                     "updated_at": "2024-04-05T15:46:15Z",
//                     "subdomain": [
//                         {
//                             "id": "4c25a245-3b00-4365-8650-62af5b8731b6",
//                             "subdomain_id": "1a602d75-3e86-4169-8b04-27299371bf78",
//                             "start_time": null,
//                             "complete_time": null,
//                             "updated_at": "2024-11-14T05:58:09Z",
//                             "name": "Operational and Capital Budgeting",
//                             "subdomain_sequence": null
//                         },
//                         {
//                             "id": "65fcd556-758e-4a18-966c-186dc2aed43b",
//                             "subdomain_id": "281db73a-f8b3-4652-adc1-a12014ba5810",
//                             "start_time": null,
//                             "complete_time": null,
//                             "updated_at": "2024-11-14T05:58:09Z",
//                             "name": "Financial Analysis and Reporting",
//                             "subdomain_sequence": null
//                         },
//                         {
//                             "id": "a1ce3b65-b196-4c2e-9358-ef4e9ea757ea",
//                             "subdomain_id": "24bfd1bb-9f01-4ef2-b049-11c9dbbbf663",
//                             "start_time": null,
//                             "complete_time": null,
//                             "updated_at": "2024-11-14T05:58:09Z",
//                             "name": "Evidence-based Decision Making",
//                             "subdomain_sequence": null
//                         },
//                         {
//                             "id": "a2186728-5967-4762-ac65-3e0b3b43e9ab",
//                             "subdomain_id": "19c02028-abf9-4968-bf88-63fdd6cc5a88",
//                             "start_time": null,
//                             "complete_time": null,
//                             "updated_at": "2024-11-14T05:58:09Z",
//                             "name": "Procurement and Contracting Strategies",
//                             "subdomain_sequence": null
//                         }
//                     ]
//                 },
//                 {
//                     "id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                     "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                     "name": "Sustainability",
//                     "domain_sequence": 5,
//                     "start_time": null,
//                     "complete_time": null,
//                     "updated_at": "2024-04-05T15:46:31Z",
//                     "subdomain": [
//                         {
//                             "id": "480c0115-bb06-47c6-9211-4e8fe901932a",
//                             "subdomain_id": "50f157d0-944a-49ab-ba08-39ec1f133405",
//                             "start_time": null,
//                             "complete_time": null,
//                             "updated_at": "2024-11-14T05:58:09Z",
//                             "name": "Water",
//                             "subdomain_sequence": null
//                         },
//                         {
//                             "id": "57439b04-bda0-4814-8058-124487a4b0f8",
//                             "subdomain_id": "0edfd78e-445f-4a4d-b227-9d0d833fa104",
//                             "start_time": null,
//                             "complete_time": null,
//                             "updated_at": "2024-11-14T05:58:09Z",
//                             "name": "Measure and Monitor",
//                             "subdomain_sequence": null
//                         },
//                         {
//                             "id": "66749a4e-cb83-414f-a0dd-02e0c4f5e017",
//                             "subdomain_id": "f48d945a-5965-467e-bddc-7eb20c12384a",
//                             "start_time": null,
//                             "complete_time": null,
//                             "updated_at": "2024-11-14T05:58:09Z",
//                             "name": "Waste",
//                             "subdomain_sequence": null
//                         },
//                         {
//                             "id": "833fda28-2982-43f6-bdf6-f1d50aeea973",
//                             "subdomain_id": "d1e258e6-dcc7-456c-9c8e-9c331f4b6ee5",
//                             "start_time": null,
//                             "complete_time": null,
//                             "updated_at": "2024-11-14T05:58:09Z",
//                             "name": "Sustainable facility management",
//                             "subdomain_sequence": null
//                         },
//                         {
//                             "id": "83a0688c-874d-4512-89d1-05cc8e2c0bff",
//                             "subdomain_id": "ac1c8c2b-0de4-4ffa-b5f1-7f28505ee8c3",
//                             "start_time": null,
//                             "complete_time": null,
//                             "updated_at": "2024-11-14T05:58:09Z",
//                             "name": "Energy",
//                             "subdomain_sequence": null
//                         },
//                         {
//                             "id": "83c9bb3d-a2a0-41f6-8595-6b3b0c9ba784",
//                             "subdomain_id": "79d96da6-1a59-42f3-a6ac-295b00bf34fc",
//                             "start_time": null,
//                             "complete_time": null,
//                             "updated_at": "2024-11-14T05:58:09Z",
//                             "name": "Site management",
//                             "subdomain_sequence": null
//                         },
//                         {
//                             "id": "e3ccd94f-d0c7-4abc-bb0c-58ed572b043d",
//                             "subdomain_id": "2044d14b-765c-4493-99e7-d0123d00ca98",
//                             "start_time": null,
//                             "complete_time": null,
//                             "updated_at": "2024-11-14T05:58:09Z",
//                             "name": "Wellness",
//                             "subdomain_sequence": null
//                         },
//                         {
//                             "id": "f51a18d2-7d99-479e-949b-28f57c20d73f",
//                             "subdomain_id": "94bde05f-bacf-4bc7-9dbd-4ac8a824d543",
//                             "start_time": null,
//                             "complete_time": null,
//                             "updated_at": "2024-11-14T05:58:09Z",
//                             "name": "Materials and Consumables",
//                             "subdomain_sequence": null
//                         }
//                     ]
//                 },
//                 {
//                     "id": "1832c147-8232-46f7-ae25-4e37dd049c85",
//                     "domain_id": "1832c147-8232-46f7-ae25-4e37dd049c85",
//                     "name": "Communication",
//                     "domain_sequence": 6,
//                     "start_time": null,
//                     "complete_time": null,
//                     "updated_at": "2024-04-05T15:46:45Z",
//                     "subdomain": [
//                         {
//                             "id": "f4f399e9-8af1-4836-92c4-972bd1bf3e9a",
//                             "subdomain_id": "39299ac0-2f57-418b-be74-63420bf9c328",
//                             "start_time": null,
//                             "complete_time": null,
//                             "updated_at": "2024-11-14T05:58:09Z",
//                             "name": "Communication management",
//                             "subdomain_sequence": null
//                         }
//                     ]
//                 },
//                 {
//                     "id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                     "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                     "name": "Quality",
//                     "domain_sequence": 7,
//                     "start_time": null,
//                     "complete_time": null,
//                     "updated_at": "2024-04-05T15:46:57Z",
//                     "subdomain": [
//                         {
//                             "id": "4f3589a2-c363-447c-930c-3b2c97677a89",
//                             "subdomain_id": "d7452919-bef6-498a-abf1-6ac26a3df0da",
//                             "start_time": null,
//                             "complete_time": null,
//                             "updated_at": "2024-11-14T05:58:09Z",
//                             "name": "Quality improvement",
//                             "subdomain_sequence": null
//                         },
//                         {
//                             "id": "b9687a95-0617-4188-8b16-055c84c3cc14",
//                             "subdomain_id": "a6081fb2-7af5-44ac-b195-3ae0adee021b",
//                             "start_time": null,
//                             "complete_time": null,
//                             "updated_at": "2024-11-14T05:58:09Z",
//                             "name": "Quality management",
//                             "subdomain_sequence": null
//                         }
//                     ]
//                 },
//                 {
//                     "id": "672a939f-344f-40eb-a897-c60987831da2",
//                     "domain_id": "672a939f-344f-40eb-a897-c60987831da2",
//                     "name": "Real Estate",
//                     "domain_sequence": 8,
//                     "start_time": null,
//                     "complete_time": null,
//                     "updated_at": "2024-04-05T15:47:09Z",
//                     "subdomain": [
//                         {
//                             "id": "1830bf73-e2da-41c1-8ec7-9a74745aeaf5",
//                             "subdomain_id": "12824b8b-1a17-482a-9c97-0963708e386e",
//                             "start_time": null,
//                             "complete_time": null,
//                             "updated_at": "2024-11-14T05:58:09Z",
//                             "name": "Functional Programming\/Planning",
//                             "subdomain_sequence": null
//                         },
//                         {
//                             "id": "57fa323d-3927-4bda-9257-bf7170be75fc",
//                             "subdomain_id": "e3da8b31-eebd-4143-b4c7-e6c71fc28057",
//                             "start_time": null,
//                             "complete_time": null,
//                             "updated_at": "2024-11-14T05:58:09Z",
//                             "name": "Master Planning",
//                             "subdomain_sequence": null
//                         },
//                         {
//                             "id": "5ac87ab3-a2a0-4b6a-bfe5-9931e6561f89",
//                             "subdomain_id": "a489498c-e604-4451-965a-84bd5b2e601f",
//                             "start_time": null,
//                             "complete_time": null,
//                             "updated_at": "2024-11-14T05:58:09Z",
//                             "name": "Real Estate Management",
//                             "subdomain_sequence": null
//                         }
//                     ]
//                 },
//                 {
//                     "id": "ee3222f0-84a2-42a6-bf79-db378189fc74",
//                     "domain_id": "ee3222f0-84a2-42a6-bf79-db378189fc74",
//                     "name": "Facility Technology & Data Management",
//                     "domain_sequence": 9,
//                     "start_time": null,
//                     "complete_time": null,
//                     "updated_at": "2024-04-05T15:47:23Z",
//                     "subdomain": [
//                         {
//                             "id": "62df89cb-18ff-46dd-8d4f-d8d10c0f218a",
//                             "subdomain_id": "99d522f2-4575-4915-b06a-03fa4dac7215",
//                             "start_time": null,
//                             "complete_time": null,
//                             "updated_at": "2024-11-14T05:58:09Z",
//                             "name": "Facility Technology",
//                             "subdomain_sequence": null
//                         },
//                         {
//                             "id": "78627488-ff19-4355-beb8-a317d15858d1",
//                             "subdomain_id": "2b482725-9a69-4c18-8114-3889de74c9d6",
//                             "start_time": null,
//                             "complete_time": null,
//                             "updated_at": "2024-11-14T05:58:09Z",
//                             "name": "Data strategy and information management",
//                             "subdomain_sequence": null
//                         },
//                         {
//                             "id": "ec9de8ab-42bd-4fd1-acbc-3e685d07c421",
//                             "subdomain_id": "635a4dda-bf5d-4f7d-aeb4-5d5b3f62e90d",
//                             "start_time": null,
//                             "complete_time": null,
//                             "updated_at": "2024-11-14T05:58:09Z",
//                             "name": "Information protection & cyber-security",
//                             "subdomain_sequence": null
//                         }
//                     ]
//                 },
//                 {
//                     "id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                     "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                     "name": "Project Management",
//                     "domain_sequence": 10,
//                     "start_time": null,
//                     "complete_time": null,
//                     "updated_at": "2024-04-05T15:47:39Z",
//                     "subdomain": [
//                         {
//                             "id": "06ec880b-846b-4671-81b7-0f357443ce4c",
//                             "subdomain_id": "6cf5a5fd-9b28-49da-99ce-d78925a104ab",
//                             "start_time": null,
//                             "complete_time": null,
//                             "updated_at": "2024-11-14T05:58:09Z",
//                             "name": "Project Closeout",
//                             "subdomain_sequence": null
//                         },
//                         {
//                             "id": "1db55530-218b-49c1-af67-0eb8f84b4c7f",
//                             "subdomain_id": "c5afed8e-2dde-4bab-95cc-5d71051ff298",
//                             "start_time": null,
//                             "complete_time": null,
//                             "updated_at": "2024-11-14T05:58:09Z",
//                             "name": "Execution & Delivery",
//                             "subdomain_sequence": null
//                         },
//                         {
//                             "id": "8d049867-f8f9-4bc6-8e54-b62d78fa237c",
//                             "subdomain_id": "998350dd-f1d0-470c-9a81-6c6ace822836",
//                             "start_time": null,
//                             "complete_time": null,
//                             "updated_at": "2024-11-14T05:58:09Z",
//                             "name": "Planning & Design",
//                             "subdomain_sequence": null
//                         }
//                     ]
//                 }
//             ],
//             "questions": {
//                 "Leadership & Strategies": {
//                     "Strategic planning and alignment with the demand organization": [
//                         {
//                             "id": "83cde828-36c8-4de5-be81-1277a352d326",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "0ed12c62-ac73-4c9e-8cd9-8615869e19f2",
//                             "question_text": "<p>Assist with documenting goals and metrics, and conducting benchmarking activities as outlined in the facilities strategic plan.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "d8d27cb8-df8e-40dd-ac7a-472fcc1176a1",
//                             "subdomain": "Strategic planning and alignment with the demand organization",
//                             "mandatory_question": 18,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 47,
//                             "is_active": 1,
//                             "competency": "Key Performance Indicators (KPI)",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 1,
//                             "question_seqeunce": 1
//                         },
//                         {
//                             "id": "65e794c7-7b0a-4211-98ea-ecc84347765b",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "18a521b7-2649-4051-b1db-8052c179cc46",
//                             "question_text": "<p>Provide reports to senior leadership that show support of the facility strategic plan.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "d8d27cb8-df8e-40dd-ac7a-472fcc1176a1",
//                             "subdomain": "Strategic planning and alignment with the demand organization",
//                             "mandatory_question": 18,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 41,
//                             "is_active": 1,
//                             "competency": "Key Performance Indicators (KPI)",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 1,
//                             "question_seqeunce": 2
//                         },
//                         {
//                             "id": "6896a415-de3a-43cf-8456-2a35a2643cea",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "346c6ffe-392b-4429-93ca-aa96e4585f06",
//                             "question_text": "<p>Develop goals and metrics, and conduct benchmarking initiatives as outlined in the facilities strategic plan.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "d8d27cb8-df8e-40dd-ac7a-472fcc1176a1",
//                             "subdomain": "Strategic planning and alignment with the demand organization",
//                             "mandatory_question": 18,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 25,
//                             "is_active": 1,
//                             "competency": "Benchmarking",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 1,
//                             "question_seqeunce": 3
//                         },
//                         {
//                             "id": "7945a5d9-9102-4848-8803-3873e7fdfbbe",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "3b349378-6180-4e16-bd7e-729fabaee759",
//                             "question_text": "<p>Create and oversee FM's commitment to and compliance with departmental goals and objectives keeping FM in alignment with corporate goals and priorities.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "d8d27cb8-df8e-40dd-ac7a-472fcc1176a1",
//                             "subdomain": "Strategic planning and alignment with the demand organization",
//                             "mandatory_question": 18,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 19,
//                             "is_active": 1,
//                             "competency": "Facility Strategic Planning",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 1,
//                             "question_seqeunce": 4
//                         },
//                         {
//                             "id": "39fd2f5f-3494-47db-8a5d-45978c8c1c06",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "47e64d6b-8903-4f45-aada-1cca0d3eefdc",
//                             "question_text": "<p>Assist with documenting goals and metrics, and conducting benchmarking activities as outlined in the facilities strategic plan.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "d8d27cb8-df8e-40dd-ac7a-472fcc1176a1",
//                             "subdomain": "Strategic planning and alignment with the demand organization",
//                             "mandatory_question": 18,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 61,
//                             "is_active": 1,
//                             "competency": "Key FM Performance Measures",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 1,
//                             "question_seqeunce": 5
//                         },
//                         {
//                             "id": "bd2effa4-9da7-4698-b486-674dd7bfe2ad",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "543975cf-6305-48a9-beb7-b8aa0b13cddb",
//                             "question_text": "<p>Implement departmental goals and objectives that keep FM aligned with the demand organization's goals and priorities.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "d8d27cb8-df8e-40dd-ac7a-472fcc1176a1",
//                             "subdomain": "Strategic planning and alignment with the demand organization",
//                             "mandatory_question": 18,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 21,
//                             "is_active": 1,
//                             "competency": "Facility Strategic Planning",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 1,
//                             "question_seqeunce": 6
//                         },
//                         {
//                             "id": "e43879f3-234e-48eb-8d3a-c485a1fe75d2",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "5a07b259-1323-49f4-8a7a-3deccd66072c",
//                             "question_text": "<p>Use data and insights to develop an FM strategic plan that is aligned with organizational strategic objectives.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "d8d27cb8-df8e-40dd-ac7a-472fcc1176a1",
//                             "subdomain": "Strategic planning and alignment with the demand organization",
//                             "mandatory_question": 18,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 13,
//                             "is_active": 1,
//                             "competency": "Strategic Thinking",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 1,
//                             "question_seqeunce": 7
//                         },
//                         {
//                             "id": "3a1a9842-3181-40f6-85da-b74b2190047b",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "5aef0be0-3188-40df-8bfb-9bee8272ff10",
//                             "question_text": "<p>Develop goals and metrics, and conduct benchmarking initiatives as outlined in the facilities strategic plan.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "d8d27cb8-df8e-40dd-ac7a-472fcc1176a1",
//                             "subdomain": "Strategic planning and alignment with the demand organization",
//                             "mandatory_question": 18,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 44,
//                             "is_active": 1,
//                             "competency": "Key Performance Indicators (KPI)",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 1,
//                             "question_seqeunce": 8
//                         },
//                         {
//                             "id": "1fec8244-e825-43b1-8e32-cc80a45afe79",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "759d138e-f379-462c-86e7-2db0cb00886b",
//                             "question_text": "<p>Ensure the appropriate FM metrics are collected and analyzed to ensure FM alignment with the demand organization's strategic objectives.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "d8d27cb8-df8e-40dd-ac7a-472fcc1176a1",
//                             "subdomain": "Strategic planning and alignment with the demand organization",
//                             "mandatory_question": 18,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 31,
//                             "is_active": 1,
//                             "competency": "Data-based Decision Making",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 1,
//                             "question_seqeunce": 9
//                         },
//                         {
//                             "id": "8c85f51a-5bdf-4e70-a5e0-f74852e4b266",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "7d9373a9-7e58-4100-bafc-7b009d8823a5",
//                             "question_text": "<p>Use data and insights to develop an FM strategic plan that is aligned with organizational strategic objectives.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "d8d27cb8-df8e-40dd-ac7a-472fcc1176a1",
//                             "subdomain": "Strategic planning and alignment with the demand organization",
//                             "mandatory_question": 18,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 34,
//                             "is_active": 1,
//                             "competency": "Data-based Decision Making",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 1,
//                             "question_seqeunce": 10
//                         },
//                         {
//                             "id": "93ceac09-7ade-41e2-b3c0-34f1ae23f7fa",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "80934e3b-2e51-4029-82c3-ba3c496e957d",
//                             "question_text": "<p>Provide reports to senior leadership that show performance fo FM organization relative to the facility strategic plan.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "d8d27cb8-df8e-40dd-ac7a-472fcc1176a1",
//                             "subdomain": "Strategic planning and alignment with the demand organization",
//                             "mandatory_question": 18,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 50,
//                             "is_active": 1,
//                             "competency": "Key FM Performance Measures",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 1,
//                             "question_seqeunce": 11
//                         },
//                         {
//                             "id": "a7593a44-e795-4b24-8e49-c5476e31ad45",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "9ba455cd-1515-44d1-a9e5-8db1543fb483",
//                             "question_text": "<p>Assist management in gathering data and sharing insights to be used to modify strategic plans and maintain alignment with the demand organization. <\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "d8d27cb8-df8e-40dd-ac7a-472fcc1176a1",
//                             "subdomain": "Strategic planning and alignment with the demand organization",
//                             "mandatory_question": 18,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 37,
//                             "is_active": 1,
//                             "competency": "Data-based Decision Making",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 1,
//                             "question_seqeunce": 12
//                         },
//                         {
//                             "id": "ad73ca39-e93f-4229-ae50-f2cb643548fe",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "b8ccc52e-c87a-432c-9586-f55c2ce67c45",
//                             "question_text": "<p>Comply with departmental goals and objectives.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "d8d27cb8-df8e-40dd-ac7a-472fcc1176a1",
//                             "subdomain": "Strategic planning and alignment with the demand organization",
//                             "mandatory_question": 18,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 22,
//                             "is_active": 1,
//                             "competency": "Facility Strategic Planning",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 1,
//                             "question_seqeunce": 13
//                         },
//                         {
//                             "id": "52ed512e-7ece-40db-a070-96edb649d014",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "bb1b99d0-6f8f-4b8b-b0c8-765ff2ceb7cf",
//                             "question_text": "<p>Ensure the development of an FM strategic plan that is aligned with organizational strategic objectives.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "d8d27cb8-df8e-40dd-ac7a-472fcc1176a1",
//                             "subdomain": "Strategic planning and alignment with the demand organization",
//                             "mandatory_question": 18,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 10,
//                             "is_active": 1,
//                             "competency": "Strategic Thinking",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 1,
//                             "question_seqeunce": 14
//                         },
//                         {
//                             "id": "420339da-75ce-4cca-9332-10e351b3f3a7",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "bdeff5c2-eb32-4994-9f05-27f0ff47f128",
//                             "question_text": "<p>Provide reports to senior leadership that show how the FM organization is performing relative to the facility strategic plan.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "d8d27cb8-df8e-40dd-ac7a-472fcc1176a1",
//                             "subdomain": "Strategic planning and alignment with the demand organization",
//                             "mandatory_question": 18,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 24,
//                             "is_active": 1,
//                             "competency": "Benchmarking",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 1,
//                             "question_seqeunce": 15
//                         },
//                         {
//                             "id": "1e1c4a1b-4d5c-4769-93c6-c6bd330bc3c4",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "dd3c4769-b3c1-413f-aea0-00d52f707e75",
//                             "question_text": "<p>Develop goals and metrics, and conduct benchmarking initiatives as outlined in the facilities strategic plan.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "d8d27cb8-df8e-40dd-ac7a-472fcc1176a1",
//                             "subdomain": "Strategic planning and alignment with the demand organization",
//                             "mandatory_question": 18,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 55,
//                             "is_active": 1,
//                             "competency": "Key FM Performance Measures",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 1,
//                             "question_seqeunce": 16
//                         },
//                         {
//                             "id": "de11b1b1-3376-4111-ae59-d1ebc0ae9632",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "f68e1b3a-2696-4836-8313-ff95a178071e",
//                             "question_text": "<p>Assist with documenting goals and metrics, and conducting benchmarking activities as outlined in the facilities strategic plan.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "d8d27cb8-df8e-40dd-ac7a-472fcc1176a1",
//                             "subdomain": "Strategic planning and alignment with the demand organization",
//                             "mandatory_question": 18,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 27,
//                             "is_active": 1,
//                             "competency": "Benchmarking",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 1,
//                             "question_seqeunce": 17
//                         },
//                         {
//                             "id": "92283fdb-823b-42b8-a75c-b4682a354453",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "f9f444c8-9b86-43e4-8d9d-98bd868e95f9",
//                             "question_text": "<p>Assist management in gathering data and sharing insights to be used to create and modify strategic plans and maintain alignment with the demand organization.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "d8d27cb8-df8e-40dd-ac7a-472fcc1176a1",
//                             "subdomain": "Strategic planning and alignment with the demand organization",
//                             "mandatory_question": 18,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 16,
//                             "is_active": 1,
//                             "competency": "Strategic Thinking",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 1,
//                             "question_seqeunce": 18
//                         }
//                     ],
//                     "Leadership": [
//                         {
//                             "id": "5f5cd6b8-2d06-43aa-ad94-81fbda74fb96",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "069d7d02-d335-494a-9865-2f5315d951b9",
//                             "question_text": "<p>Develop policies, procedures, and related training programs that improve the governance of the FM organization.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "82964658-2d6e-4cf6-bc2b-ff1a90b7c4c7",
//                             "subdomain": "Leadership",
//                             "mandatory_question": 22,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 67,
//                             "is_active": 1,
//                             "competency": "Leadership",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 2,
//                             "question_seqeunce": 1
//                         },
//                         {
//                             "id": "1e0fe1d0-478b-44e8-9476-3af7aabdb885",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "08127f3a-bc1b-4c45-8930-3f5b18daa216",
//                             "question_text": "<p>Ensure compliance with all legislated, organizational, and industry-related standards and guidelines (e.g., regulatory, sustainability certifications, ISO standards).<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "82964658-2d6e-4cf6-bc2b-ff1a90b7c4c7",
//                             "subdomain": "Leadership",
//                             "mandatory_question": 22,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 131,
//                             "is_active": 1,
//                             "competency": "Standards and Certifications",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 2,
//                             "question_seqeunce": 2
//                         },
//                         {
//                             "id": "2aa04fae-cc82-4458-b3e9-074d3859ea97",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "0b081aab-c9f2-4f44-83ec-2853908cf735",
//                             "question_text": "<p>Create policies and procedures that support organizational objectives.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "82964658-2d6e-4cf6-bc2b-ff1a90b7c4c7",
//                             "subdomain": "Leadership",
//                             "mandatory_question": 22,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 122,
//                             "is_active": 1,
//                             "competency": "Policy Development",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 2,
//                             "question_seqeunce": 3
//                         },
//                         {
//                             "id": "ba1ffe24-7937-416e-a072-acd361239c4b",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "15bb456a-2d94-4816-843b-3cca7453f5f6",
//                             "question_text": "<p>Participate in meetings with other department representatives to understand needs and drivers.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "82964658-2d6e-4cf6-bc2b-ff1a90b7c4c7",
//                             "subdomain": "Leadership",
//                             "mandatory_question": 22,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 112,
//                             "is_active": 1,
//                             "competency": "Partnering",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 2,
//                             "question_seqeunce": 4
//                         },
//                         {
//                             "id": "3b4f759d-2b07-459d-a9f3-dabae100d86f",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "1dc56354-8338-4250-b238-67a98f4a3fcc",
//                             "question_text": "<p>Assist in the development of policies, procedures, and related training programs related to the governance of the FM organization<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "82964658-2d6e-4cf6-bc2b-ff1a90b7c4c7",
//                             "subdomain": "Leadership",
//                             "mandatory_question": 22,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 77,
//                             "is_active": 1,
//                             "competency": "Leadership",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 2,
//                             "question_seqeunce": 5
//                         },
//                         {
//                             "id": "52a5cc03-700e-46d5-9a60-ea428e17bdad",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "24429433-44d8-4e7f-8bd1-8dbff5fb7e7a",
//                             "question_text": "<p>Assist in the drafting of FM policies and procedures that align with  organizational objectives.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "82964658-2d6e-4cf6-bc2b-ff1a90b7c4c7",
//                             "subdomain": "Leadership",
//                             "mandatory_question": 22,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 125,
//                             "is_active": 1,
//                             "competency": "Policy Development",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 2,
//                             "question_seqeunce": 6
//                         },
//                         {
//                             "id": "0023314c-6eb0-4b8c-9bd7-f7f9fda08686",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "38f27db4-d39f-4608-8e07-d0b3f5d2c4d7",
//                             "question_text": "<p>Foster an environment of collaboration, team work, and partnering across the FM organization.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "82964658-2d6e-4cf6-bc2b-ff1a90b7c4c7",
//                             "subdomain": "Leadership",
//                             "mandatory_question": 22,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 79,
//                             "is_active": 1,
//                             "competency": "Influencing",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 2,
//                             "question_seqeunce": 7
//                         },
//                         {
//                             "id": "a8e32d24-d95a-46fe-bbda-29f24f79802b",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "455541f1-b60e-435b-95ad-42248a875b76",
//                             "question_text": "<p>Ensure facility team has equitable knowledge of the core competencies and access to resources that present developing practices and procedures.  Familiarity with ISO standard 41000 is recommended.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "82964658-2d6e-4cf6-bc2b-ff1a90b7c4c7",
//                             "subdomain": "Leadership",
//                             "mandatory_question": 22,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 143,
//                             "is_active": 1,
//                             "competency": "Facility Management Practices and Procedures",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 2,
//                             "question_seqeunce": 8
//                         },
//                         {
//                             "id": "70c9e52f-8c3c-4930-a9bd-0ae4b4ee0eb0",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "51369212-bb29-4c7f-b275-0c09f3d4a32e",
//                             "question_text": "<p>Confirm that policies and procedures comply with all legislated, organizational, and industry-related standards and guidelines (e.g., regulatory, sustainability certifications, ISO standards).<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "82964658-2d6e-4cf6-bc2b-ff1a90b7c4c7",
//                             "subdomain": "Leadership",
//                             "mandatory_question": 22,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 136,
//                             "is_active": 1,
//                             "competency": "Standards and Certifications",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 2,
//                             "question_seqeunce": 9
//                         },
//                         {
//                             "id": "2fc0642b-1cc1-4459-8117-337395a2b6a0",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "64625454-3572-4320-a344-d4693747a61b",
//                             "question_text": "<p>Collect data related to compliance with all legislated, organizational, and industry-related standards and guidelines (e.g., regulatory, sustainability certifications, ISO standards).<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "82964658-2d6e-4cf6-bc2b-ff1a90b7c4c7",
//                             "subdomain": "Leadership",
//                             "mandatory_question": 22,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 140,
//                             "is_active": 1,
//                             "competency": "Standards and Certifications",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 2,
//                             "question_seqeunce": 10
//                         },
//                         {
//                             "id": "8191fbb3-4fc4-44fa-804a-a6d894067d3b",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "64dd98a3-802b-4c85-85a8-5729bd160342",
//                             "question_text": "<p>Explore emerging FM practices and determine applicability and impact to existing practices and procedures.  Recommend a timeline for adoption.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "82964658-2d6e-4cf6-bc2b-ff1a90b7c4c7",
//                             "subdomain": "Leadership",
//                             "mandatory_question": 22,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 152,
//                             "is_active": 1,
//                             "competency": "Facility Management Practices and Procedures",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 2,
//                             "question_seqeunce": 11
//                         },
//                         {
//                             "id": "85fa98f0-0418-4cc0-87c3-5fbacf106343",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "72b11986-248f-4e4d-9117-558ee26fbfed",
//                             "question_text": "<p>Contribute to an environment of collaboration, team work, and partnering across the FM organization.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "82964658-2d6e-4cf6-bc2b-ff1a90b7c4c7",
//                             "subdomain": "Leadership",
//                             "mandatory_question": 22,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 88,
//                             "is_active": 1,
//                             "competency": "Influencing",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 2,
//                             "question_seqeunce": 12
//                         },
//                         {
//                             "id": "c9307695-5b91-460b-bb5f-c6733c343953",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "739f695c-1803-45e1-bb88-8e493dfac192",
//                             "question_text": "<p>Develop strategies that support continuity, and lead succession planning efforts for FM positions.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "82964658-2d6e-4cf6-bc2b-ff1a90b7c4c7",
//                             "subdomain": "Leadership",
//                             "mandatory_question": 22,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 322,
//                             "is_active": 1,
//                             "competency": "Succession planning",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 2,
//                             "question_seqeunce": 13
//                         },
//                         {
//                             "id": "bb87b96e-93ab-4554-b800-591b3f10aca4",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "7992bb52-b1e8-4882-82d4-f3d6f1cca699",
//                             "question_text": "<p>Evaluate, improve, and approve policies and procedures so they support organizational objectives.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "82964658-2d6e-4cf6-bc2b-ff1a90b7c4c7",
//                             "subdomain": "Leadership",
//                             "mandatory_question": 22,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 116,
//                             "is_active": 1,
//                             "competency": "Policy Development",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 2,
//                             "question_seqeunce": 14
//                         },
//                         {
//                             "id": "2d1f2145-d9cc-46af-b144-59c3bb408590",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "897630ea-db31-4302-8412-a8722c63e34e",
//                             "question_text": "<p>Work collaboratively to support the adoption of policies and procedures that are aligned with the goals of the organization.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "82964658-2d6e-4cf6-bc2b-ff1a90b7c4c7",
//                             "subdomain": "Leadership",
//                             "mandatory_question": 22,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 103,
//                             "is_active": 1,
//                             "competency": "Communicating With Leadership",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 2,
//                             "question_seqeunce": 15
//                         },
//                         {
//                             "id": "112538ae-2832-4d24-bf67-0b4c61af114f",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "a46869fc-0f9e-4791-9d2d-51ea9a6fe5be",
//                             "question_text": "<p>Work collaboratively with other leadership team members to confirm that facility and operation's strategies are implemented as agreed on and support organizational objectives while maximizing efficiencies, improving skill levels, reducing costs, and etc.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "82964658-2d6e-4cf6-bc2b-ff1a90b7c4c7",
//                             "subdomain": "Leadership",
//                             "mandatory_question": 22,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 109,
//                             "is_active": 1,
//                             "competency": "Partnering",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 2,
//                             "question_seqeunce": 16
//                         },
//                         {
//                             "id": "3232c404-af63-485f-9e3c-c9c20c96a191",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "a48f0730-40b1-4e10-8af2-145a15b4cea8",
//                             "question_text": "<p>Work collaboratively with other leadership team members to implement policies and procedures that are aligned with the goals of the organization.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "82964658-2d6e-4cf6-bc2b-ff1a90b7c4c7",
//                             "subdomain": "Leadership",
//                             "mandatory_question": 22,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 101,
//                             "is_active": 1,
//                             "competency": "Communicating With Leadership",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 2,
//                             "question_seqeunce": 17
//                         },
//                         {
//                             "id": "cfe2f731-c4e0-482c-abc3-b296da50274c",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "a80d9e2d-10b2-40e7-b669-667a4e784c9e",
//                             "question_text": "<p>Work collaboratively with other leadership team members to define, develop, and document policies and procedures that are aligned with the demand organization's goals.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "82964658-2d6e-4cf6-bc2b-ff1a90b7c4c7",
//                             "subdomain": "Leadership",
//                             "mandatory_question": 22,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 95,
//                             "is_active": 1,
//                             "competency": "Communicating With Leadership",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 2,
//                             "question_seqeunce": 18
//                         },
//                         {
//                             "id": "747e1c51-7f7a-41b1-b6af-21be00543e3f",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "ac7c64b1-148a-4391-b730-13df9fc47cc1",
//                             "question_text": "<p>Develop new or revise existing policies and procedures that support organizational objectives recognizing emerging FM practices.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "82964658-2d6e-4cf6-bc2b-ff1a90b7c4c7",
//                             "subdomain": "Leadership",
//                             "mandatory_question": 22,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 147,
//                             "is_active": 1,
//                             "competency": "Facility Management Practices and Procedures",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 2,
//                             "question_seqeunce": 19
//                         },
//                         {
//                             "id": "072bdc3f-2430-492c-be49-fd49cf3e792b",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "ae5b7f6a-6d06-47a0-8984-cd5fb3261974",
//                             "question_text": "<p>Identify ways to improve the governance of the facilities management organization incorporating team work and collaboration.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "82964658-2d6e-4cf6-bc2b-ff1a90b7c4c7",
//                             "subdomain": "Leadership",
//                             "mandatory_question": 22,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 71,
//                             "is_active": 1,
//                             "competency": "Leadership",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 2,
//                             "question_seqeunce": 20
//                         },
//                         {
//                             "id": "ada808f3-b209-4ad5-8b45-883864ce8c43",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "b998b0bd-ab4a-4648-9133-f95f8fe4270a",
//                             "question_text": "<p>Build an environment of collaboration, team work, and partnering across the FM organization.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "82964658-2d6e-4cf6-bc2b-ff1a90b7c4c7",
//                             "subdomain": "Leadership",
//                             "mandatory_question": 22,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 84,
//                             "is_active": 1,
//                             "competency": "Influencing",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 2,
//                             "question_seqeunce": 21
//                         },
//                         {
//                             "id": "7ca21685-b57f-426b-b6ea-e4ed0b8ed5c5",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "d5cb4ed3-b9dd-4832-9125-97a80cc51f7f",
//                             "question_text": "<p>Strategize with leadership on how to best build and maintain relationships with other department representatives <\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "82964658-2d6e-4cf6-bc2b-ff1a90b7c4c7",
//                             "subdomain": "Leadership",
//                             "mandatory_question": 22,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 105,
//                             "is_active": 1,
//                             "competency": "Partnering",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 2,
//                             "question_seqeunce": 22
//                         }
//                     ],
//                     "Change management": [
//                         {
//                             "id": "2ff779f1-13f9-407e-af6c-f53f92375990",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "26c0c690-0da1-4c19-bd2a-7512ea3b0498",
//                             "question_text": "<p>Assist with implementation of facility initiatives <\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "4dd23013-72a4-47fd-bd1d-31508d184c94",
//                             "subdomain": "Change management",
//                             "mandatory_question": 6,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 179,
//                             "is_active": 1,
//                             "competency": "Change Management",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 3,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "6f3470b4-12da-4a36-bcda-2b8c9c8e6fa6",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "5136a18b-817e-4e6b-b113-15724832049b",
//                             "question_text": "<p>Support change management initiatives and communications to achieve effective program objectives.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "4dd23013-72a4-47fd-bd1d-31508d184c94",
//                             "subdomain": "Change management",
//                             "mandatory_question": 6,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 169,
//                             "is_active": 1,
//                             "competency": "Change Management",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 3,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "8c9d751e-0fb2-4422-90db-8149fa035ed1",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "ab7b3892-550c-4e57-b3dc-a8166bc75b62",
//                             "question_text": "<p>Assist in implementing a change management process.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "4dd23013-72a4-47fd-bd1d-31508d184c94",
//                             "subdomain": "Change management",
//                             "mandatory_question": 6,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 174,
//                             "is_active": 1,
//                             "competency": "Change Management",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 3,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "8fc5cfe7-29b8-4d43-9af8-d0a67347c7b5",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "a671312f-c2ad-4f78-8061-6f1919406b84",
//                             "question_text": "<p>Lead change management and communications initiatives to achieve program objectives.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "4dd23013-72a4-47fd-bd1d-31508d184c94",
//                             "subdomain": "Change management",
//                             "mandatory_question": 6,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 165,
//                             "is_active": 1,
//                             "competency": "Change Management",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 3,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "d5ac80fb-fbf4-482c-a4bd-1e930d272c8f",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "28d50129-21ea-4d7f-a4e0-05052bc2659c",
//                             "question_text": "<p>Manage implementation and adoption of facility initiatives<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "4dd23013-72a4-47fd-bd1d-31508d184c94",
//                             "subdomain": "Change management",
//                             "mandatory_question": 6,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 171,
//                             "is_active": 1,
//                             "competency": "Change Management",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 3,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "f23153a2-fcfd-4b67-adeb-74e2cc7f3c7d",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "a73f491b-5507-4c70-9583-f49bdb570d81",
//                             "question_text": "<p>Act as a liaison between field teams, operations, and the support services group to support implementation and adoption of organization's initiatives <\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "4dd23013-72a4-47fd-bd1d-31508d184c94",
//                             "subdomain": "Change management",
//                             "mandatory_question": 6,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 168,
//                             "is_active": 1,
//                             "competency": "Change Management",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 3,
//                             "question_seqeunce": null
//                         }
//                     ],
//                     "Relationship management and conflict management": [
//                         {
//                             "id": "286dbea9-331c-46cd-b3a7-922fadc36bf7",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "4b6255cb-b31c-4f0b-abb2-20490104a268",
//                             "question_text": "<p>Promote comprehensive strategies for obtaining competitive vendor pricing, effective budget development and contract execution, and meaningful performance measurements for process improvement.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "6aa34898-b28e-4d54-9cc9-a086e4a42b95",
//                             "subdomain": "Relationship management and conflict management",
//                             "mandatory_question": 6,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 216,
//                             "is_active": 1,
//                             "competency": "Negotiating",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 4,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "76ab6cc6-e349-4650-bbc6-c7538e2d729d",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "5f8b79e5-d9b2-4cd6-864a-6a7fea65c74f",
//                             "question_text": "<p>Resolve facilities-related concerns with business partners, contractors, project managers, and others, and respond to their queries in a timely manner.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "6aa34898-b28e-4d54-9cc9-a086e4a42b95",
//                             "subdomain": "Relationship management and conflict management",
//                             "mandatory_question": 6,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 208,
//                             "is_active": 1,
//                             "competency": "Conflict Management",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 4,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "a011d9ed-a507-478b-8907-f1c6455d906e",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "debe6b08-a5cc-41e3-808d-c607742616bf",
//                             "question_text": "<p>Identify and report facilities-related concerns regarding business partners, contractors, project managers, and so forth.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "6aa34898-b28e-4d54-9cc9-a086e4a42b95",
//                             "subdomain": "Relationship management and conflict management",
//                             "mandatory_question": 6,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 211,
//                             "is_active": 1,
//                             "competency": "Conflict Management",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 4,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "a4f4a461-0d3c-4bcf-b34a-3858e37842ae",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "f58d59cf-931b-4adf-93df-af5219a2b448",
//                             "question_text": "<p>Confirm facilities-related concerns with business partners, contractors, project managers, and others, are responded to in a timely and effective manner.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "6aa34898-b28e-4d54-9cc9-a086e4a42b95",
//                             "subdomain": "Relationship management and conflict management",
//                             "mandatory_question": 6,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 206,
//                             "is_active": 1,
//                             "competency": "Conflict Management",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 4,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "ab6d54cf-fb4c-4e4c-a0a1-65d2507615a3",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "0d371c9a-7a0b-467a-b000-466be57511b7",
//                             "question_text": "<p>Annually set goals and establish strategies to expand supplier-vendor pool to improve quality, service, and competitive pricing.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "6aa34898-b28e-4d54-9cc9-a086e4a42b95",
//                             "subdomain": "Relationship management and conflict management",
//                             "mandatory_question": 6,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 213,
//                             "is_active": 1,
//                             "competency": "Negotiating",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 4,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "b14f9fed-c345-474f-b1a0-a500d759df9b",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "fabed517-72d8-45c6-a8f3-dd0d46184c93",
//                             "question_text": "<p>Develop means to track and report performance of existing vendors.  Establish scorecard for communicating improvements measures.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "6aa34898-b28e-4d54-9cc9-a086e4a42b95",
//                             "subdomain": "Relationship management and conflict management",
//                             "mandatory_question": 6,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 219,
//                             "is_active": 1,
//                             "competency": "Negotiating",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 4,
//                             "question_seqeunce": null
//                         }
//                     ],
//                     "Individual and team leadership management": [
//                         {
//                             "id": "117b58c7-081e-4fc5-bb1a-fe4badfa7902",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "475fb35d-1ed2-49b3-af86-f24d9c3a302f",
//                             "question_text": "<p>Understand parameters for developing and conducting employee relations in accordance with laws, rules, regulations, principles, and practices.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "0e3053af-6db4-4f51-8470-da811aa01fb7",
//                             "subdomain": "Individual and team leadership management",
//                             "mandatory_question": 20,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 310,
//                             "is_active": 1,
//                             "competency": "Employee Relations",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 5,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "21b27abd-9ff0-4f9e-9298-7a7ea303cff2",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "5d0f1ef2-da02-4a1e-aabe-42716ed4c794",
//                             "question_text": "<p>Help identify the competencies needed for each position and  determine training needs.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "0e3053af-6db4-4f51-8470-da811aa01fb7",
//                             "subdomain": "Individual and team leadership management",
//                             "mandatory_question": 20,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 299,
//                             "is_active": 1,
//                             "competency": "Workforce Development",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 5,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "2669e9ff-70b3-486d-be73-3e9a7b8e581b",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "2c7a59b6-8750-40a2-98db-8fffedfc8c11",
//                             "question_text": "<p>Review the competencies associated with each position, identify personnel competency gaps, and determine the training that will fill those gaps.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "0e3053af-6db4-4f51-8470-da811aa01fb7",
//                             "subdomain": "Individual and team leadership management",
//                             "mandatory_question": 20,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 297,
//                             "is_active": 1,
//                             "competency": "Workforce Development",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 5,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "444da114-d00d-4eab-bd31-b8d506f77987",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "17d2b2d9-1840-4728-bc23-8c9a34f2084e",
//                             "question_text": "<p>Reinforce and ensure implementation of parameters for developing and conducting employee relations in accordance with laws, rules, regulations, principles, and practices.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "0e3053af-6db4-4f51-8470-da811aa01fb7",
//                             "subdomain": "Individual and team leadership management",
//                             "mandatory_question": 20,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 308,
//                             "is_active": 1,
//                             "competency": "Employee Relations",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 5,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "466c0631-8ac5-4d27-b8ad-dec15456fd7e",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "8629cf3e-6e76-4b8e-80eb-edc0097d4325",
//                             "question_text": "<p>Understand parameters for developing and conducting employee relations in accordance with laws, rules, regulations, principles, and practices.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "0e3053af-6db4-4f51-8470-da811aa01fb7",
//                             "subdomain": "Individual and team leadership management",
//                             "mandatory_question": 20,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 317,
//                             "is_active": 1,
//                             "competency": "Employee Relations",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 5,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "55085a89-542a-4cac-9802-bfd704f08b85",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "f285c993-8e62-4403-92f1-4bdd958ecc22",
//                             "question_text": "<p>Create a staffing strategy that aligns business needs with the required leadership experience.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "0e3053af-6db4-4f51-8470-da811aa01fb7",
//                             "subdomain": "Individual and team leadership management",
//                             "mandatory_question": 20,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 222,
//                             "is_active": 1,
//                             "competency": "Staffing analysis",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 5,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "555cb364-e14c-4d36-8f12-e019dd7b3141",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "7803cece-2ea8-428d-a4b5-0efe424bf3a4",
//                             "question_text": "<p>Align training plans with competency gaps and ensure career planning is integrated with employee performance plans. <\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "0e3053af-6db4-4f51-8470-da811aa01fb7",
//                             "subdomain": "Individual and team leadership management",
//                             "mandatory_question": 20,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 295,
//                             "is_active": 1,
//                             "competency": "Workforce Development",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 5,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "55965af2-bfdc-4147-a496-8f6dafd57ffe",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "49aebbf6-62a4-4221-a2a2-d42fc7954fd2",
//                             "question_text": "<p>Participate in initiatives that encourage employees to grow and develop. Lead the development of performance goals and evaluation tools for FM staff.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "0e3053af-6db4-4f51-8470-da811aa01fb7",
//                             "subdomain": "Individual and team leadership management",
//                             "mandatory_question": 20,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 305,
//                             "is_active": 1,
//                             "competency": "Performance Management",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 5,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "5b46512f-6e90-46a7-8104-ec1e19cab2d2",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "78a180c3-ae99-47ba-8844-8b95952ff259",
//                             "question_text": "<p>Provide guidance, coaching, information, ideas, feedback, and encouragement to employees to promote their growth and development.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "0e3053af-6db4-4f51-8470-da811aa01fb7",
//                             "subdomain": "Individual and team leadership management",
//                             "mandatory_question": 20,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 342,
//                             "is_active": 1,
//                             "competency": "Facility Services Improvement",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 5,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "73c3f712-508d-4566-99d1-431589711225",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "dedc201a-9d70-4098-b04d-bb2edf2239af",
//                             "question_text": "<p>Support the development of a staffing plan.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "0e3053af-6db4-4f51-8470-da811aa01fb7",
//                             "subdomain": "Individual and team leadership management",
//                             "mandatory_question": 20,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 228,
//                             "is_active": 1,
//                             "competency": "Staffing analysis",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 5,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "8592d064-da1c-4afa-832e-f37f5dcda1dd",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "a076e1b2-0688-44fa-9e4c-ba51d7f10e4b",
//                             "question_text": "<p>Develop an understanding of the succession needs of the organization.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "0e3053af-6db4-4f51-8470-da811aa01fb7",
//                             "subdomain": "Individual and team leadership management",
//                             "mandatory_question": 20,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 334,
//                             "is_active": 1,
//                             "competency": "Succession planning",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 5,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "89765c4b-eb71-4d6a-861e-d7acdba9eda5",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "2710a188-54c1-45ad-8453-8398be6837b3",
//                             "question_text": "<p>Build a staffing plan that aligns business needs with the required knowledge, skills, and abilities.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "0e3053af-6db4-4f51-8470-da811aa01fb7",
//                             "subdomain": "Individual and team leadership management",
//                             "mandatory_question": 20,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 225,
//                             "is_active": 1,
//                             "competency": "Staffing analysis",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 5,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "93766364-52ee-4fd7-a818-77b8f728eb28",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "32bd06f0-3530-4417-b367-42ffd23665ec",
//                             "question_text": "<p>Review minimum requirements for open positions; develop job descriptions for new positions; establish baseline skillsets, credentials, and other requirements.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "0e3053af-6db4-4f51-8470-da811aa01fb7",
//                             "subdomain": "Individual and team leadership management",
//                             "mandatory_question": 20,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 292,
//                             "is_active": 1,
//                             "competency": "Recruitment",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 5,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "9e4d1948-918d-4c26-a5f7-d43e049b17c0",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "1869329b-c1e4-401d-aa40-ee81783263e1",
//                             "question_text": "<p>Complete and approve performance reviews and employee development plans. Provide guidance, coaching, information, ideas, feedback, and encouragement to employees to promote their growth and development.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "0e3053af-6db4-4f51-8470-da811aa01fb7",
//                             "subdomain": "Individual and team leadership management",
//                             "mandatory_question": 20,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 302,
//                             "is_active": 1,
//                             "competency": "Performance Management",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 5,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "aae4bea8-ad58-40a4-9977-e8a6fa8a6813",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "17018114-0064-4332-8a8f-3321d11fa817",
//                             "question_text": "<p>Annually set goals and establish strategies to address human resource needs of the FM department and communicate these to demand organization.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "0e3053af-6db4-4f51-8470-da811aa01fb7",
//                             "subdomain": "Individual and team leadership management",
//                             "mandatory_question": 20,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 290,
//                             "is_active": 1,
//                             "competency": "Recruitment",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 5,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "b718a8d3-209b-46c0-9e5a-397db0e27370",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "fa0def8f-3b9d-415e-8962-94c8080b9cfc",
//                             "question_text": "<p>Help identify opportunities for skills development through job shadow apprenticeship programs.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "0e3053af-6db4-4f51-8470-da811aa01fb7",
//                             "subdomain": "Individual and team leadership management",
//                             "mandatory_question": 20,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 294,
//                             "is_active": 1,
//                             "competency": "Recruitment",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 5,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "d74e4ea9-ebf0-4b03-8213-020203489c76",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "3c1a295b-8795-478d-baee-d64316d4db08",
//                             "question_text": "<p>Create metrics and set expectations for each position; develop the structure for analysis and input for each performance review; approve learning and training plans. Report on the performance of team member to Human Resources.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "0e3053af-6db4-4f51-8470-da811aa01fb7",
//                             "subdomain": "Individual and team leadership management",
//                             "mandatory_question": 20,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 301,
//                             "is_active": 1,
//                             "competency": "Performance Management",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 5,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "e0744155-84ba-4f82-98b5-a550db54a1d7",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "709a7c2a-e648-4f1b-b6a7-2068dbb93ebf",
//                             "question_text": "<p>Assist in the collection of staff feedback.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "0e3053af-6db4-4f51-8470-da811aa01fb7",
//                             "subdomain": "Individual and team leadership management",
//                             "mandatory_question": 20,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 346,
//                             "is_active": 1,
//                             "competency": "Facility Services Improvement",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 5,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "edbc97fb-335d-4f89-b858-cce849f55845",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "70f817ed-2370-46a3-a2c1-f0de2da38867",
//                             "question_text": "<p>Set goals and measurements for continual learning efforts to support succession planning.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "0e3053af-6db4-4f51-8470-da811aa01fb7",
//                             "subdomain": "Individual and team leadership management",
//                             "mandatory_question": 20,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 327,
//                             "is_active": 1,
//                             "competency": "Succession planning",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 5,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "f9fa6314-169b-4020-a032-55c0d9ff0b91",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "86ac1957-fa6b-4fc8-be4a-86a416062ef5",
//                             "question_text": "<p>Integrate staff feedback to improve the efficiency and effectiveness of service delivery.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "0e3053af-6db4-4f51-8470-da811aa01fb7",
//                             "subdomain": "Individual and team leadership management",
//                             "mandatory_question": 20,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 338,
//                             "is_active": 1,
//                             "competency": "Facility Services Improvement",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 5,
//                             "question_seqeunce": null
//                         }
//                     ],
//                     "Political, social, economic, and industry factors affecting facility management": [
//                         {
//                             "id": "a0b868b3-c515-47c6-a588-fec6e3218943",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "903dc0f0-a324-4015-8c74-fc17600593bc",
//                             "question_text": "<p>Help identify internal and external factors (i.e., political, social, economic, and industry) that can affect FM.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "4dc6d34e-2df4-4928-88ad-66c0f7b5dc8f",
//                             "subdomain": "Political, social, economic, and industry factors affecting facility management",
//                             "mandatory_question": 3,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 363,
//                             "is_active": 1,
//                             "competency": "External Awareness",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 6,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "f3b53ec9-d4e6-4a72-a9af-794ba0b66df7",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "57e1a551-dd91-48c6-b242-beaa4a6017ab",
//                             "question_text": "<p>Review and revise, if needed, the facility strategic plan in response to internal and external factors (i.e., political, social, economic, and industry) affecting facility management.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "4dc6d34e-2df4-4928-88ad-66c0f7b5dc8f",
//                             "subdomain": "Political, social, economic, and industry factors affecting facility management",
//                             "mandatory_question": 3,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 349,
//                             "is_active": 1,
//                             "competency": "External Awareness",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 6,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "ff9b7cf9-6897-47bd-a1df-a6449fbf7c6c",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "e268f1bf-f0de-4cee-a7f6-d5ea35b79975",
//                             "question_text": "<p>Monitor internal and external factors (i.e., political, social, economic, and industry) that can affect FM.<\/p>",
//                             "domain_id": "b24a6aeb-6d67-476f-8fee-dcdc09f770d6",
//                             "domain": "Leadership & Strategies",
//                             "subdomain_id": "4dc6d34e-2df4-4928-88ad-66c0f7b5dc8f",
//                             "subdomain": "Political, social, economic, and industry factors affecting facility management",
//                             "mandatory_question": 3,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 360,
//                             "is_active": 1,
//                             "competency": "External Awareness",
//                             "domain_seqeunce": 1,
//                             "subdomain_seqeunce": 6,
//                             "question_seqeunce": null
//                         }
//                     ]
//                 },
//                 "Facility Operations": {
//                     "Asset management": [
//                         {
//                             "id": "01560955-d2c9-48df-85bd-e28a1595ee0d",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "78af26b7-c9f5-4b57-b401-c5593ac22798",
//                             "question_text": "<p>Collaborate with relevant stakeholders to oversee the implementation of facility-related programs.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "e1dc01a6-b63d-478d-9ab5-d2c95a6692a7",
//                             "subdomain": "Asset management",
//                             "mandatory_question": 18,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 495,
//                             "is_active": 1,
//                             "competency": "Facility improvements",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "04cf2f51-526f-41d8-b7f7-269eef0f4cfe",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "54c01478-49db-4ebe-80de-4179aad94635",
//                             "question_text": "<p>Establish funding models to make sure that facility assets are properly maintained, renewed, and replaced in a cost-effective manner<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "e1dc01a6-b63d-478d-9ab5-d2c95a6692a7",
//                             "subdomain": "Asset management",
//                             "mandatory_question": 18,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 451,
//                             "is_active": 1,
//                             "competency": "Asset management",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "177fc551-5e27-49f8-8d4b-967ffc400261",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "32ecfd55-3b3a-45e8-a8f6-7d46a0715b28",
//                             "question_text": "<p>Coordinate repair, replacement, retrofit, and facility improvements.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "e1dc01a6-b63d-478d-9ab5-d2c95a6692a7",
//                             "subdomain": "Asset management",
//                             "mandatory_question": 18,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 503,
//                             "is_active": 1,
//                             "competency": "Facility improvements",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "2674f224-b81a-48fd-8a48-937f8f589352",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "4628d971-962c-40b1-aead-73b66fd5f86f",
//                             "question_text": "<p>Maintain listings of assets and their condition, and participate in assigning facility condition index numbers.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "e1dc01a6-b63d-478d-9ab5-d2c95a6692a7",
//                             "subdomain": "Asset management",
//                             "mandatory_question": 18,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 455,
//                             "is_active": 1,
//                             "competency": "Asset management",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "2c555eeb-48bd-4e71-9434-bcc5d84f38a4",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "2a2a073d-9d5f-46dc-aa69-e27ee6092b48",
//                             "question_text": "<p>Monitor repair, replacement, retrofit, and facility improvements.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "e1dc01a6-b63d-478d-9ab5-d2c95a6692a7",
//                             "subdomain": "Asset management",
//                             "mandatory_question": 18,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 513,
//                             "is_active": 1,
//                             "competency": "Facility improvements",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "527bb194-fde6-4ec1-9f84-94e15304767e",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "93531462-ba3f-4725-bf3f-9a12bb5200a7",
//                             "question_text": "<p>Confer with management, to confirm priority and present cost benefit for the capital expenditures.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "e1dc01a6-b63d-478d-9ab5-d2c95a6692a7",
//                             "subdomain": "Asset management",
//                             "mandatory_question": 18,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 464,
//                             "is_active": 1,
//                             "competency": "Facility condition assessment",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "7478c2d8-ca4e-4c9f-9571-7f82cd8b3722",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "87ce40da-f6b2-49fb-814c-8bf8259d67ad",
//                             "question_text": "<p>Oversee the evaluation of site equipment for needed upgrades and replacements.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "e1dc01a6-b63d-478d-9ab5-d2c95a6692a7",
//                             "subdomain": "Asset management",
//                             "mandatory_question": 18,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 483,
//                             "is_active": 1,
//                             "competency": "Facility improvements",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "7ea02c39-46c6-4014-a737-0cd0f3d60fb0",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "744d9ffc-a909-496c-9c80-f182bff05f2c",
//                             "question_text": "<p>Prepare and maintain lists of planned and potential site infrastructure projects.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "e1dc01a6-b63d-478d-9ab5-d2c95a6692a7",
//                             "subdomain": "Asset management",
//                             "mandatory_question": 18,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 510,
//                             "is_active": 1,
//                             "competency": "Facility improvements",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "7ffd77bc-f0d4-4219-838a-b31fecd981f8",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "7bc929a6-7a57-4155-ba49-3f6238df99d9",
//                             "question_text": "<p>Coordinate repair, replacement, and renewal needs with relevant stakeholders.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "e1dc01a6-b63d-478d-9ab5-d2c95a6692a7",
//                             "subdomain": "Asset management",
//                             "mandatory_question": 18,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 485,
//                             "is_active": 1,
//                             "competency": "Facility improvements",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "84c18fbb-61c8-42c1-8ec3-cb43e19f70ed",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "5a5ca10e-4b9c-4cae-8673-d2be2908d977",
//                             "question_text": "<p>Assist in evaluating various site equipment for needed upgrades and replacements.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "e1dc01a6-b63d-478d-9ab5-d2c95a6692a7",
//                             "subdomain": "Asset management",
//                             "mandatory_question": 18,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 506,
//                             "is_active": 1,
//                             "competency": "Facility improvements",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "864efaa7-b96d-479e-83c4-bf6842b4f638",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "386325f2-10e7-4bee-924d-35bab6a2270c",
//                             "question_text": "<p>Conduct periodic site inspections of operations and conditions of machines and equipment.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "e1dc01a6-b63d-478d-9ab5-d2c95a6692a7",
//                             "subdomain": "Asset management",
//                             "mandatory_question": 18,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 468,
//                             "is_active": 1,
//                             "competency": "Facility condition assessment",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "9e561323-d79b-42aa-ba81-bc94fa96c491",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "fcb82739-2e77-4ac6-9140-f5928e0b40ae",
//                             "question_text": "<p>Ensure a focus on improving operations through quality control and optimization.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "e1dc01a6-b63d-478d-9ab5-d2c95a6692a7",
//                             "subdomain": "Asset management",
//                             "mandatory_question": 18,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 462,
//                             "is_active": 1,
//                             "competency": "Facility condition assessment",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "9eee85a7-e3a7-4363-aacd-baf8f892f2ae",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "8dfcdfe4-9d73-4b4c-acc1-2260c1f438a0",
//                             "question_text": "<p>Develop plans and schedules for site infrastructure improvement projects.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "e1dc01a6-b63d-478d-9ab5-d2c95a6692a7",
//                             "subdomain": "Asset management",
//                             "mandatory_question": 18,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 500,
//                             "is_active": 1,
//                             "competency": "Facility improvements",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "a1314dc8-fd6b-433c-9135-d73de224f7c7",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "d36236fd-6ef3-42f8-9b11-c5f9cd971e4d",
//                             "question_text": "<p>Evaluate site equipment for needed upgrades and replacements.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "e1dc01a6-b63d-478d-9ab5-d2c95a6692a7",
//                             "subdomain": "Asset management",
//                             "mandatory_question": 18,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 492,
//                             "is_active": 1,
//                             "competency": "Facility improvements",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "a28648ab-a970-4ae2-b0da-2c8f05703590",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "2ea601a2-60ac-41d4-83ea-56803f259338",
//                             "question_text": "<p>Prioritize site improvement projects to align with the organization's needs and available resources.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "e1dc01a6-b63d-478d-9ab5-d2c95a6692a7",
//                             "subdomain": "Asset management",
//                             "mandatory_question": 18,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 487,
//                             "is_active": 1,
//                             "competency": "Facility improvements",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "bfc3205b-ee37-45a9-95e1-bd80d5a7b5ab",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "b2ce967c-5a8f-423d-a6f9-6a90aa821fe6",
//                             "question_text": "<p>Provide administrative, logistical, and scheduling assistance for renovation, expansion, and new site projects.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "e1dc01a6-b63d-478d-9ab5-d2c95a6692a7",
//                             "subdomain": "Asset management",
//                             "mandatory_question": 18,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 508,
//                             "is_active": 1,
//                             "competency": "Facility improvements",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "d1c76d95-3196-4a24-946c-77e4c1074e55",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "98db9f3c-715d-4d7c-9213-6f896a9f6705",
//                             "question_text": "<p>Determine the optimum delivery method for facility repair, replacement, and improvement projects.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "e1dc01a6-b63d-478d-9ab5-d2c95a6692a7",
//                             "subdomain": "Asset management",
//                             "mandatory_question": 18,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 489,
//                             "is_active": 1,
//                             "competency": "Facility improvements",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "fba91387-c0c5-4521-9654-90e78a51908c",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "eecc53e4-2465-4247-8dcc-e3291c74ed31",
//                             "question_text": "<p>Create facility condition indexes based on asset maintenance and lifecycle information<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "e1dc01a6-b63d-478d-9ab5-d2c95a6692a7",
//                             "subdomain": "Asset management",
//                             "mandatory_question": 18,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 453,
//                             "is_active": 1,
//                             "competency": "Asset management",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         }
//                     ],
//                     "Occupant Health, Safety, and Security": [
//                         {
//                             "id": "039b0e18-d143-49a8-8bbc-4c38e0e89bb4",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "8decbfc0-6587-44a9-80df-a5ca7cf9d7b6",
//                             "question_text": "<p>Assist with audits and inspections, compile data, and report findings to supervisor\/manager.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 599,
//                             "is_active": 1,
//                             "competency": "Health and Safety",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "079cbf8e-3f5d-4435-8697-190b556be2eb",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "19beff15-b775-4cb2-82c6-2f7e04840b96",
//                             "question_text": "<p>Conduct regular workplace health and safety reviews to mitigate potential incidents in the workplace.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 586,
//                             "is_active": 1,
//                             "competency": "Health and Safety",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "0c487480-c4f0-495d-adef-531d2e11e347",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "f6fb2997-f602-4ec8-bebd-5b0525509833",
//                             "question_text": "<p>Assist and support the delivery of safety training.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 592,
//                             "is_active": 1,
//                             "competency": "Health and Safety",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "10fad479-b528-4e12-9e37-1db01d937612",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "99e90602-e671-4403-a430-0e9838b19c9c",
//                             "question_text": "<p>Coordinate and execute the health, safety and security activities related to training and documentation.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 595,
//                             "is_active": 1,
//                             "competency": "Health and Safety",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "13373f39-b567-421a-9cd9-0ff4dfcdbc98",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "345df7df-89c6-4129-b2e8-e96c89502e40",
//                             "question_text": "<p>Establish a health and safety playbook with policy and procedures to assure compliance with organizational objectives, local codes, regulations, and standards.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 578,
//                             "is_active": 1,
//                             "competency": "Health and Safety",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "1a67ee8e-603c-4e3f-a18f-5db28cd90e11",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "c85e1251-1c48-479e-8d60-a2415816a0fe",
//                             "question_text": "<p>Observe and identify environmental hazards in facilities and grounds. <\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 452,
//                             "is_active": 1,
//                             "competency": "Hazard management",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "1a93ad00-8a81-4f0b-ba39-d3625c3e6c8e",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "59a7c3ca-4c53-43bd-860c-63c7613ae07e",
//                             "question_text": "<p>Perform worker safety management for facilities and grounds operations; ensure training and certification renewals<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 603,
//                             "is_active": 1,
//                             "competency": "Worker Safety",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "1b444535-135b-48a2-b322-c53581841da1",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "f9290231-f0fc-4b91-a649-09da2c25ecce",
//                             "question_text": "<p>Monitor, track, and report on security policy violations.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 538,
//                             "is_active": 1,
//                             "competency": "Physical Security and Safety",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "1c250f23-7534-40d4-aa68-1cb40ba580e9",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "9668a257-f121-443e-a3bb-2ce231414ffb",
//                             "question_text": "<p>Oversee the effective implementation of site-specific safety procedures, including lock-out and tag-out procedures.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 521,
//                             "is_active": 1,
//                             "competency": "Hazard management",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "1f5ad82a-0618-4d0c-a223-6524222fc424",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "e8a9268e-143d-4456-ad83-dd97c9517b67",
//                             "question_text": "<p>Develop and align security policies with the requirements of the organization.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 478,
//                             "is_active": 1,
//                             "competency": "Physical Security and Safety",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "348068f0-bade-4aaf-bcc0-7baa62e17319",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "2c3d40d5-a88f-4ce1-b2b8-4e77300f4b78",
//                             "question_text": "<p>Communicate safe work practices and confirm all employees are trained, adopt, and follow safe work practices in accordance with applicable regulations. <\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 567,
//                             "is_active": 1,
//                             "competency": "Health and Safety",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "3aa85268-77c7-4716-9877-cbcc4c9fed57",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "2c4dbd36-8e81-4013-8049-76343d2b4d9f",
//                             "question_text": "<p>Assist in the investigation of health, safety, and security incidents and the preparation of required reports.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 463,
//                             "is_active": 1,
//                             "competency": "Incident Management",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "419c6b3e-e2bd-482b-a4ef-5f09001ec94a",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "06d469fe-a15e-4f83-af15-8cebe47df232",
//                             "question_text": "<p>Administer and manage facility access systems and records.<\/p><p>Implement safety policies and procedures.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 526,
//                             "is_active": 1,
//                             "competency": "Physical Security and Safety",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "465ae6d1-33ec-43e4-9483-8667f59a57e1",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "05bf0524-51e1-4e49-bd00-feb71c98647c",
//                             "question_text": "<p>Coordinate the delivery of safety training across the enterprise.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 582,
//                             "is_active": 1,
//                             "competency": "Health and Safety",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "49c3ab76-aabc-4951-8369-d7fed748a417",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "5710c026-8f40-4c82-81fb-e309e214e650",
//                             "question_text": "<p>Support physical security operations (e.g., badging systems, hardware, CCTV).<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 535,
//                             "is_active": 1,
//                             "competency": "Physical Security and Safety",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "4a957e88-663c-4bf8-a0b6-5e5feea70fef",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "07888817-1be6-46f7-bd5b-db6641c294c2",
//                             "question_text": "<p>Coordinate periodic audits and inspections to confirm  compliance with organizational objectives, local health, safety related codes, and regulations and standards.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 589,
//                             "is_active": 1,
//                             "competency": "Health and Safety",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "51476f42-9e31-4590-b250-c32ca6c0ee9a",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "f9563f44-b4e8-49b3-b1ea-2f0ce44fe722",
//                             "question_text": "<p>Implement security policies that align with the requirements of the organization.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 524,
//                             "is_active": 1,
//                             "competency": "Physical Security and Safety",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "52ec34a4-24e5-4b00-86bb-b18bd09e11c5",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "f4b40500-b67c-4bb6-bb43-ee2d5c5d1343",
//                             "question_text": "<p>Provide administrative support for the implementation of security protocols.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 536,
//                             "is_active": 1,
//                             "competency": "Physical Security and Safety",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "5e24bf40-c9f9-4042-9e4c-06fd511f04a5",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "7b8b026d-cfb0-4a54-8920-16885e2c90c7",
//                             "question_text": "<p>Assist in the delivery of physical security protocols, policies, practices, and programs.  <\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 540,
//                             "is_active": 1,
//                             "competency": "Physical Security and Safety",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "5ff948d8-e1cc-4975-a2dd-054c94ee7ac7",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "a2d2a3ba-895b-4409-ad31-d74ede31a349",
//                             "question_text": "<p>Provide expert advice on the safety program including accident investigations, required reports, and compliance with standards and legislation.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 467,
//                             "is_active": 1,
//                             "competency": "Personnel Security and Safety",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "60940d43-2551-4329-9557-cb820611becf",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "b6ed62b9-19bf-4246-8596-b71f0a99ff27",
//                             "question_text": "<p>Oversee the effective implementation of site-specific safety procedures, including lock-out and tag-out procedures.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 431,
//                             "is_active": 1,
//                             "competency": "Hazard management",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "60fef176-13d5-4bbb-9074-10c585316c49",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "e98524e3-6fbf-439f-b057-1381b2a0c9d5",
//                             "question_text": "<p>Observe and identify worker safety issues in facilities and grounds operations. <\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 605,
//                             "is_active": 1,
//                             "competency": "Worker Safety",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "63094c55-2a50-45c9-925f-5a6d53026453",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "e04d1146-0561-4125-98a6-410445501ce7",
//                             "question_text": "<p>Assist with conducting periodic inspections and surveys to determine compliance with lock-out\/tag-out protocols.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 448,
//                             "is_active": 1,
//                             "competency": "Hazard management",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "65271caf-4996-4238-a31c-2a9d18848827",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "66395967-17d7-467c-8c3c-349b21c5ceee",
//                             "question_text": "<p>Develop physical security standards and an operational playbook<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 531,
//                             "is_active": 1,
//                             "competency": "Physical Security and Safety",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "674e1713-9702-46e8-ba37-bcc2ddce3dc9",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "edb5186d-1555-43c2-8841-6f985c4443e2",
//                             "question_text": "<p>Perform environmental hazard management for facilities and grounds.   <\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 444,
//                             "is_active": 1,
//                             "competency": "Hazard management",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "85a3be44-abc2-4787-a01a-dcac9867785f",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "3fc2435c-dced-4068-ab5f-c56947573b7f",
//                             "question_text": "<p>Train staff on all safety procedures, including lock-out and tag-out procedures.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 440,
//                             "is_active": 1,
//                             "competency": "Hazard management",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "8e3bd9ab-195f-49e9-885d-6dfbcc6108fa",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "4fd56c31-c39a-44c2-81fa-5737b088e402",
//                             "question_text": "<p>Plan and manage health, safety, and security activities related training programs to evaluate the effectiveness for improvement.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 585,
//                             "is_active": 1,
//                             "competency": "Health and Safety",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "9e5df9f5-7a8d-4390-bdf0-27d5cae65eab",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "0e6b9ed9-6e6a-48fe-bfa8-04b2515883f8",
//                             "question_text": "<p>Serve as the key point of escalation for health, safety, and security incident reporting to make sure corrective actions are implemented.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 461,
//                             "is_active": 1,
//                             "competency": "Incident Management",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "9e6b4247-b093-46bc-9890-d1012de8e2f4",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "7d72ffbc-5533-4766-b973-6258bbb38083",
//                             "question_text": "<p>Ensure that training programs meet all regulatory requirements.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 571,
//                             "is_active": 1,
//                             "competency": "Health and Safety",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "9fe875cd-0290-45e4-81df-d6aab29642d7",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "139fd9f1-bbb7-4364-b44f-2369a6af425e",
//                             "question_text": "<p>Collect, review, and analyze security access data and information.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 544,
//                             "is_active": 1,
//                             "competency": "Physical Security and Safety",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "a19331b1-75f4-445d-95ff-9d15f14331fc",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "b7ee1ebe-02a2-40b5-bdbb-b91c2c6f4400",
//                             "question_text": "<p>Administer vendor safety-related accreditation, training, and qualifications.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 529,
//                             "is_active": 1,
//                             "competency": "Physical Security and Safety",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "a3aa619c-609a-400e-8801-fb7c58d9d643",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "4f38e4e2-651a-4db7-8705-1d5989f688fc",
//                             "question_text": "<p> Collect and document vendor safety related information.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 542,
//                             "is_active": 1,
//                             "competency": "Physical Security and Safety",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "a49e597b-1068-467a-a640-3dfade569652",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "9eb18b43-16a9-4345-8bfb-a8c2d618b2c5",
//                             "question_text": "<p>Confirm there are appropriate mitigation strategies for worker safety for facilities and grounds operations.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 601,
//                             "is_active": 1,
//                             "competency": "Worker Safety",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "ab9344d4-c2c1-40bc-b1c8-9c51b43832cf",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "6dcfc49c-2dbb-4a62-b293-ca170c739102",
//                             "question_text": "<p>Manage physical security operations to identify needs (e.g., badging systems, hardware, CCTV).<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 522,
//                             "is_active": 1,
//                             "competency": "Physical Security and Safety",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "ac4bfe80-6143-4f69-bdad-036eb05c4231",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "25d257fc-4dde-4e57-bfe3-3411c7ca8ce4",
//                             "question_text": "<p>Act as the point of contact related to safety incidents. <\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 471,
//                             "is_active": 1,
//                             "competency": "Personnel Security and Safety",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "ae39fd52-968d-44a5-b924-2fc51284ee9e",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "77f9d2a4-f3b3-4e49-9862-be72d655fd00",
//                             "question_text": "<p> Assist with regular workplace health and safety reviews to mitigate potential incidents in the workplace.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 598,
//                             "is_active": 1,
//                             "competency": "Health and Safety",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "aff3a629-afc1-45d3-8897-51e81aa17621",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "f8069169-4d75-4678-b5fe-c8be15e2eff9",
//                             "question_text": "<p>Confirm that the levels of service are consistent with senior management's objectives and budgetary considerations and in line with best practices. <\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 486,
//                             "is_active": 1,
//                             "competency": "Physical Security and Safety",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "b1b197f6-7168-46db-8c70-92db46ffa1e0",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "17415c60-ef48-4beb-a26b-6ff948d2b35d",
//                             "question_text": "<p>Ensure that the physical security standards and playbook complies with all contractual and regulatory requirements.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 498,
//                             "is_active": 1,
//                             "competency": "Physical Security and Safety",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "b297bffe-103e-4e27-b8a2-16aaea3b0f99",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "7e8d4778-7d11-469e-ab19-726123c1c34e",
//                             "question_text": "<p>Review security protocols to confirm alignment with the organization's risk tolerance.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 476,
//                             "is_active": 1,
//                             "competency": "Physical Security and Safety",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "b74a8aeb-29f0-453c-8f5c-141b4d49c35f",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "0b962c32-4176-4df1-8bf6-4a9b88ef3861",
//                             "question_text": "<p>Confirm there are appropriate mitigation strategies for environmental hazards for facilities and grounds. <\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 435,
//                             "is_active": 1,
//                             "competency": "Hazard management",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "b91990fa-692c-4930-a226-6bdcdf89e45d",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "e69f7092-9b7f-4b3e-81af-eccecc4e7cb4",
//                             "question_text": "<p>Develop standards regarding facilities' cleaning and maintenance.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 527,
//                             "is_active": 1,
//                             "competency": "Physical Security and Safety",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "c4dc47a5-af57-4c62-9058-626357bf4b59",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "9bbb2c52-1884-40b8-bdd5-83336be4ae7f",
//                             "question_text": "<p>Develop vendor safety-related accreditation, training, and qualifications.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 490,
//                             "is_active": 1,
//                             "competency": "Physical Security and Safety",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "c56acb28-55ff-4dda-b794-25eea40c3c25",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "8c61f506-9ad7-4d5a-90bb-8e6b8e9f368e",
//                             "question_text": "<p>Administer the safety program, investigate accidents, and complete required reports.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 469,
//                             "is_active": 1,
//                             "competency": "Personnel Security and Safety",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "d00e2a4a-94d0-4a00-bffd-486e97e54afa",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "1a1c38e1-429b-4e86-8740-654e7610d2c2",
//                             "question_text": "<p> Ensure regular workplace health and safety reviews to mitigate potential incidents in the workplace.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 575,
//                             "is_active": 1,
//                             "competency": "Health and Safety",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "d52f3622-476c-40d5-9ae8-78939af78084",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "ff4c8059-04bb-434b-af5a-f24c3bceccea",
//                             "question_text": "<p>Establish safety policies.<\/p><p>Monitor regulatory organizations for new or modified requirements.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 482,
//                             "is_active": 1,
//                             "competency": "Physical Security and Safety",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "f18db3f3-d6e2-431c-b8a5-f16b8d78e985",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "1c662e25-7fc6-4171-95e6-9205864ec887",
//                             "question_text": "<p>Review after action reports to identify gaps and implement recommendations to optimize the program.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "45e99807-a528-4a96-9207-9d9d4f621981",
//                             "subdomain": "Occupant Health, Safety, and Security",
//                             "mandatory_question": 46,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 458,
//                             "is_active": 1,
//                             "competency": "Incident Management",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         }
//                     ],
//                     "Maintenance Processes": [
//                         {
//                             "id": "06f12323-8960-4982-bb7f-41446c0be516",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "d1f441e0-986e-4188-a0ce-c2e3210bced7",
//                             "question_text": "<p>Identify and escalate maintenance issues as required.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 255,
//                             "is_active": 1,
//                             "competency": "Equipment reliability",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "0a83d11c-42cd-42c6-8410-abb311de1658",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "c271ac80-0938-4028-8098-c186bafa1259",
//                             "question_text": "<p>Review critical operations and develop strategies to confirm that all assigned site infrastructure equipment and systems remain functional.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 177,
//                             "is_active": 1,
//                             "competency": "Building systems maintenance",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "2142b9e4-e129-4f37-8b67-fb6ee7d2188e",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "6dfc70af-e73c-4203-87a6-023876c8dda9",
//                             "question_text": "<p>Assist in implementing the organization's maintenance strategies.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 234,
//                             "is_active": 1,
//                             "competency": "Building systems maintenance",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "22614f7f-e3e3-46c9-8405-171044d93142",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "6e2be2c4-6e4b-44b2-bc3d-2c774c5046f7",
//                             "question_text": "<p>Manage critical operations and maintenance of assigned site infrastructure equipment and systems.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 304,
//                             "is_active": 1,
//                             "competency": "Criticality analysis",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "2fe8462f-63c3-4155-9aef-d09f844d7a61",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "63c1bf9b-21f0-4aa4-9710-40555075d230",
//                             "question_text": "<p>Provide or secure expert advice on engineering evaluations and the troubleshooting of equipment abnormalities.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 340,
//                             "is_active": 1,
//                             "competency": "Problem Solving",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "3c963288-7631-4e73-b63c-3082e368fb0a",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "9eecf65f-ae24-4f1e-ba7d-5fafb8eb4066",
//                             "question_text": "<p>Oversee and lead the development and implementation of O&amp;M plans and standard operating procedures (SOPs).<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 243,
//                             "is_active": 1,
//                             "competency": "Oversee and lead the development and implementation of O&M plans and standard operating procedures (",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "40fb3554-bfb0-41de-8eeb-66b899192a95",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "4290626d-5f59-488f-a37f-40abb6c899b9",
//                             "question_text": "<p>Review critical operations and develop strategies to confirm that all assigned site infrastructure equipment and systems remain functional.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 270,
//                             "is_active": 1,
//                             "competency": "Criticality analysis",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "48c949c2-3fb8-4480-9d18-e3747391d629",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "99231422-5904-4366-b4a7-fa1dc3be395c",
//                             "question_text": "<p>Assist in implementing the organization's maintenance strategies.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 329,
//                             "is_active": 1,
//                             "competency": "Maintenance strategies",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "48e7895a-63cf-4fb4-8d4c-d0314d0ed751",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "5533037a-c02b-48fa-815b-d5365f6eca87",
//                             "question_text": "<p>Create and maintain a backlog list of items requiring alternative maintenance support resources and services.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 373,
//                             "is_active": 1,
//                             "competency": "Maintenance sourcing",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "4abc6c98-4702-4f0f-86f7-1ea2a2323891",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "7e045ed6-c15c-4957-baa1-3a8bc498adcc",
//                             "question_text": "<p>Review inventory management system to confirm its efficient and effective support of the facilities operations.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 359,
//                             "is_active": 1,
//                             "competency": "Inventory management",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "4d24987e-d516-4690-97ad-f7ef5cdbe61a",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "b1afd72b-5ced-4795-8195-4725fe8a8fd9",
//                             "question_text": "<p>Benchmark and implement best practices for maintenance programs (e.g., participate in global maintenance community, implement practices to increase overall effectiveness).<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 311,
//                             "is_active": 1,
//                             "competency": "Maintenance strategies",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "4fcdda74-ff7a-43b6-99e4-be6bb8df8de2",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "778a92d7-4981-4cef-9958-d628a67c4f44",
//                             "question_text": "<p>Confirm the availability of an adequate inventory of parts, tools, and other supplies to operate the facilities.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 364,
//                             "is_active": 1,
//                             "competency": "Inventory management",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "5352a017-ce08-4af2-b79e-3df10586e09f",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "d4165569-9afe-45df-88a8-fab43d0d0483",
//                             "question_text": "<p>Benchmark and implement best practices for maintenance programs (e.g., participate in global maintenance community, implement practices to increase overall effectiveness).<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 181,
//                             "is_active": 1,
//                             "competency": "Building systems maintenance",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "552672a9-bba8-43bf-ba73-37b41d230f17",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "38dafbbd-7685-4741-80e4-ab1469c49df3",
//                             "question_text": "<p>Implement preventive, corrective, and predictive maintenance programs.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 318,
//                             "is_active": 1,
//                             "competency": "Maintenance strategies",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "7200f25f-1cf8-4e6d-b232-920c178a4c31",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "051d2b1e-70ba-49b5-a754-c4c98605867b",
//                             "question_text": "<p>Create systems, policies, and procedures that comply with regulatory-required maintenance activities.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 166,
//                             "is_active": 1,
//                             "competency": "Building systems maintenance",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "76ddadee-885a-41a3-a3ab-120c27867ad3",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "2f7171e6-9a57-4d52-8d60-099009419ab4",
//                             "question_text": "<p>Identify, evaluate, and implement alternative maintenance support resources and services.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 370,
//                             "is_active": 1,
//                             "competency": "Maintenance sourcing",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "77e38405-d067-40a4-be3a-b649cfdd5181",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "2352fe3b-967b-4ed2-a364-507b618eaaaf",
//                             "question_text": "<p>Track and record the maintenance of critical site infrastructure and equipment.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 306,
//                             "is_active": 1,
//                             "competency": "Criticality analysis",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "7f72d461-8fea-4d87-898c-5509aa8b9eec",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "b163731d-563d-4089-95f5-f5a40be82e0e",
//                             "question_text": "<p>Oversee building operations and maintenance<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 173,
//                             "is_active": 1,
//                             "competency": "Building systems maintenance",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "80a8307a-b9f7-4a94-b7df-7012789c7c34",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "ca9676da-8b51-40ac-aee9-1763e6f3bf24",
//                             "question_text": "<p>Track and record the maintenance of critical site infrastructure and equipment.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 233,
//                             "is_active": 1,
//                             "competency": "Building systems maintenance",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "8453b374-2e36-4ecd-90c2-9f722024a166",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "31b978df-2ce4-4429-b540-0f1bca464ae2",
//                             "question_text": "<p>Develop programs for alternative maintenance support.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 367,
//                             "is_active": 1,
//                             "competency": "Maintenance sourcing",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "84a32e91-92ec-46c2-8056-b8c4605b3eb0",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "b15614a7-82c5-4abe-bfe6-e8747c7034b8",
//                             "question_text": "<p>Conduct repair and replacement planning.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 221,
//                             "is_active": 1,
//                             "competency": "Building systems maintenance",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "8f9c659d-bc33-4d83-a7ed-4fa7e4e67cc4",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "240385c6-0373-4dd8-bb64-e7a287642bcd",
//                             "question_text": "<p>Review and analyze the outcomes of troubleshooting efforts.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 351,
//                             "is_active": 1,
//                             "competency": "Problem Solving",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "8fa6d6d3-d2c6-43a6-955b-f7a0e82ce9a0",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "0e5c2bd8-aee4-4324-b5ff-93c9a0d3ec24",
//                             "question_text": "<p>Ensure a focus on improving operations through quality control and optimization.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 189,
//                             "is_active": 1,
//                             "competency": "Building systems maintenance",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "984445c1-8b12-405e-9fa8-626895dfd3f5",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "d410a47f-59b1-4d52-94fe-2a45674cebd2",
//                             "question_text": "<p>Record the outcomes of troubleshooting efforts.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 355,
//                             "is_active": 1,
//                             "competency": "Problem Solving",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "9c1cb32c-3abf-4b57-9ce6-9b8cfc3e90f7",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "7b6985b4-6451-4f85-adc1-2bcbddae6fab",
//                             "question_text": "<p>Make sure maintenance issues are addressed in a timely fashion.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 252,
//                             "is_active": 1,
//                             "competency": "Equipment reliability",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "9e45b639-e5ae-4a9f-9448-ae088c4e0af0",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "d1444643-1b2d-473e-a32f-a79fa6934896",
//                             "question_text": "<p>Assist with maintenance engineering evaluations and the troubleshooting of equipment abnormalities.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 352,
//                             "is_active": 1,
//                             "competency": "Problem Solving",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "ad1a6a39-318e-467b-ab86-06e49cb38e62",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "e7886a4b-c070-4002-81b2-a69fc073f19b",
//                             "question_text": "<p>Review repair records and expected useful life of facilities assets and determine the optimum time for replacement or major renewal.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 184,
//                             "is_active": 1,
//                             "competency": "Building systems maintenance",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "add06e01-ce35-4f26-b473-6fac505c1f1f",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "76170dd3-c550-462a-9af4-9543f80ff4ac",
//                             "question_text": "<p>Manage maintenance engineering evaluations and the troubleshooting of equipment abnormalities.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 347,
//                             "is_active": 1,
//                             "competency": "Problem Solving",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "afa1125e-4024-4554-b8f5-db073f687605",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "d3bbfdeb-69c9-4e25-a24d-79bd8d5ecdb2",
//                             "question_text": "<p>Manage maintenance engineering evaluations and the troubleshooting of equipment abnormalities.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 223,
//                             "is_active": 1,
//                             "competency": "Building systems maintenance",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "afd5143a-b031-482d-80d6-55b9c3ca9688",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "e9ef096a-0b4b-4142-a715-2b5a4b6a68b8",
//                             "question_text": "<p>Conduct periodic site inspections of operations and conditions of machines and equipment.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 238,
//                             "is_active": 1,
//                             "competency": "Building systems maintenance",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "b07d6050-f914-490d-8bd3-be1f39d98f16",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "cafb9b3a-7426-47ab-9e7e-030dd68ff069",
//                             "question_text": "<p>Develop a schedule for planned maintenance events.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 227,
//                             "is_active": 1,
//                             "competency": "Building systems maintenance",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "b566e816-731f-4a01-8c0f-d1c2f7f918ea",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "e02cffde-baf7-4c3b-9140-fef75c7d0ca3",
//                             "question_text": "<p>Develop and implement O&amp;M plans and standard operating procedures (SOPs).<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 244,
//                             "is_active": 1,
//                             "competency": "Operations & maintenance planning",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "b9ee47de-ff3f-4abd-899a-0a2a89af11d0",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "59a80577-ac17-44e8-bb9b-2120c1eff069",
//                             "question_text": "<p>Assist in reporting and managing major repairs. <\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 235,
//                             "is_active": 1,
//                             "competency": "Building systems maintenance",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "bf9030a6-83ad-4ff5-8c37-1cb835504af0",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "c5807e32-684a-47df-8c4e-1e77c3c9906b",
//                             "question_text": "<p>Review and analyze maintenance data to improve maintenance processes<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 217,
//                             "is_active": 1,
//                             "competency": "Building systems maintenance",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "c0fae238-bcc3-422a-b5cb-9b482e2ac26b",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "65f77be7-712b-4a4b-8016-f0ad6e2d2199",
//                             "question_text": "<p>Collect information on predictive maintenance programs<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 330,
//                             "is_active": 1,
//                             "competency": "Maintenance strategies",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "c3cfa3d4-b4e4-4aa7-8c3c-b2e0aebb6b86",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "42a81819-50c0-4707-9ba0-dc2bed09d30f",
//                             "question_text": "<p>Support the execution of building systems maintenance <\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 232,
//                             "is_active": 1,
//                             "competency": "Building systems maintenance",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "c47d8ce5-ec9f-4643-a9e4-a246909272dc",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "cf066bf8-400a-433e-be54-bc36cf3eee4e",
//                             "question_text": "<p>Assist with maintenance engineering evaluations and the troubleshooting of equipment abnormalities.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 236,
//                             "is_active": 1,
//                             "competency": "Building systems maintenance",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "d22cee07-47fe-4ebe-be98-9e271fadc9a1",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "af026019-5812-4afb-9b95-3a9bfbc8207b",
//                             "question_text": "<p>Manage critical operations and maintenance of assigned site infrastructure equipment and systems.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 218,
//                             "is_active": 1,
//                             "competency": "Building systems maintenance",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "e8594d13-af98-41ee-b089-4142ed4e7422",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "93af6c80-981e-4b9f-b689-94e66db3e87c",
//                             "question_text": "<p>Administer a system\/process to confirm an adequate inventory of parts for critical building systems.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 361,
//                             "is_active": 1,
//                             "competency": "Inventory management",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "e9092da1-b486-4417-9e03-4fa724f50ede",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "c3f31fa1-3497-4fa5-8d03-96a40a2ee882",
//                             "question_text": "<p>Develop criteria and policies regarding the escalation of maintenance issues.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 250,
//                             "is_active": 1,
//                             "competency": "Equipment reliability",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "eae3be97-6d2c-4263-9413-83103927c0aa",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "d11859ef-8a58-4976-a2b0-2987004c3379",
//                             "question_text": "<p>Maintain documentation on regulatory-required maintenance actions.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 230,
//                             "is_active": 1,
//                             "competency": "Building systems maintenance",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "ed9b4b1e-43d9-4431-a326-944a5f664d54",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "a0eced62-a0b9-4536-8b4e-5118c3f2f32f",
//                             "question_text": "<p>Identify O&amp;M plans and standard operating procedures (SOPs).<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 247,
//                             "is_active": 1,
//                             "competency": "Operations & maintenance planning",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "f25231c7-0c65-4864-bed1-4100146e6ee8",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "0cddf0d8-6362-4e1d-bd5c-fa703374b408",
//                             "question_text": "<p>Confer with management, engineering, and quality control personnel to resolve maintenance problems and recommend measures to improve operations and conditions of machines and equipment<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 224,
//                             "is_active": 1,
//                             "competency": "Building systems maintenance",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "f2d09bc4-87e6-4994-a354-04d8a3d152b5",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "05518fac-6df3-4a92-9999-b0ca752a46e8",
//                             "question_text": "<p>Establish, review, and modify predictive maintenance requirements programmed into the maintenance management system to align with maintenance strategy<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 324,
//                             "is_active": 1,
//                             "competency": "Maintenance strategies",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "f64214bc-7c48-4983-9250-fe3ec1a8ec67",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "9048ead7-f633-403a-836b-f7d33587156f",
//                             "question_text": "<p>Develop training and equipment requirements to improve troubleshooting efficiency and outcomes.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 343,
//                             "is_active": 1,
//                             "competency": "Problem Solving",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "f6bec26c-0031-48bf-b5bd-1938dcba0ba8",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "92f9df76-26bf-4476-b1f9-ba02d7a1a94c",
//                             "question_text": "<p>Develop a strategy to optimize predictive maintenance programs to align with overall maintenance strategy.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 314,
//                             "is_active": 1,
//                             "competency": "Maintenance strategies",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "f8af9e58-1dd0-41dc-b1d6-5b5f1979c7ab",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "2e744f62-dc1c-4831-961a-7f731b20d8a7",
//                             "question_text": "<p>Manage regulatory-required maintenance actions to ensure accuracy, timeliness, and compliance with requirements.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 214,
//                             "is_active": 1,
//                             "competency": "Building systems maintenance",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "fa04ce1a-847d-4445-98b2-c9622d81d068",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "3c68a9fc-09d9-4989-92fc-86946e6f019d",
//                             "question_text": "<p>Implement preventive, corrective, and predictive maintenance programs.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 220,
//                             "is_active": 1,
//                             "competency": "Building systems maintenance",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "fc35c915-62d2-4ff7-a840-47944e79bad3",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "c145477c-3637-4332-80f2-c9613b3873f6",
//                             "question_text": "<p>Provide or secure expert advice on engineering evaluations and the troubleshooting of equipment abnormalities.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5b5e20c0-91c0-431a-93d8-852e86ff4e31",
//                             "subdomain": "Maintenance Processes",
//                             "mandatory_question": 49,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 187,
//                             "is_active": 1,
//                             "competency": "Building systems maintenance",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         }
//                     ],
//                     "Operations processes": [
//                         {
//                             "id": "0807ab86-f9fe-4a39-bb68-dd45d7acc23d",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "00fa1e26-ed37-4a03-a09a-17146eff87dc",
//                             "question_text": "<p>Oversee facility services to ensure customer satisfaction.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "561cc4ce-3614-411d-b0a4-384177e2bddd",
//                             "subdomain": "Operations processes",
//                             "mandatory_question": 18,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 389,
//                             "is_active": 1,
//                             "competency": "Facility operations",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "2186e6b1-4035-4523-8dd4-997aadd847d9",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "6c86cec4-c6fa-4906-8da7-c2096b174f22",
//                             "question_text": "<p>Oversee building operations and maintenance (e.g., plans and operations, equipment inventories, utility operations, energy management, compliance, engineering).<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "561cc4ce-3614-411d-b0a4-384177e2bddd",
//                             "subdomain": "Operations processes",
//                             "mandatory_question": 18,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 398,
//                             "is_active": 1,
//                             "competency": "Facility operations",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "33d1c58a-f82f-4ee1-a144-884964341e42",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "8f435383-b9c7-4dc7-9296-a1e927169a5d",
//                             "question_text": "<p>Assist in the delivery of business support services (such as, hospitality, catering, AV, mailroom, logistics, reception, event management).<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "561cc4ce-3614-411d-b0a4-384177e2bddd",
//                             "subdomain": "Operations processes",
//                             "mandatory_question": 18,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 445,
//                             "is_active": 1,
//                             "competency": "Occupant services",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "3d967de5-82a4-4084-8d33-c87613d879a1",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "1a4e25cb-324c-45d8-ac50-6d4e21e725cc",
//                             "question_text": "<p>Confirm the delivery of business support services  supports the organization's culture and goals.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "561cc4ce-3614-411d-b0a4-384177e2bddd",
//                             "subdomain": "Operations processes",
//                             "mandatory_question": 18,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 437,
//                             "is_active": 1,
//                             "competency": "Occupant services",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "4f1ae8ed-b752-45e0-b701-7f06fb87c2f3",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "3e8d2980-0d36-4789-a01e-ffb575da32a8",
//                             "question_text": "<p>Support the efficient day-to-day operations (e.g., general appearance, cleaning, stocking, maintenance, signage) of all facilities.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "561cc4ce-3614-411d-b0a4-384177e2bddd",
//                             "subdomain": "Operations processes",
//                             "mandatory_question": 18,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 425,
//                             "is_active": 1,
//                             "competency": "Facility operations",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "673d5d61-87e3-4536-adb3-73f2c36212e8",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "10dd139e-6cde-40c8-9a2f-f07d4f4a7e38",
//                             "question_text": "<p>Promote and support the food service program, including food safety quality program.  <\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "561cc4ce-3614-411d-b0a4-384177e2bddd",
//                             "subdomain": "Operations processes",
//                             "mandatory_question": 18,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 419,
//                             "is_active": 1,
//                             "competency": "Facility operations",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "6e9737f5-05d8-4987-bca1-e4fc442c3c62",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "1147fe52-778b-458b-90aa-8b1452d06e40",
//                             "question_text": "<p>Manage the efficient day-to-day operations (e.g., general appearance, cleaning, stocking, maintenance, signage) of all facilities.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "561cc4ce-3614-411d-b0a4-384177e2bddd",
//                             "subdomain": "Operations processes",
//                             "mandatory_question": 18,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 408,
//                             "is_active": 1,
//                             "competency": "Facility operations",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "806cbbb8-ef92-4671-baa9-e67fd30f89fe",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "83b4bbb5-8317-4cdb-a5f3-e0755f875edd",
//                             "question_text": "<p>Oversee the availability and adequacy of resources that allow for the efficient operation of all facilities.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "561cc4ce-3614-411d-b0a4-384177e2bddd",
//                             "subdomain": "Operations processes",
//                             "mandatory_question": 18,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 391,
//                             "is_active": 1,
//                             "competency": "Facility operations",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "9c023097-d857-4370-a047-29fb0daba834",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "23721fc2-d384-4558-aedc-402ab30c1e33",
//                             "question_text": "<p>Assist in scheduling and coordinating facility services.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "561cc4ce-3614-411d-b0a4-384177e2bddd",
//                             "subdomain": "Operations processes",
//                             "mandatory_question": 18,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 421,
//                             "is_active": 1,
//                             "competency": "Facility operations",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "a3e7b130-3a70-4e9c-ad6d-35fdc8e9afc5",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "bb2f225e-bc9a-4be5-9283-c2b3a5453c93",
//                             "question_text": "<p>Manage and recommend business support services (such as hospitality, catering, AV, mailroom, logistics, reception, event management). Create a scorecard for occupancy space conditions; collect occupant feedback and measure occupant satisfaction<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "561cc4ce-3614-411d-b0a4-384177e2bddd",
//                             "subdomain": "Operations processes",
//                             "mandatory_question": 18,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 443,
//                             "is_active": 1,
//                             "competency": "Occupant services",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "ad364b91-07e5-4dae-bdda-5aa10ae8c3fd",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "a636a370-674b-4236-ba84-bfe47556cb3e",
//                             "question_text": "<p>Evaluate the level of compliance of the food safety quality program.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "561cc4ce-3614-411d-b0a4-384177e2bddd",
//                             "subdomain": "Operations processes",
//                             "mandatory_question": 18,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 401,
//                             "is_active": 1,
//                             "competency": "Facility operations",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "b26168c8-ec2c-46eb-8b2f-5986160a7b8c",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "ee130ff9-a28a-489b-9a6c-20ab20307a72",
//                             "question_text": "<p>Confirm that the levels of service are consistent with senior management's objectives and budgetary considerations and in line with best practices. <\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "561cc4ce-3614-411d-b0a4-384177e2bddd",
//                             "subdomain": "Operations processes",
//                             "mandatory_question": 18,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 394,
//                             "is_active": 1,
//                             "competency": "Facility operations",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "b85a1385-a257-4c03-b116-f7cff4817773",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "27d31d5e-ff4b-4cf9-a073-361fe1933092",
//                             "question_text": "<p>Assist in the coordination and delivery of facility cleaning and maintenance.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "561cc4ce-3614-411d-b0a4-384177e2bddd",
//                             "subdomain": "Operations processes",
//                             "mandatory_question": 18,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 428,
//                             "is_active": 1,
//                             "competency": "Facility operations",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "dbd47186-e2c4-446c-98eb-3526e6c41d70",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "029533b3-a236-4ec1-8acb-f86853f9bf54",
//                             "question_text": "<p>Support the execution of building operations and maintenance (e.g., plans and operations, equipment inventories, utility operations, energy management, compliance, and engineering).<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "561cc4ce-3614-411d-b0a4-384177e2bddd",
//                             "subdomain": "Operations processes",
//                             "mandatory_question": 18,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 429,
//                             "is_active": 1,
//                             "competency": "Facility operations",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "dc3eff36-c189-4288-a9ea-878f6e90a3e1",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "92da91e9-74a9-4e0c-8ee8-f9bd18ef3ef3",
//                             "question_text": "<p>Manage facility services and procurements as required (e.g., pest control).<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "561cc4ce-3614-411d-b0a4-384177e2bddd",
//                             "subdomain": "Operations processes",
//                             "mandatory_question": 18,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 405,
//                             "is_active": 1,
//                             "competency": "Facility operations",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "df7b081a-ed36-4fd8-bf6d-4d35b3ac8ed6",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "04c51c14-f0a6-4408-93d5-aca53c81d36c",
//                             "question_text": "<p>Review and analyze maintenance data to improve operations maintenance processes (e.g., personnel are properly trained and adhere to established policies, procedures, and standards).<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "561cc4ce-3614-411d-b0a4-384177e2bddd",
//                             "subdomain": "Operations processes",
//                             "mandatory_question": 18,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 415,
//                             "is_active": 1,
//                             "competency": "Facility operations",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "f15b8e3d-0145-4f53-8be3-a821734bc68d",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "d00afa32-94c2-4369-bc75-615f9197c19d",
//                             "question_text": "<p>Participate in the food service program, including the safety quality program, and track and report on any lapse in safety protocols. <\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "561cc4ce-3614-411d-b0a4-384177e2bddd",
//                             "subdomain": "Operations processes",
//                             "mandatory_question": 18,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 432,
//                             "is_active": 1,
//                             "competency": "Facility operations",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "f3448e9b-abbb-48d3-8caa-9ef0cbfe81cb",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "844f4294-93bd-4a55-8cce-e474856b4ab2",
//                             "question_text": "<p>Develop standards regarding facilities' cleaning and maintenance.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "561cc4ce-3614-411d-b0a4-384177e2bddd",
//                             "subdomain": "Operations processes",
//                             "mandatory_question": 18,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 411,
//                             "is_active": 1,
//                             "competency": "Facility operations",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         }
//                     ],
//                     "Buildings, Building Systems, and Infrastructure and Grounds": [
//                         {
//                             "id": "1ab5c3c6-5693-491c-b06b-1333086bd705",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "2b9184b4-c8bd-4389-918d-c2d56b0a41de",
//                             "question_text": "<p>Maintain and update layout and installation drawings of the facilities and equipment.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5d740cb0-c2c7-4cd5-99b0-ef1112a30429",
//                             "subdomain": "Buildings, Building Systems, and Infrastructure and Grounds",
//                             "mandatory_question": 25,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 98,
//                             "is_active": 1,
//                             "competency": "Facility and equipment documents",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "1d6bd8b1-aec5-4eeb-80d2-138e8c2ff94b",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "e25a4706-b531-434b-b610-f6f82d9ae6a7",
//                             "question_text": "<p>Manage FM technology and building system commissioning process. <\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5d740cb0-c2c7-4cd5-99b0-ef1112a30429",
//                             "subdomain": "Buildings, Building Systems, and Infrastructure and Grounds",
//                             "mandatory_question": 25,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 63,
//                             "is_active": 1,
//                             "competency": "Building commissioning oversight process",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "24a6f248-2601-4578-b44a-6de48b6f41fc",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "e9b779a2-16d5-4c1a-970d-868f088d7061",
//                             "question_text": "<p>Oversee the development of plans, specifications, and scopes of work for property maintenance.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5d740cb0-c2c7-4cd5-99b0-ef1112a30429",
//                             "subdomain": "Buildings, Building Systems, and Infrastructure and Grounds",
//                             "mandatory_question": 25,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 32,
//                             "is_active": 1,
//                             "competency": "Building systems acquisition, installation, and operation",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "31bdb7da-b191-4738-bb40-d2aac910a0c6",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "cf909213-b00e-40e0-a064-59832a72b33c",
//                             "question_text": "<p>Establish a program and\/or process that guides front-llne staff in maintaining facility grounds.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5d740cb0-c2c7-4cd5-99b0-ef1112a30429",
//                             "subdomain": "Buildings, Building Systems, and Infrastructure and Grounds",
//                             "mandatory_question": 25,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 153,
//                             "is_active": 1,
//                             "competency": "Grounds and site elements",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "4bc0fefa-f84a-46d4-b51f-b2de3acbbdff",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "5c5dff61-4cc8-4e47-8355-3a50d60ae2f9",
//                             "question_text": "<p>Develop commissioning protocols with appropriate professional resources to safeguard the reliability of FM technology and building systems<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5d740cb0-c2c7-4cd5-99b0-ef1112a30429",
//                             "subdomain": "Buildings, Building Systems, and Infrastructure and Grounds",
//                             "mandatory_question": 25,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 60,
//                             "is_active": 1,
//                             "competency": "Building commissioning oversight process",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "4bf93973-aae1-4cc0-ad0c-21722b68eb02",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "09ea7523-f460-4019-9091-c17a4e1a455c",
//                             "question_text": "<p>Establish a strategy to confirm all major building equipment is maintained.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5d740cb0-c2c7-4cd5-99b0-ef1112a30429",
//                             "subdomain": "Buildings, Building Systems, and Infrastructure and Grounds",
//                             "mandatory_question": 25,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 26,
//                             "is_active": 1,
//                             "competency": "Building systems acquisition, installation, and operation",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "5de2ca9b-de7b-4db0-96c2-4f03cba2a8af",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "0a1f47bc-38cb-43e7-9fa5-5783ed2a030b",
//                             "question_text": "<p>Assist with complex infrastructure shutdowns.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5d740cb0-c2c7-4cd5-99b0-ef1112a30429",
//                             "subdomain": "Buildings, Building Systems, and Infrastructure and Grounds",
//                             "mandatory_question": 25,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 124,
//                             "is_active": 1,
//                             "competency": "Infrastructure shutdowns",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "6355f969-f931-4180-b43b-ff3658455dcb",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "db41baad-5a9a-47ae-9a14-710100ab6f4e",
//                             "question_text": "<p>Review inspection logs and reports to assess the effectiveness of mechanical and other facilities equipment.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5d740cb0-c2c7-4cd5-99b0-ef1112a30429",
//                             "subdomain": "Buildings, Building Systems, and Infrastructure and Grounds",
//                             "mandatory_question": 25,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 48,
//                             "is_active": 1,
//                             "competency": "Building systems acquisition, installation, and operation",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "6718296e-d166-4469-b2d2-640b18266575",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "16dc3588-13ce-4102-9ec2-d6f6329457ae",
//                             "question_text": "<p>Assist in inspection and planning for implementation of necessary interior and exterior renewal and replacement.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5d740cb0-c2c7-4cd5-99b0-ef1112a30429",
//                             "subdomain": "Buildings, Building Systems, and Infrastructure and Grounds",
//                             "mandatory_question": 25,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 139,
//                             "is_active": 1,
//                             "competency": "Interior & exterior elements",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "6f7e9c34-b1ce-4b84-ac85-89f0fcd21001",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "7becdeb7-2c98-4466-a53e-f46e9cb34987",
//                             "question_text": "<p>Assist in inspecting and evaluating the effectiveness of mechanical and other facility equipment.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5d740cb0-c2c7-4cd5-99b0-ef1112a30429",
//                             "subdomain": "Buildings, Building Systems, and Infrastructure and Grounds",
//                             "mandatory_question": 25,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 53,
//                             "is_active": 1,
//                             "competency": "Building systems acquisition, installation, and operation",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "794b5ecc-7f9b-46bf-a608-ec5905993805",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "29abaa1d-7943-48b0-94ac-154b9e286a57",
//                             "question_text": "<p>Lead and oversee the strategy for facility design layout and equipment installation<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5d740cb0-c2c7-4cd5-99b0-ef1112a30429",
//                             "subdomain": "Buildings, Building Systems, and Infrastructure and Grounds",
//                             "mandatory_question": 25,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 89,
//                             "is_active": 1,
//                             "competency": "Facility and equipment documents",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "7c701781-5c62-4781-a0a5-9ff9def54dc0",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "5bc1869e-e7df-40ed-b0ec-9441ec02ff3c",
//                             "question_text": "<p>Develop and manage property maintenance plans, scopes of work, and schedules.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5d740cb0-c2c7-4cd5-99b0-ef1112a30429",
//                             "subdomain": "Buildings, Building Systems, and Infrastructure and Grounds",
//                             "mandatory_question": 25,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 36,
//                             "is_active": 1,
//                             "competency": "Building systems acquisition, installation, and operation",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "7d085af7-9c28-4252-97cd-5a4aed45cddb",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "6ff5261d-c7df-4f09-b652-baed089ca805",
//                             "question_text": "<p>Manage regulatory-required maintenance actions to ensure accuracy, timeliness, and compliance with requirements. <\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5d740cb0-c2c7-4cd5-99b0-ef1112a30429",
//                             "subdomain": "Buildings, Building Systems, and Infrastructure and Grounds",
//                             "mandatory_question": 25,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 74,
//                             "is_active": 1,
//                             "competency": "Building systems & Controls",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "933d3b24-d71b-4727-8445-b208de836971",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "70f7a40b-8bde-4672-9023-d3782a4055ff",
//                             "question_text": "<p>Ensure the use of building control systems to enhance the efficiency of building systems. <\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5d740cb0-c2c7-4cd5-99b0-ef1112a30429",
//                             "subdomain": "Buildings, Building Systems, and Infrastructure and Grounds",
//                             "mandatory_question": 25,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 70,
//                             "is_active": 1,
//                             "competency": "Building systems & Controls",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "9485af16-fa62-4b28-8a60-48e658d1c056",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "9e016175-645c-4b7d-9d27-108c46d06c4a",
//                             "question_text": "<p>Evaluate the effectiveness of the grounds maintenance program.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5d740cb0-c2c7-4cd5-99b0-ef1112a30429",
//                             "subdomain": "Buildings, Building Systems, and Infrastructure and Grounds",
//                             "mandatory_question": 25,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 149,
//                             "is_active": 1,
//                             "competency": "Grounds and site elements",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "96405f09-c0f5-447b-b258-76c4f8aaab1e",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "9aecfdaa-686f-4655-a880-6fbd9f57b782",
//                             "question_text": "<p>Explain how the use of building control systems supports the facility management operations.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5d740cb0-c2c7-4cd5-99b0-ef1112a30429",
//                             "subdomain": "Buildings, Building Systems, and Infrastructure and Grounds",
//                             "mandatory_question": 25,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 82,
//                             "is_active": 1,
//                             "competency": "Building systems & Controls",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "995cac27-0a5a-4f13-9909-f885132d874d",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "84c49a37-fec9-4159-9cf9-8c8322dbaad1",
//                             "question_text": "<p>Determine the prequalification requirements for designers and contractors to support property due diligence, planning and construction, and renovation activities. Build long-range plan to address renewal and replacement with probable capital expenditures<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5d740cb0-c2c7-4cd5-99b0-ef1112a30429",
//                             "subdomain": "Buildings, Building Systems, and Infrastructure and Grounds",
//                             "mandatory_question": 25,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 130,
//                             "is_active": 1,
//                             "competency": "Interior & exterior elements",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "9a9198ce-fe16-4b71-a4f9-526c085f7cac",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "254c7ce4-2952-4a4c-9df9-90d542c049e6",
//                             "question_text": "<p>Manage building equpment installation, maintenance, and repair to ensure cost-effectiveness and efficency.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5d740cb0-c2c7-4cd5-99b0-ef1112a30429",
//                             "subdomain": "Buildings, Building Systems, and Infrastructure and Grounds",
//                             "mandatory_question": 25,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 29,
//                             "is_active": 1,
//                             "competency": "Building systems acquisition, installation, and operation",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "ac7bd10b-af94-4f3d-b324-df74a765ed0c",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "592297fe-a3a6-440a-8b64-6efee5e14e93",
//                             "question_text": "<p>Coordinate and facilitate complex infrastructure shutdowns.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5d740cb0-c2c7-4cd5-99b0-ef1112a30429",
//                             "subdomain": "Buildings, Building Systems, and Infrastructure and Grounds",
//                             "mandatory_question": 25,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 117,
//                             "is_active": 1,
//                             "competency": "Infrastructure shutdowns",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "aea57e67-7627-4702-a4d5-6f7af7b5d3ba",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "f57515f7-bd93-4a37-9706-e5ccaa1e0eb2",
//                             "question_text": "<p>Set guidelines for work activities and appropriate long-term care for interior and exterior elements<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5d740cb0-c2c7-4cd5-99b0-ef1112a30429",
//                             "subdomain": "Buildings, Building Systems, and Infrastructure and Grounds",
//                             "mandatory_question": 25,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 132,
//                             "is_active": 1,
//                             "competency": "Interior & exterior elements",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "b2ff3344-12c0-49c9-a6cb-2c761912cf73",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "62102592-2974-4f86-b1c9-18e2f709f175",
//                             "question_text": "<p>Manage the design process and staff involvement in facility design layout and equipment installation<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5d740cb0-c2c7-4cd5-99b0-ef1112a30429",
//                             "subdomain": "Buildings, Building Systems, and Infrastructure and Grounds",
//                             "mandatory_question": 25,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 92,
//                             "is_active": 1,
//                             "competency": "Facility and equipment documents",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "e8560f95-d5d6-4921-8ce0-62a6a682d2ad",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "fe059b0a-056c-486b-82ce-0e4b079295cd",
//                             "question_text": "<p>Develop processes, procedures, and communications plans for complex infrastructure shutdowns.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5d740cb0-c2c7-4cd5-99b0-ef1112a30429",
//                             "subdomain": "Buildings, Building Systems, and Infrastructure and Grounds",
//                             "mandatory_question": 25,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 111,
//                             "is_active": 1,
//                             "competency": "Infrastructure shutdowns",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "f2c46dbc-0616-41e1-947e-8a2b9baa70c6",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "6d0acdc8-e01b-4d67-a166-5a1951cf8de5",
//                             "question_text": "<p>Assist in developing scopes of work for property maintenance by investigating issues, inspecting sites, and reporting findings.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5d740cb0-c2c7-4cd5-99b0-ef1112a30429",
//                             "subdomain": "Buildings, Building Systems, and Infrastructure and Grounds",
//                             "mandatory_question": 25,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 40,
//                             "is_active": 1,
//                             "competency": "Building systems acquisition, installation, and operation",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "f3aa807b-e7b0-4430-ba77-24b836b36578",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "1d3a360e-c48b-4d84-b577-6cd63d89d4ba",
//                             "question_text": "<p>Assist in inspecting and evaluating facility grounds, buildings, and sites to make sure operations are safe and efficient.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5d740cb0-c2c7-4cd5-99b0-ef1112a30429",
//                             "subdomain": "Buildings, Building Systems, and Infrastructure and Grounds",
//                             "mandatory_question": 25,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 156,
//                             "is_active": 1,
//                             "competency": "Grounds and site elements",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "fa92da8e-0ed9-4879-99a1-13dbe0763222",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "934e3426-69d7-472c-8695-9df89cbfa55f",
//                             "question_text": "<p>Develop processes and procedures for overseeing the inspection of mechanical and other facilities equipment.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "5d740cb0-c2c7-4cd5-99b0-ef1112a30429",
//                             "subdomain": "Buildings, Building Systems, and Infrastructure and Grounds",
//                             "mandatory_question": 25,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 45,
//                             "is_active": 1,
//                             "competency": "Building systems acquisition, installation, and operation",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         }
//                     ],
//                     "Furniture, fixtures and equipment (FF&E)": [
//                         {
//                             "id": "27149bfb-620b-42ce-b2d9-b1af39b2af6d",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "01d59487-51af-4860-87fa-80a112d75e46",
//                             "question_text": "<p>Suggest solutions to facility users to improve process and equipment reliability.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "32829d45-7ee4-4e62-b993-0fa21f49db23",
//                             "subdomain": "Furniture, fixtures and equipment (FF&E)",
//                             "mandatory_question": 9,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 512,
//                             "is_active": 1,
//                             "competency": "Equipment reliability",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "304c73bd-7663-4491-9f6a-7b456cac8a2e",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "5efbb554-e7d6-469c-9683-e99849097cb8",
//                             "question_text": "<p>Monitor and report on process and equipment reliability.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "32829d45-7ee4-4e62-b993-0fa21f49db23",
//                             "subdomain": "Furniture, fixtures and equipment (FF&E)",
//                             "mandatory_question": 9,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 516,
//                             "is_active": 1,
//                             "competency": "Equipment reliability",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "39eb51a9-a2bb-47a1-b349-492fd68cdc68",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "7e1973e9-b6c1-4d87-84fc-ec32214dc20b",
//                             "question_text": "<p>Work with facility users to improve process and equipment reliability.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "32829d45-7ee4-4e62-b993-0fa21f49db23",
//                             "subdomain": "Furniture, fixtures and equipment (FF&E)",
//                             "mandatory_question": 9,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 515,
//                             "is_active": 1,
//                             "competency": "Equipment reliability",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "580ff134-3e77-4c78-aef4-65cf453776d5",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "aba441da-3f2d-4415-b2c3-39233701d215",
//                             "question_text": "<p>Develop schedule for lifecycle requirements of FFE meeting operation and maintenance guidelines.  Establish timeframe and budget for replacement based on lifecycle expectations and actual conditions. <\/p><p>op schedule for <\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "32829d45-7ee4-4e62-b993-0fa21f49db23",
//                             "subdomain": "Furniture, fixtures and equipment (FF&E)",
//                             "mandatory_question": 9,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 497,
//                             "is_active": 1,
//                             "competency": "Furniture, fixtures, and equipment (FFE)",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "9cf7c520-ee15-4e6a-b37b-28af3bdd3c2c",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "fc5eedcd-170d-4c87-a197-328b01f745ef",
//                             "question_text": "<p>Assist in the planning, logistics, and installation of decor items and furniture, fixtures, and equipment (FF&amp;E).<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "32829d45-7ee4-4e62-b993-0fa21f49db23",
//                             "subdomain": "Furniture, fixtures and equipment (FF&E)",
//                             "mandatory_question": 9,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 494,
//                             "is_active": 1,
//                             "competency": "Furniture, fixtures, and equipment (FFE)",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "a6b60879-1c86-4192-bda8-218a5409177a",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "81a1f342-b0c1-496b-ab51-ece7474093ee",
//                             "question_text": "<p>Develop and audit FF&amp;E standards to confirm alignment with organizational needs.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "32829d45-7ee4-4e62-b993-0fa21f49db23",
//                             "subdomain": "Furniture, fixtures and equipment (FF&E)",
//                             "mandatory_question": 9,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 484,
//                             "is_active": 1,
//                             "competency": "Furniture, fixtures, and equipment (FFE)",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "afd6ce4c-3770-49c0-8d74-c1be73e8c602",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "800f8c70-0a49-4977-bba3-59911b440fb3",
//                             "question_text": "<p>Oversee and confirm maintenance of FF&amp;E inventory and repairs.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "32829d45-7ee4-4e62-b993-0fa21f49db23",
//                             "subdomain": "Furniture, fixtures and equipment (FF&E)",
//                             "mandatory_question": 9,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 502,
//                             "is_active": 1,
//                             "competency": "Furniture, fixtures, and equipment (FFE)",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "cf5029d9-cd9d-46e1-9ad4-81976c6556cc",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "e0aa2aa5-4479-4992-b897-45d5f4f6cbdf",
//                             "question_text": "<p>Maintain FF&amp;E inventory; provide maintenance and repairs.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "32829d45-7ee4-4e62-b993-0fa21f49db23",
//                             "subdomain": "Furniture, fixtures and equipment (FF&E)",
//                             "mandatory_question": 9,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 509,
//                             "is_active": 1,
//                             "competency": "Furniture, fixtures, and equipment (FFE)",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "d6e54671-f925-4975-802a-614e634a4333",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "cd1dcc91-ac3e-4a43-b89e-e279558578c0",
//                             "question_text": "<p>Oversee the planning, logistics, and installation of decor items and furniture, fixtures, and equipment (FF&amp;E).<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "32829d45-7ee4-4e62-b993-0fa21f49db23",
//                             "subdomain": "Furniture, fixtures and equipment (FF&E)",
//                             "mandatory_question": 9,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 491,
//                             "is_active": 1,
//                             "competency": "Furniture, fixtures, and equipment (FFE)",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         }
//                     ],
//                     "Work management systems": [
//                         {
//                             "id": "33906818-252c-4be9-94a1-868cb1c8602d",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "174466af-5f71-43cc-8639-e63f09a61df2",
//                             "question_text": "<p>Evaluate the effectiveness of work management practices and procedures.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "dfe64514-aa79-42b4-991c-cc0a41cf2ab3",
//                             "subdomain": "Work management systems",
//                             "mandatory_question": 12,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 548,
//                             "is_active": 1,
//                             "competency": "Work order management systems",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "6c56c1a3-0e93-438a-9b20-306a18497619",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "dad859ab-570f-4c3a-b57d-5690ba87b701",
//                             "question_text": "<p>Approve the proposed process to improve documentation process (e.g., work order).<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "dfe64514-aa79-42b4-991c-cc0a41cf2ab3",
//                             "subdomain": "Work management systems",
//                             "mandatory_question": 12,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 561,
//                             "is_active": 1,
//                             "competency": "Documentation management",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "6e31a896-d5dd-446d-91ed-276b439b798d",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "6aba75c5-e180-4c14-9046-9d6016be05c0",
//                             "question_text": "<p>Prepare work schedules, assign work, and oversee the work product while minimizing overtime and call-in hours.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "dfe64514-aa79-42b4-991c-cc0a41cf2ab3",
//                             "subdomain": "Work management systems",
//                             "mandatory_question": 12,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 550,
//                             "is_active": 1,
//                             "competency": "Work order management systems",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "6ed594de-be51-42e8-a4d5-dd02f5f05184",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "911eefef-a5d1-4578-ae8a-6ef1abd28bcb",
//                             "question_text": "<p>Define work-order assignments; review that assignments are completed; make sure that data are added to the work-order management system.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "dfe64514-aa79-42b4-991c-cc0a41cf2ab3",
//                             "subdomain": "Work management systems",
//                             "mandatory_question": 12,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 557,
//                             "is_active": 1,
//                             "competency": "Work order management systems",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "a82107b3-75a7-4e0e-808d-77bf9ec95c9d",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "2968acb8-b817-45f7-811e-94fb10689044",
//                             "question_text": "<p>Evaluate the effectiveness of the work-order management system and suggest modifications\/improvements where appropriate.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "dfe64514-aa79-42b4-991c-cc0a41cf2ab3",
//                             "subdomain": "Work management systems",
//                             "mandatory_question": 12,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 554,
//                             "is_active": 1,
//                             "competency": "Work order management systems",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "aa3f3356-abeb-46c7-975f-4b350a843969",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "61c3e9b8-f25c-4e34-8829-99351593e4e7",
//                             "question_text": "<p>Review recommendations to improve the documentation process (e.g., work order).<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "dfe64514-aa79-42b4-991c-cc0a41cf2ab3",
//                             "subdomain": "Work management systems",
//                             "mandatory_question": 12,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 564,
//                             "is_active": 1,
//                             "competency": "Documentation management",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "b6dff463-d3d0-4930-8e26-140f3d3c197b",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "0136c3a3-eeaa-47c7-8f54-346be7d47cef",
//                             "question_text": "<p>Organize, monitor, and distribute preventive maintenance work orders to staff and required vendors.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "dfe64514-aa79-42b4-991c-cc0a41cf2ab3",
//                             "subdomain": "Work management systems",
//                             "mandatory_question": 12,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 552,
//                             "is_active": 1,
//                             "competency": "Work order management systems",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "ccfd6c04-3242-498d-b8b8-a59e9a65e0f0",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "d98b8f03-84ef-4659-9163-8830afc969e0",
//                             "question_text": "<p>Create and maintain a backlog list of items requiring alternative maintenance support resources and services.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "dfe64514-aa79-42b4-991c-cc0a41cf2ab3",
//                             "subdomain": "Work management systems",
//                             "mandatory_question": 12,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 579,
//                             "is_active": 1,
//                             "competency": "Alternative maintenance support resources",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "d715a787-6e66-4090-8163-ff4ae5e57332",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "039352ce-3d27-4fbc-9e9d-c0fba2e54e1b",
//                             "question_text": "<p>Develop programs for alternative maintenance support.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "dfe64514-aa79-42b4-991c-cc0a41cf2ab3",
//                             "subdomain": "Work management systems",
//                             "mandatory_question": 12,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 570,
//                             "is_active": 1,
//                             "competency": "Alternative maintenance support resources",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "dfd24bab-6d32-40e1-9da5-9c61467f86d8",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "e233c26d-d7d1-4801-9ea4-a35429f90107",
//                             "question_text": "<p>Identify, evaluate, and implement alternative maintenance support resources and services.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "dfe64514-aa79-42b4-991c-cc0a41cf2ab3",
//                             "subdomain": "Work management systems",
//                             "mandatory_question": 12,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 574,
//                             "is_active": 1,
//                             "competency": "Alternative maintenance support resources",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "f6566d2e-ba42-4e32-9cc8-98120fa1cbfd",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "a82e46d9-5f3a-4775-b894-eb0e940e61ff",
//                             "question_text": "<p>Recommend ways to improve the documentation process (e.g., work order).<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "dfe64514-aa79-42b4-991c-cc0a41cf2ab3",
//                             "subdomain": "Work management systems",
//                             "mandatory_question": 12,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 566,
//                             "is_active": 1,
//                             "competency": "Documentation management",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "fd99678f-20f6-472f-af5e-e512ed0cd346",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "2c8875ec-0a0c-44e1-8b55-cdb6a23de7a1",
//                             "question_text": "<p>Collect and input data for work-order management systems.<\/p>",
//                             "domain_id": "9683d88e-e768-4e07-8b50-fa8195d61bde",
//                             "domain": "Facility Operations",
//                             "subdomain_id": "dfe64514-aa79-42b4-991c-cc0a41cf2ab3",
//                             "subdomain": "Work management systems",
//                             "mandatory_question": 12,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 559,
//                             "is_active": 1,
//                             "competency": "Work order management systems",
//                             "domain_seqeunce": 2,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         }
//                     ]
//                 },
//                 "Risk Management": {
//                     "Risk management planning": [
//                         {
//                             "id": "0e20fced-369b-4b00-86a7-29bd6450828e",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "0f6d541d-a6f3-403f-95cf-fba27af24ac4",
//                             "question_text": "<p>Advise and consult members of the leadership team concerning the assessment of FM related risks and their impact on the demand organization.<\/p>",
//                             "domain_id": "92b8217d-7870-47c4-8234-251277657ec8",
//                             "domain": "Risk Management",
//                             "subdomain_id": "326c6bc1-f4aa-41d7-8171-b73d6487ac7e",
//                             "subdomain": "Risk management planning",
//                             "mandatory_question": 15,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 163,
//                             "is_active": 1,
//                             "competency": "Risk Assessment",
//                             "domain_seqeunce": 3,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "11670929-4675-472f-b0d7-971a21a42bad",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "f4669606-e500-49bf-83b9-38949fc807bc",
//                             "question_text": "<p>Address threats, risks, and vulnerabilities related to compliance with standards and regulations.<\/p>",
//                             "domain_id": "92b8217d-7870-47c4-8234-251277657ec8",
//                             "domain": "Risk Management",
//                             "subdomain_id": "326c6bc1-f4aa-41d7-8171-b73d6487ac7e",
//                             "subdomain": "Risk management planning",
//                             "mandatory_question": 15,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 129,
//                             "is_active": 1,
//                             "competency": "Enterprise risk (ERM)",
//                             "domain_seqeunce": 3,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "1d42b1ef-9cdb-4071-b6a7-95d759bce2c1",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "e7aca823-f959-459e-9cdd-e054e6fbfa87",
//                             "question_text": "<p>Assist in identifying FM risks and vulnerabilities to occupants, physical assets, systems, and essential resources.<\/p>",
//                             "domain_id": "92b8217d-7870-47c4-8234-251277657ec8",
//                             "domain": "Risk Management",
//                             "subdomain_id": "326c6bc1-f4aa-41d7-8171-b73d6487ac7e",
//                             "subdomain": "Risk management planning",
//                             "mandatory_question": 15,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 141,
//                             "is_active": 1,
//                             "competency": "Operational risks",
//                             "domain_seqeunce": 3,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "527f8c08-3f5a-4c16-8b6f-9eae72e7aaab",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "6819dfee-6399-40f3-b44f-a97188ef3b48",
//                             "question_text": "<p>Lead risk management planning and align FM risk management plans with the demand organization and coordinate with other functional representatives and required experts.<\/p>",
//                             "domain_id": "92b8217d-7870-47c4-8234-251277657ec8",
//                             "domain": "Risk Management",
//                             "subdomain_id": "326c6bc1-f4aa-41d7-8171-b73d6487ac7e",
//                             "subdomain": "Risk management planning",
//                             "mandatory_question": 15,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 107,
//                             "is_active": 1,
//                             "competency": "Risk management planning",
//                             "domain_seqeunce": 3,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "6f7b0ff3-e570-43a8-bd73-535a8abe2ea4",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "63217134-c9d8-417d-afe3-6fe474f62879",
//                             "question_text": "<p>Assist in identifying FM risks and vulnerabilities to occupants, physical assets, systems, and essential resources.<\/p>",
//                             "domain_id": "92b8217d-7870-47c4-8234-251277657ec8",
//                             "domain": "Risk Management",
//                             "subdomain_id": "326c6bc1-f4aa-41d7-8171-b73d6487ac7e",
//                             "subdomain": "Risk management planning",
//                             "mandatory_question": 15,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 167,
//                             "is_active": 1,
//                             "competency": "Risk Assessment",
//                             "domain_seqeunce": 3,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "790b11de-31d7-4c73-a87f-95b258daa0ae",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "0e361d55-0e16-46f6-bd00-58d68824a79b",
//                             "question_text": "<p>Establish policies that support compliance with standards and regulations and inform the leadership team of those policies.<\/p>",
//                             "domain_id": "92b8217d-7870-47c4-8234-251277657ec8",
//                             "domain": "Risk Management",
//                             "subdomain_id": "326c6bc1-f4aa-41d7-8171-b73d6487ac7e",
//                             "subdomain": "Risk management planning",
//                             "mandatory_question": 15,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 146,
//                             "is_active": 1,
//                             "competency": "Operational risks",
//                             "domain_seqeunce": 3,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "846154fb-48bb-4dc5-89d1-37338bafbec0",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "bb03aecf-2967-48f8-a2e0-7193846ba063",
//                             "question_text": "<p>Ensure risk assessment is conducted at a frequency appropriate to the demand organization business practices and expectations.<\/p>",
//                             "domain_id": "92b8217d-7870-47c4-8234-251277657ec8",
//                             "domain": "Risk Management",
//                             "subdomain_id": "326c6bc1-f4aa-41d7-8171-b73d6487ac7e",
//                             "subdomain": "Risk management planning",
//                             "mandatory_question": 15,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 121,
//                             "is_active": 1,
//                             "competency": "Enterprise risk (ERM)",
//                             "domain_seqeunce": 3,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "9458b061-3624-413d-bcc8-eafe9cddc0ce",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "d9d69b96-4405-4633-8e6c-56b05683e15a",
//                             "question_text": "<p>Participate on the leadership team to identify, assess, and manage threats, risks, and vulnerabilities to occupants, physical assets, systems, and essential resourses that would interrupt or halt business processes if compromised or damaged.<\/p>",
//                             "domain_id": "92b8217d-7870-47c4-8234-251277657ec8",
//                             "domain": "Risk Management",
//                             "subdomain_id": "326c6bc1-f4aa-41d7-8171-b73d6487ac7e",
//                             "subdomain": "Risk management planning",
//                             "mandatory_question": 15,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 124,
//                             "is_active": 1,
//                             "competency": "Enterprise risk (ERM)",
//                             "domain_seqeunce": 3,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "97394bf4-c12c-46fc-99e9-9d67ad18c7e4",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "1b2a12f1-b5fc-434c-8238-08dd55c21464",
//                             "question_text": "<p>Advise and consult members of the leadership team concerning the assessment of FM related risks and their impact on the organization.<\/p>",
//                             "domain_id": "92b8217d-7870-47c4-8234-251277657ec8",
//                             "domain": "Risk Management",
//                             "subdomain_id": "326c6bc1-f4aa-41d7-8171-b73d6487ac7e",
//                             "subdomain": "Risk management planning",
//                             "mandatory_question": 15,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 134,
//                             "is_active": 1,
//                             "competency": "Operational risks",
//                             "domain_seqeunce": 3,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "a75f1450-ae17-4454-b20b-57f4db0489b3",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "54f8a2db-e45d-4e2d-b9bb-7901cf736f81",
//                             "question_text": "<p>Participate on the leadership team to identify, assess, and manage threats, risks, and vulnerabilities to occupants, physical assets, systems, and essential resourses that would interrupt or halt business processes if compromised or damaged.<\/p>",
//                             "domain_id": "92b8217d-7870-47c4-8234-251277657ec8",
//                             "domain": "Risk Management",
//                             "subdomain_id": "326c6bc1-f4aa-41d7-8171-b73d6487ac7e",
//                             "subdomain": "Risk management planning",
//                             "mandatory_question": 15,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 164,
//                             "is_active": 1,
//                             "competency": "Risk Assessment",
//                             "domain_seqeunce": 3,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "b0a49c37-22d0-4b5c-8b7b-9ba3d048e01b",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "836abf14-9a1d-4cd8-8701-2a4c4da1fe7b",
//                             "question_text": "<p>Develop, implement, and evaluate risk management plans.<\/p>",
//                             "domain_id": "92b8217d-7870-47c4-8234-251277657ec8",
//                             "domain": "Risk Management",
//                             "subdomain_id": "326c6bc1-f4aa-41d7-8171-b73d6487ac7e",
//                             "subdomain": "Risk management planning",
//                             "mandatory_question": 15,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 112,
//                             "is_active": 1,
//                             "competency": "Risk management planning",
//                             "domain_seqeunce": 3,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "bd9ff007-1ffa-4821-9ab0-bf5ca39cbc55",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "203c9eb9-6ebe-40e7-b1e4-e90565c46aa0",
//                             "question_text": "<p>Assist with identifying and addressing threats, risks, and vulnerabilities related to non-compliance with standards and regulations.<\/p>",
//                             "domain_id": "92b8217d-7870-47c4-8234-251277657ec8",
//                             "domain": "Risk Management",
//                             "subdomain_id": "326c6bc1-f4aa-41d7-8171-b73d6487ac7e",
//                             "subdomain": "Risk management planning",
//                             "mandatory_question": 15,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 161,
//                             "is_active": 1,
//                             "competency": "Operational risks",
//                             "domain_seqeunce": 3,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "c226cd5d-227a-451c-a121-dbb70a15dd35",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "868c606f-aec3-4a83-8990-5ef6813a02f8",
//                             "question_text": "<p>Assist with developing and evaluating risk management plans.<\/p>",
//                             "domain_id": "92b8217d-7870-47c4-8234-251277657ec8",
//                             "domain": "Risk Management",
//                             "subdomain_id": "326c6bc1-f4aa-41d7-8171-b73d6487ac7e",
//                             "subdomain": "Risk management planning",
//                             "mandatory_question": 15,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 115,
//                             "is_active": 1,
//                             "competency": "Risk management planning",
//                             "domain_seqeunce": 3,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "ef058ada-51ca-4a66-b35d-5187c979690d",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "b65ada20-989f-4322-b3c2-db218b22ccae",
//                             "question_text": "<p>Address threats, risks, and vulnerabilities related to non-compliance with standards and regulations.<\/p>",
//                             "domain_id": "92b8217d-7870-47c4-8234-251277657ec8",
//                             "domain": "Risk Management",
//                             "subdomain_id": "326c6bc1-f4aa-41d7-8171-b73d6487ac7e",
//                             "subdomain": "Risk management planning",
//                             "mandatory_question": 15,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 158,
//                             "is_active": 1,
//                             "competency": "Operational risks",
//                             "domain_seqeunce": 3,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "fde10531-c18f-4712-8073-c52d9a49ff13",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "807664ee-a4dc-4b01-ad4d-c911e2ea0c62",
//                             "question_text": "<p>Participate on the leadership team to identify, assess, and manage threats, risks, and vulnerabilities to occupants, physical assets, systems, and essential resourses that would interrupt or halt business processes if compromised or damaged.<\/p>",
//                             "domain_id": "92b8217d-7870-47c4-8234-251277657ec8",
//                             "domain": "Risk Management",
//                             "subdomain_id": "326c6bc1-f4aa-41d7-8171-b73d6487ac7e",
//                             "subdomain": "Risk management planning",
//                             "mandatory_question": 15,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 137,
//                             "is_active": 1,
//                             "competency": "Operational risks",
//                             "domain_seqeunce": 3,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         }
//                     ],
//                     "Facility resilience and business continuity": [
//                         {
//                             "id": "1a1c351c-69e2-45d8-8638-0d13326d61e8",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "4352dd0c-695b-4e00-8056-c7cde19cc44c",
//                             "question_text": "<p>Ensure that senior management understands the potential impact of a facility loss and provides adequate funding to prevent or mitigate potential losses.<\/p>",
//                             "domain_id": "92b8217d-7870-47c4-8234-251277657ec8",
//                             "domain": "Risk Management",
//                             "subdomain_id": "33f5e305-8d63-435a-991c-76f44b23399a",
//                             "subdomain": "Facility resilience and business continuity",
//                             "mandatory_question": 12,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 57,
//                             "is_active": 1,
//                             "competency": "Business continuity planning",
//                             "domain_seqeunce": 3,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "23c5dba3-2b0f-4554-93ae-3c634b8754a9",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "5e71f46d-abcf-460f-942f-e3544af34960",
//                             "question_text": "<p>Identify and manage critical facility systems, key vulnerabilities, and essential functions that impact the reliability of the facility.<\/p>",
//                             "domain_id": "92b8217d-7870-47c4-8234-251277657ec8",
//                             "domain": "Risk Management",
//                             "subdomain_id": "33f5e305-8d63-435a-991c-76f44b23399a",
//                             "subdomain": "Facility resilience and business continuity",
//                             "mandatory_question": 12,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 75,
//                             "is_active": 1,
//                             "competency": "Business Impact analysis",
//                             "domain_seqeunce": 3,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "356018f2-fbba-4de5-8e6a-10be36b05dc2",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "4dec7eec-96e9-4702-9471-679a0fa6133d",
//                             "question_text": "<p>Assist with long-term planning for facility resilence. <\/p>",
//                             "domain_id": "92b8217d-7870-47c4-8234-251277657ec8",
//                             "domain": "Risk Management",
//                             "subdomain_id": "33f5e305-8d63-435a-991c-76f44b23399a",
//                             "subdomain": "Facility resilience and business continuity",
//                             "mandatory_question": 12,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 52,
//                             "is_active": 1,
//                             "competency": "Facility Resilience",
//                             "domain_seqeunce": 3,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "3fd7acd6-a67f-4327-b8f6-b4152b922e3e",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "decec23e-5df3-4d39-a5e3-66818dd4447f",
//                             "question_text": "<p>Implement long-term planning for facility resilence.<\/p>",
//                             "domain_id": "92b8217d-7870-47c4-8234-251277657ec8",
//                             "domain": "Risk Management",
//                             "subdomain_id": "33f5e305-8d63-435a-991c-76f44b23399a",
//                             "subdomain": "Facility resilience and business continuity",
//                             "mandatory_question": 12,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 46,
//                             "is_active": 1,
//                             "competency": "Facility Resilience",
//                             "domain_seqeunce": 3,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "4e976f44-b3f8-4665-8211-2acf3c7c9656",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "0bae5c6d-fddc-468a-9a6d-1e9d133e60bd",
//                             "question_text": "<p>Assist in the assessment of potential FM vulnerabilities and possible mitigation strategies.<\/p>",
//                             "domain_id": "92b8217d-7870-47c4-8234-251277657ec8",
//                             "domain": "Risk Management",
//                             "subdomain_id": "33f5e305-8d63-435a-991c-76f44b23399a",
//                             "subdomain": "Facility resilience and business continuity",
//                             "mandatory_question": 12,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 65,
//                             "is_active": 1,
//                             "competency": "Business continuity planning",
//                             "domain_seqeunce": 3,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "672695a6-ca71-4501-be35-7f2856f0eb47",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "e1d4fc2f-a501-4daf-b374-2ee6295ffeaa",
//                             "question_text": "<p>Identify critical facility systems, vulnerabilities, and essential functions that impact the reliability of the facility.<\/p>",
//                             "domain_id": "92b8217d-7870-47c4-8234-251277657ec8",
//                             "domain": "Risk Management",
//                             "subdomain_id": "33f5e305-8d63-435a-991c-76f44b23399a",
//                             "subdomain": "Facility resilience and business continuity",
//                             "mandatory_question": 12,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 81,
//                             "is_active": 1,
//                             "competency": "Business Impact analysis",
//                             "domain_seqeunce": 3,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "7e02e14a-f75f-4c35-b8fe-1f70cdf97a8d",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "81ec84d8-261e-40da-b07e-032e55ebabac",
//                             "question_text": "<p>Support implementing risk management strategies to decrease risk and improve reliability of critical facilities.<\/p>",
//                             "domain_id": "92b8217d-7870-47c4-8234-251277657ec8",
//                             "domain": "Risk Management",
//                             "subdomain_id": "33f5e305-8d63-435a-991c-76f44b23399a",
//                             "subdomain": "Facility resilience and business continuity",
//                             "mandatory_question": 12,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 100,
//                             "is_active": 1,
//                             "competency": "Risk management strategies",
//                             "domain_seqeunce": 3,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "96b84034-6b04-4bf6-b52e-b111aa0a7832",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "02579e96-1a53-4a9d-8445-8f2ce3a2b5c6",
//                             "question_text": "<p>Identify mitigation measures to decrease risk and improve the reliability of critical facilities.<\/p>",
//                             "domain_id": "92b8217d-7870-47c4-8234-251277657ec8",
//                             "domain": "Risk Management",
//                             "subdomain_id": "33f5e305-8d63-435a-991c-76f44b23399a",
//                             "subdomain": "Facility resilience and business continuity",
//                             "mandatory_question": 12,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 94,
//                             "is_active": 1,
//                             "competency": "Risk management strategies",
//                             "domain_seqeunce": 3,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "9d31cca7-8e4d-409b-a3ae-3aee6d2b0318",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "b4559a92-3ef6-4ae8-9cea-4ee403189e0b",
//                             "question_text": "<p>Monitor and evaluate the effectiveness of the organization's risk management strategies and business continuity plans.<\/p>",
//                             "domain_id": "92b8217d-7870-47c4-8234-251277657ec8",
//                             "domain": "Risk Management",
//                             "subdomain_id": "33f5e305-8d63-435a-991c-76f44b23399a",
//                             "subdomain": "Facility resilience and business continuity",
//                             "mandatory_question": 12,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 87,
//                             "is_active": 1,
//                             "competency": "Risk management strategies",
//                             "domain_seqeunce": 3,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "b45594c5-f3f6-41a2-80c5-554eebd971ab",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "d608e6ce-8a15-46da-b867-1d4b06d47c8e",
//                             "question_text": "<p>Collaborate with other functional areas in developing business continuity plans.<\/p>",
//                             "domain_id": "92b8217d-7870-47c4-8234-251277657ec8",
//                             "domain": "Risk Management",
//                             "subdomain_id": "33f5e305-8d63-435a-991c-76f44b23399a",
//                             "subdomain": "Facility resilience and business continuity",
//                             "mandatory_question": 12,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 62,
//                             "is_active": 1,
//                             "competency": "Business continuity planning",
//                             "domain_seqeunce": 3,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "cd363e23-6438-418d-baca-2a060280b9c2",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "bd7f5499-b1a7-464c-9422-a136a2d7e95d",
//                             "question_text": "<p>Strategize with stakeholders on how to best implement mitigation measures to improve the reliability of critical facilities.<\/p>",
//                             "domain_id": "92b8217d-7870-47c4-8234-251277657ec8",
//                             "domain": "Risk Management",
//                             "subdomain_id": "33f5e305-8d63-435a-991c-76f44b23399a",
//                             "subdomain": "Facility resilience and business continuity",
//                             "mandatory_question": 12,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 72,
//                             "is_active": 1,
//                             "competency": "Business Impact analysis",
//                             "domain_seqeunce": 3,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "d2af107d-5d7e-4013-a93e-7c03c880bcc6",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "381c9946-5496-44b7-9e8f-16c27b9d14cd",
//                             "question_text": "<p>Ensure long-term planning for facility resilience occurs and that adequate funding is provided to implement resiliency plans.<\/p>",
//                             "domain_id": "92b8217d-7870-47c4-8234-251277657ec8",
//                             "domain": "Risk Management",
//                             "subdomain_id": "33f5e305-8d63-435a-991c-76f44b23399a",
//                             "subdomain": "Facility resilience and business continuity",
//                             "mandatory_question": 12,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 42,
//                             "is_active": 1,
//                             "competency": "Facility Resilience",
//                             "domain_seqeunce": 3,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         }
//                     ],
//                     "Emergency preparedness, response and recovery": [
//                         {
//                             "id": "229d29a8-7d02-46c4-bd2a-c32b0c1f972f",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "afce31c3-51ce-4293-94e3-659745aa91b1",
//                             "question_text": "<p>Evaluate the effectiveness of emergency response training and tabletop exercises.<\/p>",
//                             "domain_id": "92b8217d-7870-47c4-8234-251277657ec8",
//                             "domain": "Risk Management",
//                             "subdomain_id": "64c16135-488a-487a-bcad-9c47661a16da",
//                             "subdomain": "Emergency preparedness, response and recovery",
//                             "mandatory_question": 6,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 185,
//                             "is_active": 1,
//                             "competency": "Emergency preparedness, response and recovery",
//                             "domain_seqeunce": 3,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "3cb243a8-8b0c-4554-ac02-e72b754e22cf",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "2f6099f8-6916-4dd0-808c-5917c72766c0",
//                             "question_text": "<p>Manage the execution of plans and procedures related to disaster or emergency planning, in conjunction with relevant stakeholders.<\/p>",
//                             "domain_id": "92b8217d-7870-47c4-8234-251277657ec8",
//                             "domain": "Risk Management",
//                             "subdomain_id": "64c16135-488a-487a-bcad-9c47661a16da",
//                             "subdomain": "Emergency preparedness, response and recovery",
//                             "mandatory_question": 6,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 176,
//                             "is_active": 1,
//                             "competency": "Emergency preparedness, response and recovery",
//                             "domain_seqeunce": 3,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "7eab7aae-8420-4ba0-869f-b8217458e9c9",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "547ab0c8-e70c-4e3b-b764-8a6d0fa94abb",
//                             "question_text": "<p>Participate in the execution of the plans for safety, security, emergency preparedness, and response training in conjunction with relevant stakeholders.<\/p>",
//                             "domain_id": "92b8217d-7870-47c4-8234-251277657ec8",
//                             "domain": "Risk Management",
//                             "subdomain_id": "64c16135-488a-487a-bcad-9c47661a16da",
//                             "subdomain": "Emergency preparedness, response and recovery",
//                             "mandatory_question": 6,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 182,
//                             "is_active": 1,
//                             "competency": "Emergency preparedness, response and recovery",
//                             "domain_seqeunce": 3,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "a96ce1e5-49e5-42b6-ab5e-2dfb1e09df82",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "1580c6d0-5848-4edc-a41e-716d735ed14e",
//                             "question_text": "<p>Work with stakeholders on emergency response training and tabletop exercises to clarify roles and responsibilities with regards to emergency response plans.<\/p>",
//                             "domain_id": "92b8217d-7870-47c4-8234-251277657ec8",
//                             "domain": "Risk Management",
//                             "subdomain_id": "64c16135-488a-487a-bcad-9c47661a16da",
//                             "subdomain": "Emergency preparedness, response and recovery",
//                             "mandatory_question": 6,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 188,
//                             "is_active": 1,
//                             "competency": "Emergency preparedness, response and recovery",
//                             "domain_seqeunce": 3,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "e8dc79ce-0875-4817-9c7c-679d55d9809c",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "24b9efd2-2052-46c7-a98e-a35a0501171a",
//                             "question_text": "<p>Assist and participate in emergency response training and tabletop exercises.<\/p>",
//                             "domain_id": "92b8217d-7870-47c4-8234-251277657ec8",
//                             "domain": "Risk Management",
//                             "subdomain_id": "64c16135-488a-487a-bcad-9c47661a16da",
//                             "subdomain": "Emergency preparedness, response and recovery",
//                             "mandatory_question": 6,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 191,
//                             "is_active": 1,
//                             "competency": "Emergency preparedness, response and recovery",
//                             "domain_seqeunce": 3,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "fe367825-edfd-4fe0-8e42-3cc66576398a",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "1f5646a2-81ae-4564-8bb5-4e31e3789014",
//                             "question_text": "<p>Lead and confirm the execution of plans and procedures related to disaster or emergency planning in conjunction with relevant stakeholders.<\/p>",
//                             "domain_id": "92b8217d-7870-47c4-8234-251277657ec8",
//                             "domain": "Risk Management",
//                             "subdomain_id": "64c16135-488a-487a-bcad-9c47661a16da",
//                             "subdomain": "Emergency preparedness, response and recovery",
//                             "mandatory_question": 6,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 175,
//                             "is_active": 1,
//                             "competency": "Emergency preparedness, response and recovery",
//                             "domain_seqeunce": 3,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         }
//                     ]
//                 },
//                 "Finance & Business": {
//                     "Operational and Capital Budgeting": [
//                         {
//                             "id": "03bb9ac5-368c-4b88-ae52-d8050a558278",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "e10728b2-406b-4ee2-94c7-55c930f2ae10",
//                             "question_text": "<p>Support collecting data and information related to facililty capital investment analysis and benefits.<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "1a602d75-3e86-4169-8b04-27299371bf78",
//                             "subdomain": "Operational and Capital Budgeting",
//                             "mandatory_question": 22,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 114,
//                             "is_active": 1,
//                             "competency": "Capital Planning and Investment Assessment",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "0d9de69f-6476-4ef3-aab9-24f916d645a8",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "5f7bee6f-dfaa-406f-b1d4-1b3b1887318f",
//                             "question_text": "<p>Provide reports to senior management on budget performance.<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "1a602d75-3e86-4169-8b04-27299371bf78",
//                             "subdomain": "Operational and Capital Budgeting",
//                             "mandatory_question": 22,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 43,
//                             "is_active": 1,
//                             "competency": "Budget administration",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "12b7c6e3-89e6-457b-bfc4-9490b7bd0296",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "f2c5f1b0-95fc-4514-9541-152d3911b956",
//                             "question_text": "<p>Manage facility-related operational expenses for budget compliance; monitor periodic reports, including financials, and explain variances.<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "1a602d75-3e86-4169-8b04-27299371bf78",
//                             "subdomain": "Operational and Capital Budgeting",
//                             "mandatory_question": 22,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 73,
//                             "is_active": 1,
//                             "competency": "Budget administration",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "186163ad-4972-4e3c-8778-a779881610ec",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "28309587-b51c-40ee-b4d4-f1afc5a340a9",
//                             "question_text": "<p>Review and approve maintenance budgets using benchmarks, historical data, and facility occupant utilization feedback (occupancy surveys)<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "1a602d75-3e86-4169-8b04-27299371bf78",
//                             "subdomain": "Operational and Capital Budgeting",
//                             "mandatory_question": 22,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 68,
//                             "is_active": 1,
//                             "competency": "Budget administration",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "2b6ab2e6-2fd3-4f7c-b0a5-bf36f291f6f7",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "57536fa5-32bb-4079-8de4-8018cbc40147",
//                             "question_text": "<p>Assist with processing orders and invoices regarding daily operations and specific projects; confirm the accuracy of cost center coding.<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "1a602d75-3e86-4169-8b04-27299371bf78",
//                             "subdomain": "Operational and Capital Budgeting",
//                             "mandatory_question": 22,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 96,
//                             "is_active": 1,
//                             "competency": "Budget administration",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "3bc51e16-b31f-484b-86cb-8807775b48c7",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "82fd0062-abdf-444b-bed6-a459602a3eee",
//                             "question_text": "<p>Manage the overall chargeback system.<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "1a602d75-3e86-4169-8b04-27299371bf78",
//                             "subdomain": "Operational and Capital Budgeting",
//                             "mandatory_question": 22,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 135,
//                             "is_active": 1,
//                             "competency": "Chargeback",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "47c309a8-f6b2-43c1-8bc4-9feebce82773",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "0d0ebf42-a017-4249-8f62-55c501389e1b",
//                             "question_text": "<p>Prepare budgets on capital expenditures (CAPEX) for facility investments and operational expenditures (OPEX) for facility operations.<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "1a602d75-3e86-4169-8b04-27299371bf78",
//                             "subdomain": "Operational and Capital Budgeting",
//                             "mandatory_question": 22,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 17,
//                             "is_active": 1,
//                             "competency": "Budget administration",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "50eb92a6-f785-4986-9048-7c661fa2b623",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "bf24fda9-0584-45d5-b4b5-45bd02b47c90",
//                             "question_text": "<p>Review and approve budget closeouts and make recommendations on how to resolve any large deviations.<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "1a602d75-3e86-4169-8b04-27299371bf78",
//                             "subdomain": "Operational and Capital Budgeting",
//                             "mandatory_question": 22,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 56,
//                             "is_active": 1,
//                             "competency": "Budget administration",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "726ddf66-1693-4dc2-843e-de24221dc8b8",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "00941620-8f1c-4365-a6ab-4b42867a1cf5",
//                             "question_text": "<p>Monitor the overall maintenance budget (e.g. maintenance and repair cost, equipment availability, and spare parts inventory)<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "1a602d75-3e86-4169-8b04-27299371bf78",
//                             "subdomain": "Operational and Capital Budgeting",
//                             "mandatory_question": 22,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 78,
//                             "is_active": 1,
//                             "competency": "Budget administration",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "7d348011-0d99-48e2-ae74-dad4edaaef61",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "963690a5-f2f0-4d65-bcf2-33b55014dbae",
//                             "question_text": "<p>Accumulate and prepare lists of proposed capital expenditures.<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "1a602d75-3e86-4169-8b04-27299371bf78",
//                             "subdomain": "Operational and Capital Budgeting",
//                             "mandatory_question": 22,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 112,
//                             "is_active": 1,
//                             "competency": "Capital Planning and Investment Assessment",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "7ff6a9bf-cd31-4578-a25b-a621b8226900",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "d3f5af9a-a41e-43b2-97f7-2d33ffe558f2",
//                             "question_text": "<p>Analyze, forecast, and report on CAPEX and OPEX according to approved budget.<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "1a602d75-3e86-4169-8b04-27299371bf78",
//                             "subdomain": "Operational and Capital Budgeting",
//                             "mandatory_question": 22,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 49,
//                             "is_active": 1,
//                             "competency": "Budget administration",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "9fb999a7-2edf-46d0-b85e-8d4eba717a0e",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "59614602-41fc-4d5c-bbe4-c7a71c050789",
//                             "question_text": "<p>Oversee and advocate for capital appropriations that support the organization's goals.<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "1a602d75-3e86-4169-8b04-27299371bf78",
//                             "subdomain": "Operational and Capital Budgeting",
//                             "mandatory_question": 22,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 104,
//                             "is_active": 1,
//                             "competency": "Capital Planning and Investment Assessment",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "a78d93df-b87b-499d-a356-8d52749b1b37",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "9b36d3a4-6174-4642-bab5-c55cca20fc73",
//                             "question_text": "<p>Conduct the formal budget closeout process.<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "1a602d75-3e86-4169-8b04-27299371bf78",
//                             "subdomain": "Operational and Capital Budgeting",
//                             "mandatory_question": 22,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 59,
//                             "is_active": 1,
//                             "competency": "Budget administration",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "ac0b01f8-a0d8-4a04-9195-23bbf2dae7ee",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "b8dffccf-787c-41bc-82f9-4d8d177c690e",
//                             "question_text": "<p>Prepare Capital Appropriations Requests (CAR) for needed infrastructure related to capital projects.<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "1a602d75-3e86-4169-8b04-27299371bf78",
//                             "subdomain": "Operational and Capital Budgeting",
//                             "mandatory_question": 22,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 108,
//                             "is_active": 1,
//                             "competency": "Capital Planning and Investment Assessment",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "acf71aea-cab5-4a28-bf5f-972b3cd0d121",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "d70eb8ee-0929-4580-a53a-8ac4f8fb11fd",
//                             "question_text": "<p>Coordinate and prepare documentation for budget closeouts.<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "1a602d75-3e86-4169-8b04-27299371bf78",
//                             "subdomain": "Operational and Capital Budgeting",
//                             "mandatory_question": 22,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 64,
//                             "is_active": 1,
//                             "competency": "Budget administration",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "ade18d83-b183-420e-98c4-a651e9a55472",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "c4dd9e8d-3c91-4413-90dc-29d51f8bfe64",
//                             "question_text": "<p>Accurately use chargebacks to allocate facility costs.<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "1a602d75-3e86-4169-8b04-27299371bf78",
//                             "subdomain": "Operational and Capital Budgeting",
//                             "mandatory_question": 22,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 138,
//                             "is_active": 1,
//                             "competency": "Chargeback",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "bbad513f-f5b2-4be1-91f2-94a23d0e6a4f",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "78bc08d1-5fae-4c50-a94c-c4bd770629dd",
//                             "question_text": "<p>Review  and report on large deviations from the approved spending plan and adjust budgets as appropriate<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "1a602d75-3e86-4169-8b04-27299371bf78",
//                             "subdomain": "Operational and Capital Budgeting",
//                             "mandatory_question": 22,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 86,
//                             "is_active": 1,
//                             "competency": "Budget administration",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "c73a0590-919a-44ec-875f-5b8a704b3f6c",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "a7ddfe99-9047-4d47-888c-7c8be81bd108",
//                             "question_text": "<p>Review periodic purchasing summaries so they conform to approved budgets.<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "1a602d75-3e86-4169-8b04-27299371bf78",
//                             "subdomain": "Operational and Capital Budgeting",
//                             "mandatory_question": 22,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 91,
//                             "is_active": 1,
//                             "competency": "Budget administration",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "c9094178-904f-46ee-93d7-4c94a246ac8f",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "8c77904c-096a-49b5-bc6e-be3380e29d41",
//                             "question_text": "<p>Collect data and support the preparation of capital and operations budgets.<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "1a602d75-3e86-4169-8b04-27299371bf78",
//                             "subdomain": "Operational and Capital Budgeting",
//                             "mandatory_question": 22,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 39,
//                             "is_active": 1,
//                             "competency": "Budget administration",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "c95e364c-3dcc-46d7-ad43-85e5f25d4a25",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "981cfb13-c471-4128-b378-7f5388644985",
//                             "question_text": "<p>Prepare life cycle budgets for a) capital expenditures (CAPEX) for facility investments and b) operational expenditures (OPEX) for facility operations.<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "1a602d75-3e86-4169-8b04-27299371bf78",
//                             "subdomain": "Operational and Capital Budgeting",
//                             "mandatory_question": 22,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 15,
//                             "is_active": 1,
//                             "competency": "Budget administration",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "e334a23b-3907-4f56-96ca-c2acba465f26",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "b3a5ffa5-82c5-4c24-bc9e-edadbadf0340",
//                             "question_text": "<p>Identify opportunities for facility capital investments and support the development of business cases.<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "1a602d75-3e86-4169-8b04-27299371bf78",
//                             "subdomain": "Operational and Capital Budgeting",
//                             "mandatory_question": 22,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 119,
//                             "is_active": 1,
//                             "competency": "Capital Planning and Investment Assessment",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "e65c25b0-b5a8-4756-9e10-0f0cc0556975",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "c87b1a6c-44ab-41ac-bef5-f8b60406058c",
//                             "question_text": "<p>Monitor the status of chargebacks and inform customers of any significant deviations from projected expenditures.<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "1a602d75-3e86-4169-8b04-27299371bf78",
//                             "subdomain": "Operational and Capital Budgeting",
//                             "mandatory_question": 22,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 127,
//                             "is_active": 1,
//                             "competency": "Chargeback",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         }
//                     ],
//                     "Evidence-based Decision Making": [
//                         {
//                             "id": "0a944138-af0f-4d11-9bb1-dfaf7dd2339a",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "44f6c755-14a7-4d89-b45e-10fbc712bce9",
//                             "question_text": "<p>Track and monitor cost savings and avoidance initiatives.<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "24bfd1bb-9f01-4ef2-b049-11c9dbbbf663",
//                             "subdomain": "Evidence-based Decision Making",
//                             "mandatory_question": 3,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 154,
//                             "is_active": 1,
//                             "competency": "Cost Savings and Cost Avoidance",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "5e365b82-65f9-454d-9fe9-00006140e269",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "c8c34c06-f7a9-4f33-a967-559a40c9ace2",
//                             "question_text": "<p>Recommend the reallocation of funds based on cost saving and avoidance strategies.<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "24bfd1bb-9f01-4ef2-b049-11c9dbbbf663",
//                             "subdomain": "Evidence-based Decision Making",
//                             "mandatory_question": 3,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 144,
//                             "is_active": 1,
//                             "competency": "Cost Savings and Cost Avoidance",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "94bbe5f5-974c-4e98-8653-b5fc802742a6",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "d14920c4-0c51-4976-abcd-a545af92b77c",
//                             "question_text": "<p>Identify cost savings and cost avoidance strategies and opportunities that are aligned with organizational objectives.<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "24bfd1bb-9f01-4ef2-b049-11c9dbbbf663",
//                             "subdomain": "Evidence-based Decision Making",
//                             "mandatory_question": 3,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 150,
//                             "is_active": 1,
//                             "competency": "Cost Savings and Cost Avoidance",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         }
//                     ],
//                     "Procurement and Contracting Strategies": [
//                         {
//                             "id": "0da202b0-807e-4d0a-b604-9a43131eb553",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "7b68a3b2-e9fb-4c21-95b7-725a2b7bcfcb",
//                             "question_text": "<p>Establish criteria for adding or removing contractors\/providers from lists of potential supply sources.<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "19c02028-abf9-4968-bf88-63fdd6cc5a88",
//                             "subdomain": "Procurement and Contracting Strategies",
//                             "mandatory_question": 18,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 205,
//                             "is_active": 1,
//                             "competency": "Supplier management",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "1d4e2b5c-4272-4758-8bd8-7921a8aa240d",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "53b92449-7bd1-4cd0-99ef-2d0f212dbd26",
//                             "question_text": "<p>Facilitate utilization of contract templates and necessary legal review. Advise on updates to contract language.<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "19c02028-abf9-4968-bf88-63fdd6cc5a88",
//                             "subdomain": "Procurement and Contracting Strategies",
//                             "mandatory_question": 18,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 200,
//                             "is_active": 1,
//                             "competency": "Contracting Strategy",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "27f179f1-f5a1-4757-88c4-9cc83cc97b44",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "62b338e2-7080-4fd0-bacb-5da30d561e78",
//                             "question_text": "<p>Ensure vendor understanding and application of scope and expectations within contracts.<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "19c02028-abf9-4968-bf88-63fdd6cc5a88",
//                             "subdomain": "Procurement and Contracting Strategies",
//                             "mandatory_question": 18,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 201,
//                             "is_active": 1,
//                             "competency": "Contracting Strategy",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "31c9e74c-cbc4-4a1a-93f6-9504b68b4c7f",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "a87c9531-2cf1-4d64-88c5-f486c93f5a6b",
//                             "question_text": "<p>Analyze supplier relationships for cost effectiveness and quality; authorize invoices.<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "19c02028-abf9-4968-bf88-63fdd6cc5a88",
//                             "subdomain": "Procurement and Contracting Strategies",
//                             "mandatory_question": 18,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 203,
//                             "is_active": 1,
//                             "competency": "Supplier management",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "3785ed76-25cd-4697-bc21-4b3c1593a236",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "00c32c4d-c5ba-4c04-8039-0f40702cc447",
//                             "question_text": "<p>Develop or acquire technical and commercial metrics to evaluate submissions from suppliers.<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "19c02028-abf9-4968-bf88-63fdd6cc5a88",
//                             "subdomain": "Procurement and Contracting Strategies",
//                             "mandatory_question": 18,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 193,
//                             "is_active": 1,
//                             "competency": "Procurement Strategy",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "504bd859-50d6-4829-afe8-135d3acb70a4",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "da028bbf-7d35-418a-a4af-e5d65d97e334",
//                             "question_text": "<p>Establish systems to evaluate supplier cost effectiveness and quality.<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "19c02028-abf9-4968-bf88-63fdd6cc5a88",
//                             "subdomain": "Procurement and Contracting Strategies",
//                             "mandatory_question": 18,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 202,
//                             "is_active": 1,
//                             "competency": "Supplier management",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "5467f5da-4bba-47fa-ad8a-6b4ef498effb",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "f238aac7-e788-4c8c-92be-3e8dcde7c832",
//                             "question_text": "<p>Assist with administering and preparing scope of work, specifications, and purchasing requisitions related to the procurement of FM products, services, and professional engagements.<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "19c02028-abf9-4968-bf88-63fdd6cc5a88",
//                             "subdomain": "Procurement and Contracting Strategies",
//                             "mandatory_question": 18,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 192,
//                             "is_active": 1,
//                             "competency": "Procurement Strategy",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "627ac056-9c1e-466d-b742-f269559e768b",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "988f72ea-38a5-44aa-96b0-bf3b14dc2560",
//                             "question_text": "<p>Establish contract templates acceptable to demand organization and update as required.<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "19c02028-abf9-4968-bf88-63fdd6cc5a88",
//                             "subdomain": "Procurement and Contracting Strategies",
//                             "mandatory_question": 18,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 199,
//                             "is_active": 1,
//                             "competency": "Contracting Strategy",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "6921b4de-3d38-4de4-b9e3-8b4ac3b9742a",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "0bca6826-6359-409c-bf1e-29013a964e7c",
//                             "question_text": "<p>Review and monitor the purchasing process, and recommend and advocate for improvements where appropriate.<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "19c02028-abf9-4968-bf88-63fdd6cc5a88",
//                             "subdomain": "Procurement and Contracting Strategies",
//                             "mandatory_question": 18,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 196,
//                             "is_active": 1,
//                             "competency": "Procurement Strategy",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "6adebe22-251d-4340-b1ba-4c7610d02c28",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "32b2c03a-97b6-404e-9e17-a4e06e0a2a74",
//                             "question_text": "<p>Guide developing scopes of work, specifications, and purchasing requisitions for the procurement of FM products, services, and professional engagements so they are aligned with the organization's goals and funding availability.<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "19c02028-abf9-4968-bf88-63fdd6cc5a88",
//                             "subdomain": "Procurement and Contracting Strategies",
//                             "mandatory_question": 18,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 159,
//                             "is_active": 1,
//                             "competency": "Procurement Strategy",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "7e9cb748-f3be-4a49-bce9-e0072ed3a3cc",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "69c63c33-8976-4552-93f0-3908a055bfdd",
//                             "question_text": "<p>Accumulate and record supplier quality metrics.<\/p><p>Assist with Procurement and Supply Chain Administration teams to source and qualify suppliers, source, and procure goods and services.<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "19c02028-abf9-4968-bf88-63fdd6cc5a88",
//                             "subdomain": "Procurement and Contracting Strategies",
//                             "mandatory_question": 18,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 204,
//                             "is_active": 1,
//                             "competency": "Supplier management",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "9620aaeb-87b5-44e2-beaa-bd743f90754f",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "a7770505-d5ac-434b-9cb4-d5402d9d47d5",
//                             "question_text": "<p>Manage lists of approved consultants, contractors and suppliers consistent withthe established criteria.<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "19c02028-abf9-4968-bf88-63fdd6cc5a88",
//                             "subdomain": "Procurement and Contracting Strategies",
//                             "mandatory_question": 18,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 207,
//                             "is_active": 1,
//                             "competency": "Supplier management",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "a8b57201-0c26-49b8-8558-8f4837e3da5a",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "c93b00bb-91c9-4198-a052-88c6e1d1339a",
//                             "question_text": "<p>Define and prepare scope of work, specifications, and purchasing requisitions related to the procurement of FM products, services, and professional engagements.<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "19c02028-abf9-4968-bf88-63fdd6cc5a88",
//                             "subdomain": "Procurement and Contracting Strategies",
//                             "mandatory_question": 18,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 160,
//                             "is_active": 1,
//                             "competency": "Procurement Strategy",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "bd896946-d871-40eb-af87-e71b40085e4b",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "cd8d773a-34ad-443f-a07d-51bdd6819b08",
//                             "question_text": "<p>Receive and record incoming purchase requisitions and track their progress through the system.<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "19c02028-abf9-4968-bf88-63fdd6cc5a88",
//                             "subdomain": "Procurement and Contracting Strategies",
//                             "mandatory_question": 18,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 198,
//                             "is_active": 1,
//                             "competency": "Procurement Strategy",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "d2c6b100-4ae4-4afd-ab45-98a753c9591a",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "5657581f-e14a-4fc6-963d-cb7ddb029511",
//                             "question_text": "<p>Review quotations and bids for compliance with solicitations and tender requirements.<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "19c02028-abf9-4968-bf88-63fdd6cc5a88",
//                             "subdomain": "Procurement and Contracting Strategies",
//                             "mandatory_question": 18,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 195,
//                             "is_active": 1,
//                             "competency": "Procurement Strategy",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "dd027f49-f662-4d67-9f56-8c3e132e5cb8",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "0a2d7a4b-d788-4e78-a594-0f2d9e276a8e",
//                             "question_text": "<p>Maintain lists of contractors who have or are capable of performing needed facility services.<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "19c02028-abf9-4968-bf88-63fdd6cc5a88",
//                             "subdomain": "Procurement and Contracting Strategies",
//                             "mandatory_question": 18,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 209,
//                             "is_active": 1,
//                             "competency": "Supplier management",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "e53bb0b4-1591-4a35-bf49-ce289615120f",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "b15aa535-d8df-4150-bd84-2493b8997ce7",
//                             "question_text": "<p>Use technical and commercial metrics to evaluate and select suppliers.<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "19c02028-abf9-4968-bf88-63fdd6cc5a88",
//                             "subdomain": "Procurement and Contracting Strategies",
//                             "mandatory_question": 18,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 194,
//                             "is_active": 1,
//                             "competency": "Procurement Strategy",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "fe7a78db-3d87-4fec-838e-8a5ac2b85955",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "890e313d-d37b-4b98-858c-2b6210e790d9",
//                             "question_text": "<p>Process and authorize purchase requisitions and written and verbal quotations to determine they comply with the requirements for competitive formal bidding; review and negotiate contractor\/supplier proposals and contracts in accordance with procurement laws, requirements, and organizational policies.<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "19c02028-abf9-4968-bf88-63fdd6cc5a88",
//                             "subdomain": "Procurement and Contracting Strategies",
//                             "mandatory_question": 18,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 197,
//                             "is_active": 1,
//                             "competency": "Procurement Strategy",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         }
//                     ],
//                     "Financial Analysis and Reporting": [
//                         {
//                             "id": "776953af-3555-4e95-a29f-05ea2a61574c",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "06959458-ffb0-4f38-9919-1b290000a6d4",
//                             "question_text": "<p> Establish criteria for deciding what financial data to collect and monitor for tracking both FM's financial key performance indicators and the demand organization's financial key performance indicators.  <\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "281db73a-f8b3-4652-adc1-a12014ba5810",
//                             "subdomain": "Financial Analysis and Reporting",
//                             "mandatory_question": 3,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 210,
//                             "is_active": 1,
//                             "competency": "Financial Analysis",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "8aa649f8-2bb0-41c5-bdda-1e082e911294",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "dd765daa-7b7f-40bc-b8ed-0c057faf8f26",
//                             "question_text": "<p>Prepare reports containing an analyses of facility performance data to confirm there is an alignment with the organization's goals and objectives, identify potential areas for improvement, and make recommendations to senior management.<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "281db73a-f8b3-4652-adc1-a12014ba5810",
//                             "subdomain": "Financial Analysis and Reporting",
//                             "mandatory_question": 3,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 215,
//                             "is_active": 1,
//                             "competency": "Financial Analysis",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "e5e87913-dc5b-414d-a922-02beafa8d145",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "6eb93456-93e1-4f43-99b7-6d98170812ff",
//                             "question_text": "<p>Establish criteria for deciding what financial data to collect and monitor for tracking both FM's financial key performance indicators and the demand organization's financial key performance indicators.<\/p>",
//                             "domain_id": "02b3ea5e-2318-4345-a9c5-d9468a32c170",
//                             "domain": "Finance & Business",
//                             "subdomain_id": "281db73a-f8b3-4652-adc1-a12014ba5810",
//                             "subdomain": "Financial Analysis and Reporting",
//                             "mandatory_question": 3,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 212,
//                             "is_active": 1,
//                             "competency": "Financial Analysis",
//                             "domain_seqeunce": 4,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         }
//                     ]
//                 },
//                 "Sustainability": {
//                     "Energy": [
//                         {
//                             "id": "0a32aa67-53a7-40c4-881b-4b970e8ba018",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "c1fcc714-73d0-4aaa-b4c4-d1f65bcf5b31",
//                             "question_text": "<p>Identify and analyze alternative financing options for energy projects.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "ac1c8c2b-0de4-4ffa-b5f1-7f28505ee8c3",
//                             "subdomain": "Energy",
//                             "mandatory_question": 36,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 439,
//                             "is_active": 1,
//                             "competency": "Alternative Financing Options for Energy Projects",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "12b72e31-2651-4cb3-9d7e-b46d68f929fc",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "c35a1c7f-53ca-4e8f-a25c-e8445505fa45",
//                             "question_text": "<p>Monitor the impact of building systems on energy use.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "ac1c8c2b-0de4-4ffa-b5f1-7f28505ee8c3",
//                             "subdomain": "Energy",
//                             "mandatory_question": 36,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 369,
//                             "is_active": 1,
//                             "competency": "Building Systems and Energy Use",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "12de4eeb-9280-452f-bbe9-0c79d4484f06",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "23e8caec-c6ad-4427-a9f8-d6cff2c4c96b",
//                             "question_text": "<p>Work with finance to compare and evaluate alternative financing options for energy projects.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "ac1c8c2b-0de4-4ffa-b5f1-7f28505ee8c3",
//                             "subdomain": "Energy",
//                             "mandatory_question": 36,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 436,
//                             "is_active": 1,
//                             "competency": "Alternative Financing Options for Energy Projects",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "19b8ad7d-c25a-4fd1-ac76-5f81db1b668d",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "e17521b8-0c80-47ed-9a31-6fb75c613a96",
//                             "question_text": "<p>Plan energy savings measures based on investment grade audit or stakeholder-completed energy savings assessments. Evaluate ROI, savings, and the value of these energy initiatives.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "ac1c8c2b-0de4-4ffa-b5f1-7f28505ee8c3",
//                             "subdomain": "Energy",
//                             "mandatory_question": 36,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 424,
//                             "is_active": 1,
//                             "competency": "Energy Savings Assessment",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "20e9c6a3-dc0a-4afd-a6c8-84f4a54512f6",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "76ae8032-0fb6-4280-9ede-9b911a2bef26",
//                             "question_text": "<p>Monitor, manage, report, and reduce energy consumption.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "ac1c8c2b-0de4-4ffa-b5f1-7f28505ee8c3",
//                             "subdomain": "Energy",
//                             "mandatory_question": 36,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 326,
//                             "is_active": 1,
//                             "competency": "Energy management",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "24474580-bf6f-4425-ab21-4428afb5f359",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "0f5fb236-5149-4542-9064-76907e6854dc",
//                             "question_text": "<p>Identify the impact of building systems on energy use.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "ac1c8c2b-0de4-4ffa-b5f1-7f28505ee8c3",
//                             "subdomain": "Energy",
//                             "mandatory_question": 36,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 374,
//                             "is_active": 1,
//                             "competency": "Building Systems and Energy Use",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "270a540e-06c4-4d4b-ad5c-fe64bb1e23a8",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "355fa5cb-cbac-48b8-ad07-0e72919501e2",
//                             "question_text": "<p>Collect data on energy consumption.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "ac1c8c2b-0de4-4ffa-b5f1-7f28505ee8c3",
//                             "subdomain": "Energy",
//                             "mandatory_question": 36,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 332,
//                             "is_active": 1,
//                             "competency": "Energy management",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "2834b48d-5ee1-4519-9d94-ca3bf28840fa",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "30cbb34e-1fce-495f-a744-d54165b526c6",
//                             "question_text": "<p>Identify potential projects and rebate programs that aim to improve energy efficiency, generate renewable energy or address energy related challenge including ROI for capital improvements supporting ECMs.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "ac1c8c2b-0de4-4ffa-b5f1-7f28505ee8c3",
//                             "subdomain": "Energy",
//                             "mandatory_question": 36,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 412,
//                             "is_active": 1,
//                             "competency": "Energy Project Identification and Justification Development",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "2c174020-1a33-4ed4-b3bc-a6a18a39ebcd",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "2a3df9e9-a92c-4442-b51a-619a67067802",
//                             "question_text": "<p>Evaluate the impact of building systems on energy use.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "ac1c8c2b-0de4-4ffa-b5f1-7f28505ee8c3",
//                             "subdomain": "Energy",
//                             "mandatory_question": 36,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 335,
//                             "is_active": 1,
//                             "competency": "Building Systems and Energy Use",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "2c2fe742-53a1-4a85-90ee-3408224e4e5e",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "40a3203c-5824-492c-b64a-88eff87dca8c",
//                             "question_text": "<p>Collect and analyze data on energy efficiency measures from investment grade audit or stakeholder-completed energy savings assessments. Analyze how the measures contribute to impacting occupants and optimizing building performance.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "ac1c8c2b-0de4-4ffa-b5f1-7f28505ee8c3",
//                             "subdomain": "Energy",
//                             "mandatory_question": 36,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 427,
//                             "is_active": 1,
//                             "competency": "Energy Savings Assessment",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "31946ff0-6100-4312-abbb-9e3259684044",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "a07646a0-04b4-4ef5-885d-d1b3c1f42374",
//                             "question_text": "<p>Ensure peak load management is integrated into energy management process and investigate ways to reduce peak loads<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "ac1c8c2b-0de4-4ffa-b5f1-7f28505ee8c3",
//                             "subdomain": "Energy",
//                             "mandatory_question": 36,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 390,
//                             "is_active": 1,
//                             "competency": "Peak Load Management",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "36307e49-5818-48fa-ad56-7c7b6da3a38b",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "d5ee9c3d-2735-4ad6-af67-ee0834de36f9",
//                             "question_text": "<p>Evaluate and negotiate utility billing, rate structures, special rate programs, incentives, and discounts.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "ac1c8c2b-0de4-4ffa-b5f1-7f28505ee8c3",
//                             "subdomain": "Energy",
//                             "mandatory_question": 36,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 449,
//                             "is_active": 1,
//                             "competency": "Utility Billing, Rate Structures, and Special Rate Programs and Incentives",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "39789891-6aa7-4db6-9bfe-ebe80c566ef0",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "d44634d4-bc43-4917-8461-7702e49f889d",
//                             "question_text": "<p>Provide assistance with the identification and management of renewable energy systems (e.g., solar, wind, biomass, hydropower).<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "ac1c8c2b-0de4-4ffa-b5f1-7f28505ee8c3",
//                             "subdomain": "Energy",
//                             "mandatory_question": 36,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 409,
//                             "is_active": 1,
//                             "competency": "Renewable Energy Systems",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "45dba1c5-7280-4f14-b424-8f7fb4294131",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "1da78259-0ac1-48bd-8852-47409823a6c8",
//                             "question_text": "<p>Evaluate programs available for implementation of Energy Conservation Measures (ECMs) or energy efficiency measures and applicability to demand organization.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "ac1c8c2b-0de4-4ffa-b5f1-7f28505ee8c3",
//                             "subdomain": "Energy",
//                             "mandatory_question": 36,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 410,
//                             "is_active": 1,
//                             "competency": "Energy Project Identification and Justification Development",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "4aaf49b1-1e07-4a4f-b1b7-5eb41fbe7e66",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "6ea54780-d61c-42ec-8f83-f4cbba13760a",
//                             "question_text": "<p>Demonstrate energy optimization during everyday service delivery through assisting with the building retuning process to ensure maximum energy efficiency and comfort for building occupants<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "ac1c8c2b-0de4-4ffa-b5f1-7f28505ee8c3",
//                             "subdomain": "Energy",
//                             "mandatory_question": 36,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 388,
//                             "is_active": 1,
//                             "competency": "Building retuning",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "4cb36356-446d-4be6-a06e-172522edf9d5",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "0e7dcbea-e0fd-4dc8-bd60-1703c881b165",
//                             "question_text": "<p>Conduct building retuning process and data collection to safeguard maximum energy efficiency and comfort for building occupants.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "ac1c8c2b-0de4-4ffa-b5f1-7f28505ee8c3",
//                             "subdomain": "Energy",
//                             "mandatory_question": 36,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 385,
//                             "is_active": 1,
//                             "competency": "Building retuning",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "4e20c540-a7be-409b-a899-4bd5a51b2706",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "2c86e1c5-a08a-4a84-a61e-a2e58f1b24a8",
//                             "question_text": "<p>Implement utility billing, rate structures, special rate programs, incentives, and discounts.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "ac1c8c2b-0de4-4ffa-b5f1-7f28505ee8c3",
//                             "subdomain": "Energy",
//                             "mandatory_question": 36,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 453,
//                             "is_active": 1,
//                             "competency": "Utility Billing, Rate Structures, and Special Rate Programs and Incentives",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "56fa2d5e-6142-48eb-87f7-026172bf2392",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "a9e7f917-48a3-4573-bc60-753400ead087",
//                             "question_text": "<p>Manage energy efficiency projects. Communicate implementation logistics and benefits to all stakeholders.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "ac1c8c2b-0de4-4ffa-b5f1-7f28505ee8c3",
//                             "subdomain": "Energy",
//                             "mandatory_question": 36,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 416,
//                             "is_active": 1,
//                             "competency": "Facility Energy Projects Monitoring",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "65acd4a5-a315-4960-bb78-8177dce71e91",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "576b97f5-4a07-46af-9190-d272c6ba8343",
//                             "question_text": "<p>Evaluate and recommend all types of commissioning (e.g., new construction commissioning, retrocommissioning, recommissioning, ongoing commissioning).<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "ac1c8c2b-0de4-4ffa-b5f1-7f28505ee8c3",
//                             "subdomain": "Energy",
//                             "mandatory_question": 36,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 376,
//                             "is_active": 1,
//                             "competency": "Commissioning, Retro-Commissioning, and Continuous Commissioning",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "72ace2b9-0cdb-43b7-8026-10e692ce60f4",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "0c39589e-ebf4-449b-a0c7-9aa792a4028f",
//                             "question_text": "<p>Set energy reduction target and report the progress in achieving the target.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "ac1c8c2b-0de4-4ffa-b5f1-7f28505ee8c3",
//                             "subdomain": "Energy",
//                             "mandatory_question": 36,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 319,
//                             "is_active": 1,
//                             "competency": "Energy management",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "8df8636b-c471-4235-a8cb-2b0b1d362056",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "3afdf5df-546e-4da8-bc6a-c1ff3ba0b548",
//                             "question_text": "<p>Provide assistance with energy savings assessment or investment grade audit.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "ac1c8c2b-0de4-4ffa-b5f1-7f28505ee8c3",
//                             "subdomain": "Energy",
//                             "mandatory_question": 36,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 430,
//                             "is_active": 1,
//                             "competency": "Energy Savings Assessment",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "96b824d8-fa5c-49b3-8f40-b8274dac408f",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "df72e408-fe12-4805-9224-6205df1e5728",
//                             "question_text": "<p>Assist with peak load management. <\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "ac1c8c2b-0de4-4ffa-b5f1-7f28505ee8c3",
//                             "subdomain": "Energy",
//                             "mandatory_question": 36,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 400,
//                             "is_active": 1,
//                             "competency": "Peak Load Management",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "971d1a70-fbbe-4b7b-9dfe-f3fad0c1e0b8",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "6f6c1bbe-3886-4e16-98a0-38458f0b1838",
//                             "question_text": "<p>Classify utility billing, rate structures, special rate programs, incentives, and discounts.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "ac1c8c2b-0de4-4ffa-b5f1-7f28505ee8c3",
//                             "subdomain": "Energy",
//                             "mandatory_question": 36,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 456,
//                             "is_active": 1,
//                             "competency": "Utility Billing, Rate Structures, and Special Rate Programs and Incentives",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "9e41e7c1-1726-4c51-868d-2d1d14675cfa",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "68a4eb4d-b0c1-4636-b27e-135a89eb919b",
//                             "question_text": "<p>Assist with data collection and system condition evaluation for potential energy projects and energy efficiency practices update (schedules).<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "ac1c8c2b-0de4-4ffa-b5f1-7f28505ee8c3",
//                             "subdomain": "Energy",
//                             "mandatory_question": 36,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 413,
//                             "is_active": 1,
//                             "competency": "Energy Project Identification and Justification Development",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "9f57086c-4930-477f-b9bf-d0671c094675",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "1673c1cd-042d-4390-8b18-a6eecfc5e433",
//                             "question_text": "<p>Manage all types of commissioning (e.g., new construction commissioning, retrocommissioning, recommissioning, ongoing commissioning).<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "ac1c8c2b-0de4-4ffa-b5f1-7f28505ee8c3",
//                             "subdomain": "Energy",
//                             "mandatory_question": 36,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 378,
//                             "is_active": 1,
//                             "competency": "Commissioning, Retro-Commissioning, and Continuous Commissioning",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "a199f0b5-ae54-4c93-991a-2aba7c6e84ea",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "fe2f3ca7-342e-4595-9d69-a2fc36cfd104",
//                             "question_text": "<p>Recommend and implement renewable energy systems (e.g., solar, wind, biomass, hydropower) to meet organizational sustainability goals.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "ac1c8c2b-0de4-4ffa-b5f1-7f28505ee8c3",
//                             "subdomain": "Energy",
//                             "mandatory_question": 36,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 403,
//                             "is_active": 1,
//                             "competency": "Renewable Energy Systems",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "a4a34b5a-1645-492e-9931-89fc0edf3933",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "a0b96447-0522-4470-bd88-802a884d27b1",
//                             "question_text": "<p>Assist with the identification of alternative financing options for energy projects. <\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "ac1c8c2b-0de4-4ffa-b5f1-7f28505ee8c3",
//                             "subdomain": "Energy",
//                             "mandatory_question": 36,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 442,
//                             "is_active": 1,
//                             "competency": "Alternative Financing Options for Energy Projects",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "b84fa1fd-8a14-46ed-b699-ced0e2a659c1",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "7e4723c4-4a5d-4c0d-8edb-25d913e232a9",
//                             "question_text": "<p>Evaluate the benefits and outcomes of energy performance contracts [investment, grade audit (IGA)]. Measure and verify stakeholder feedback. <\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "ac1c8c2b-0de4-4ffa-b5f1-7f28505ee8c3",
//                             "subdomain": "Energy",
//                             "mandatory_question": 36,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 460,
//                             "is_active": 1,
//                             "competency": "Energy performance contracting",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "bf4f81f9-70d6-4474-b96b-7b341dd96b12",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "7e3fb426-9143-47d1-9cff-fbebc2404d7f",
//                             "question_text": "<p>Confirm the integration of building retuning processes into operations and maintenance of systems for maximum energy efficiency and comfort for building occupants.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "ac1c8c2b-0de4-4ffa-b5f1-7f28505ee8c3",
//                             "subdomain": "Energy",
//                             "mandatory_question": 36,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 383,
//                             "is_active": 1,
//                             "competency": "Building retuning",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "c747ad80-56d8-4152-a00e-4794af6ba16a",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "b1f62fe6-8146-4790-98b1-b11c43f35301",
//                             "question_text": "<p>Assist with all types of commissioning (e.g., new construction commissioning, retrocommissioning, recommissioning, ongoing commissioning).<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "ac1c8c2b-0de4-4ffa-b5f1-7f28505ee8c3",
//                             "subdomain": "Energy",
//                             "mandatory_question": 36,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 380,
//                             "is_active": 1,
//                             "competency": "Commissioning, Retro-Commissioning, and Continuous Commissioning",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "c907525c-4cbb-43f3-a647-cc3386ec6c7a",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "f8672378-b23b-46fc-ab89-a5261bd4bc61",
//                             "question_text": "<p>Provide assistance to an energy efficiency program, including identification of energy efficiency measures including modifications to energy program practices (schedules).<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "ac1c8c2b-0de4-4ffa-b5f1-7f28505ee8c3",
//                             "subdomain": "Energy",
//                             "mandatory_question": 36,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 418,
//                             "is_active": 1,
//                             "competency": "Facility Energy Projects Monitoring",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "cc6d6b67-0eaf-4bd4-ae7d-9a55a01b1675",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "63a8fcc2-753a-474e-8669-58cdb0a336ef",
//                             "question_text": "<p>Plan, strategize, implement and manage energy efficiency projects to achieve established performance targets.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "ac1c8c2b-0de4-4ffa-b5f1-7f28505ee8c3",
//                             "subdomain": "Energy",
//                             "mandatory_question": 36,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 414,
//                             "is_active": 1,
//                             "competency": "Facility Energy Projects Monitoring",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "cdc9da7e-6251-412c-b2b8-b20af36e3f2d",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "19f53734-e281-423d-98b0-b6514810714b",
//                             "question_text": "<p>Assist with the development of energy performance contracts [energy services company (ESCo)].<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "ac1c8c2b-0de4-4ffa-b5f1-7f28505ee8c3",
//                             "subdomain": "Energy",
//                             "mandatory_question": 36,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 466,
//                             "is_active": 1,
//                             "competency": "Energy performance contracting",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "f597b922-e639-4c53-87bd-15d1b547ef7d",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "544af471-a71d-486c-ad8f-d77bf8d78610",
//                             "question_text": "<p>Analyze renewable energy systems options and manage those systems (e.g., solar, wind, biomass, hydropower) to meet organizational sustainability goals.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "ac1c8c2b-0de4-4ffa-b5f1-7f28505ee8c3",
//                             "subdomain": "Energy",
//                             "mandatory_question": 36,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 406,
//                             "is_active": 1,
//                             "competency": "Renewable Energy Systems",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "f60f3294-4b5b-4c98-862f-e43ceab2796f",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "fe5e25b6-c4b9-405b-9cb8-599cfa1d4b23",
//                             "question_text": "<p>Manage peak load and monitor peaks.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "ac1c8c2b-0de4-4ffa-b5f1-7f28505ee8c3",
//                             "subdomain": "Energy",
//                             "mandatory_question": 36,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 395,
//                             "is_active": 1,
//                             "competency": "Peak Load Management",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "fc19c5b6-28be-486a-a862-7529bbe45f79",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "aa49a569-a73f-46c3-a365-0efc5f895d3c",
//                             "question_text": "<p>Implement and manage energy performance contracts [investment, grade audit (IGA)].<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "ac1c8c2b-0de4-4ffa-b5f1-7f28505ee8c3",
//                             "subdomain": "Energy",
//                             "mandatory_question": 36,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 465,
//                             "is_active": 1,
//                             "competency": "Energy performance contracting",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         }
//                     ],
//                     "Water": [
//                         {
//                             "id": "0c52ca83-374c-4dcb-a202-a3d58726e503",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "dd86a733-fc88-4dee-9839-40d4968e9d7c",
//                             "question_text": "<p>Support and promote facility water conservation awareness programs.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "50f157d0-944a-49ab-ba08-39ec1f133405",
//                             "subdomain": "Water",
//                             "mandatory_question": 12,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 477,
//                             "is_active": 1,
//                             "competency": "Water Efficiency Principles",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "1293132f-3798-4718-8c62-deee2692a388",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "b1dddeb4-2cab-46b8-8401-aee1769d5177",
//                             "question_text": "<p>Conduct a water audit that includes systemwide use, fixtures, and equipment. <\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "50f157d0-944a-49ab-ba08-39ec1f133405",
//                             "subdomain": "Water",
//                             "mandatory_question": 12,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 472,
//                             "is_active": 1,
//                             "competency": "Water Efficiency Principles",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "49704493-b3e6-41f9-ad2b-40737223fdb6",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "79111019-098b-4c16-bc8e-0a1accdc6063",
//                             "question_text": "<p>Provide assistance with a water audit.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "50f157d0-944a-49ab-ba08-39ec1f133405",
//                             "subdomain": "Water",
//                             "mandatory_question": 12,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 474,
//                             "is_active": 1,
//                             "competency": "Water Efficiency Principles",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "51c0eee6-330d-409a-b97d-0c69a0aeb245",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "63ace62e-b791-47de-a629-9b586d3897ba",
//                             "question_text": "<p>Assess most effective water efficiency measures using lifecycle analysis. <\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "50f157d0-944a-49ab-ba08-39ec1f133405",
//                             "subdomain": "Water",
//                             "mandatory_question": 12,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 496,
//                             "is_active": 1,
//                             "competency": "Water management",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "51fa44eb-ca04-4181-8590-a55fb9eb654a",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "815c71dd-80db-4ec6-92fb-8adb0c50a009",
//                             "question_text": "<p>Manage a water audit that includes systemwide use, fixtures, and equipment.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "50f157d0-944a-49ab-ba08-39ec1f133405",
//                             "subdomain": "Water",
//                             "mandatory_question": 12,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 470,
//                             "is_active": 1,
//                             "competency": "Water Efficiency Principles",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "9dbc67b6-0db1-4047-8d97-eba1626edf11",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "bc4683f3-fb7f-4556-ad1c-58920bed7ead",
//                             "question_text": "<p>Manage a water audit that includes systemwide use, fixtures, and equipment.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "50f157d0-944a-49ab-ba08-39ec1f133405",
//                             "subdomain": "Water",
//                             "mandatory_question": 12,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 505,
//                             "is_active": 1,
//                             "competency": "Water Audits",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "abbcf4e0-4d75-490d-93b8-5b5b31fecd78",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "5f914f97-223d-4cad-8b58-8ffb4da7bf62",
//                             "question_text": "<p>Implement and monitor facility water conservation awareness program.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "50f157d0-944a-49ab-ba08-39ec1f133405",
//                             "subdomain": "Water",
//                             "mandatory_question": 12,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 479,
//                             "is_active": 1,
//                             "competency": "Water Efficiency Principles",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "bc179d62-5a13-4dd5-b95a-fdf23cb4f23c",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "18952f55-2346-4afb-8026-d0939ea0e612",
//                             "question_text": "<p>Evaluate water efficiency measures for their contribution to impacting occupants and optimizing building performance<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "50f157d0-944a-49ab-ba08-39ec1f133405",
//                             "subdomain": "Water",
//                             "mandatory_question": 12,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 493,
//                             "is_active": 1,
//                             "competency": "Water management",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "ca56623c-1857-49cc-8034-965405d3d2a5",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "9cef1c26-758f-4778-9cec-8af01ee46e49",
//                             "question_text": "<p>Utilize a facility water conservation awareness program.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "50f157d0-944a-49ab-ba08-39ec1f133405",
//                             "subdomain": "Water",
//                             "mandatory_question": 12,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 481,
//                             "is_active": 1,
//                             "competency": "Water Efficiency Principles",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "d1123dee-4257-49c9-9648-8f2d4368962a",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "8c33d6d8-d150-4863-ac16-3b6ae3aa604c",
//                             "question_text": "<p>Identify the impact of building systems on water consumption.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "50f157d0-944a-49ab-ba08-39ec1f133405",
//                             "subdomain": "Water",
//                             "mandatory_question": 12,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 501,
//                             "is_active": 1,
//                             "competency": "Water management",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "d9bcaef0-bb0f-4bcd-ba79-2bca20768957",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "a2ca1fa2-fcb4-42f7-804b-28269e223b99",
//                             "question_text": "<p>Conduct a water audit that includes systemwide use, fixtures, and equipment. <\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "50f157d0-944a-49ab-ba08-39ec1f133405",
//                             "subdomain": "Water",
//                             "mandatory_question": 12,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 507,
//                             "is_active": 1,
//                             "competency": "Water Audits",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "e6489823-255f-409b-9efa-53eadfe42efb",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "960980ca-aac1-42b8-a0ab-710473f7b133",
//                             "question_text": "<p>Provide assistance with a water audit.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "50f157d0-944a-49ab-ba08-39ec1f133405",
//                             "subdomain": "Water",
//                             "mandatory_question": 12,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 511,
//                             "is_active": 1,
//                             "competency": "Water Audits",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         }
//                     ],
//                     "Sustainable facility management": [
//                         {
//                             "id": "173313a9-5803-48ad-8ed3-6788b6666e15",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "773bd201-7cc5-4208-be1b-aadf40ea2d2c",
//                             "question_text": "<p>Assist with sustainability performance assessments, the development of goals, metrics, plans (e.g., stakeholder satisfaction, service delivery, benchmarking, vendor performance), and reporting.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "d1e258e6-dcc7-456c-9c8e-9c331f4b6ee5",
//                             "subdomain": "Sustainable facility management",
//                             "mandatory_question": 21,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 285,
//                             "is_active": 1,
//                             "competency": "Sustainability performance management",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "1fa5b5da-2a5b-4f85-97dc-89b75f54e566",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "ec51a4e6-897e-4e7e-9257-6b7e9dc8947b",
//                             "question_text": "<p>Conduct sustainability performance assessments, the development of goals, metrics, plans (e.g., stakeholder satisfaction, service delivery, benchmarking, vendor performance), and reporting.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "d1e258e6-dcc7-456c-9c8e-9c331f4b6ee5",
//                             "subdomain": "Sustainable facility management",
//                             "mandatory_question": 21,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 282,
//                             "is_active": 1,
//                             "competency": "Sustainability performance management",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "263c6861-21c9-41df-b458-581dfd006763",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "bc91b1bf-2a7c-4c39-9ef7-24980baeab28",
//                             "question_text": "<p>Assess sustainability performance assessments and action plan development (e.g., stakeholder satisfaction, service delivery, benchmarking, vendor performance).<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "d1e258e6-dcc7-456c-9c8e-9c331f4b6ee5",
//                             "subdomain": "Sustainable facility management",
//                             "mandatory_question": 21,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 279,
//                             "is_active": 1,
//                             "competency": "Sustainability performance management",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "2d0a3d7d-d4b6-4af3-b70a-83247c55b6dd",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "c93a108e-fb24-4322-92d2-30dd4cfdc604",
//                             "question_text": "<p>Help identify new and existing sustainability policies, procedures, and practices for the facility organization.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "d1e258e6-dcc7-456c-9c8e-9c331f4b6ee5",
//                             "subdomain": "Sustainable facility management",
//                             "mandatory_question": 21,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 266,
//                             "is_active": 1,
//                             "competency": "Sustainable facility management",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "39735aeb-dee4-4a1a-8688-0f3c65f60abc",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "02a67183-efd2-4ee1-bc13-c790f115efe8",
//                             "question_text": "<p>Facilitate and integrate discussion for implementation of sustainable initiatives with Finance Dept.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "d1e258e6-dcc7-456c-9c8e-9c331f4b6ee5",
//                             "subdomain": "Sustainable facility management",
//                             "mandatory_question": 21,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 293,
//                             "is_active": 1,
//                             "competency": "Finance",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "3c2af509-6661-4f73-be48-973697d03332",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "da72723e-099f-4cb4-826b-daa4f92c03b1",
//                             "question_text": "<p>Implement long-term planning for climate change risk to facilities.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "d1e258e6-dcc7-456c-9c8e-9c331f4b6ee5",
//                             "subdomain": "Sustainable facility management",
//                             "mandatory_question": 21,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 242,
//                             "is_active": 1,
//                             "competency": "Climate change",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "588d0bdd-e97c-48d3-9ac0-df1f79182c7e",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "1806b706-af02-4838-9d16-b6449006e7e9",
//                             "question_text": "<p>Implement and monitor a facility sustainability awareness program.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "d1e258e6-dcc7-456c-9c8e-9c331f4b6ee5",
//                             "subdomain": "Sustainable facility management",
//                             "mandatory_question": 21,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 264,
//                             "is_active": 1,
//                             "competency": "Sustainable facility management",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "5ccc553a-3921-4417-a16a-9941b2071784",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "5bf74ab2-131e-403b-a645-dd11287a4a5c",
//                             "question_text": "<p>Ensure long-term planning for climate change risk to facilities occurs. Determine feasibility of resiliency planning for short-term mitigation affecting facilities.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "d1e258e6-dcc7-456c-9c8e-9c331f4b6ee5",
//                             "subdomain": "Sustainable facility management",
//                             "mandatory_question": 21,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 239,
//                             "is_active": 1,
//                             "competency": "Climate change",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "5f0163b7-8039-4822-b474-957a4e5312f0",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "620ca734-7204-4e34-9075-088e19d14f07",
//                             "question_text": "<p>Develop qualitative and quantitative metrics to assess the impact of sustainability initiatives. <\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "d1e258e6-dcc7-456c-9c8e-9c331f4b6ee5",
//                             "subdomain": "Sustainable facility management",
//                             "mandatory_question": 21,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 254,
//                             "is_active": 1,
//                             "competency": "Sustainable facility management",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "5f08057b-f514-4535-9c35-657ea22e045d",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "1662c0af-c79b-4316-8531-d4f0f55f08d1",
//                             "question_text": "<p>Set carbon footprint reduction target and report the progress in achieving the target.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "d1e258e6-dcc7-456c-9c8e-9c331f4b6ee5",
//                             "subdomain": "Sustainable facility management",
//                             "mandatory_question": 21,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 613,
//                             "is_active": 1,
//                             "competency": "Carbon footprint management",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "732a1f05-945b-4ee7-bdbb-d9065e824ff0",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "e94a363d-4e1f-48b7-8378-8dacca086afd",
//                             "question_text": "<p>Evaluate and support sustainability policies, procedures, and practices. Confirm they align with the demand organization's strategic initiatives.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "d1e258e6-dcc7-456c-9c8e-9c331f4b6ee5",
//                             "subdomain": "Sustainable facility management",
//                             "mandatory_question": 21,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 271,
//                             "is_active": 1,
//                             "competency": "Sustainable facility planning",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "7fc71db3-a5c2-4a92-abc9-221b4fef10ba",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "b0631c59-b84f-4cbd-8e48-cac9cb182928",
//                             "question_text": "<p>Collect data on carbon footprint for facilities.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "d1e258e6-dcc7-456c-9c8e-9c331f4b6ee5",
//                             "subdomain": "Sustainable facility management",
//                             "mandatory_question": 21,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 251,
//                             "is_active": 1,
//                             "competency": "Carbon footprint management",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "881282d2-6c6f-4237-8da8-30511d396069",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "67e0ac11-fa3c-4891-b16a-fcab37eb6242",
//                             "question_text": "<p>Facilitate planning discussions for the implementation of sustainability policies, procedures, and practices for the facility organization.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "d1e258e6-dcc7-456c-9c8e-9c331f4b6ee5",
//                             "subdomain": "Sustainable facility management",
//                             "mandatory_question": 21,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 272,
//                             "is_active": 1,
//                             "competency": "Sustainable facility planning",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "8d744168-22dd-41d8-983c-d67decc51f91",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "12f968e7-3488-4b69-93ba-7b1967880f37",
//                             "question_text": "<p>Evaluate and support sustainability policies, procedures, and practices. Confirm they align with the demand organization's strategic initiatives.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "d1e258e6-dcc7-456c-9c8e-9c331f4b6ee5",
//                             "subdomain": "Sustainable facility management",
//                             "mandatory_question": 21,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 261,
//                             "is_active": 1,
//                             "competency": "Sustainable facility management",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "a3ab26c1-f6aa-48d4-968f-0cf88fb43268",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "589deebd-a233-4a2b-b4f9-4ee6429cc593",
//                             "question_text": "<p>Manage sustainability-related programs across the organization.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "d1e258e6-dcc7-456c-9c8e-9c331f4b6ee5",
//                             "subdomain": "Sustainable facility management",
//                             "mandatory_question": 21,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 256,
//                             "is_active": 1,
//                             "competency": "Sustainable facility management",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "b0e8e687-45a8-4926-a978-43bf765de32b",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "c842de18-d025-44a9-9b70-3deb513a592a",
//                             "question_text": "<p>Monitor, manage and report to reduce carbon footprint for facilities.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "d1e258e6-dcc7-456c-9c8e-9c331f4b6ee5",
//                             "subdomain": "Sustainable facility management",
//                             "mandatory_question": 21,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 248,
//                             "is_active": 1,
//                             "competency": "Carbon footprint management",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "b7373965-80a1-4b34-97e8-19e5ac08db28",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "147bfc75-065c-4251-86c8-0ddbd0244d8f",
//                             "question_text": "<p>Assist with long-term planning for climate change risk to facilities. <\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "d1e258e6-dcc7-456c-9c8e-9c331f4b6ee5",
//                             "subdomain": "Sustainable facility management",
//                             "mandatory_question": 21,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 245,
//                             "is_active": 1,
//                             "competency": "Climate change",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "bd7e37af-77fc-4b23-9a95-69a4f6c4220c",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "ad11eb08-a2d4-4008-ab5f-118df19b8b6a",
//                             "question_text": "<p>Assist in data collection required to perform LCA or LCC.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "d1e258e6-dcc7-456c-9c8e-9c331f4b6ee5",
//                             "subdomain": "Sustainable facility management",
//                             "mandatory_question": 21,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 312,
//                             "is_active": 1,
//                             "competency": "Finance",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "d1d3a529-c33f-464f-a8e6-4618c462823b",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "0a25d349-8611-4433-987a-ef4e856b5e8c",
//                             "question_text": "<p>Help identify new and existing sustainability policies, procedures, and practices for the facility organization.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "d1e258e6-dcc7-456c-9c8e-9c331f4b6ee5",
//                             "subdomain": "Sustainable facility management",
//                             "mandatory_question": 21,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 274,
//                             "is_active": 1,
//                             "competency": "Sustainable facility planning",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "d30460a6-829f-470a-b47b-2b99dd749de0",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "e5a8ee5f-e5e2-405d-91ba-24842cf0ec3f",
//                             "question_text": "<p>Implement sustainability-related programs at the building-level.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "d1e258e6-dcc7-456c-9c8e-9c331f4b6ee5",
//                             "subdomain": "Sustainable facility management",
//                             "mandatory_question": 21,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 258,
//                             "is_active": 1,
//                             "competency": "Sustainable facility management",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "ea9d1513-3f47-4b6e-8a6b-8c1d86b01b37",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "ff654f7b-4ea7-42fa-b62b-54a43a5480bf",
//                             "question_text": "<p>Develop and update process for integrating LCA or LCC into annual facility operations plan. Communicate milestones and objectives to demand organization Finance team.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "d1e258e6-dcc7-456c-9c8e-9c331f4b6ee5",
//                             "subdomain": "Sustainable facility management",
//                             "mandatory_question": 21,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 291,
//                             "is_active": 1,
//                             "competency": "Finance",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         }
//                     ],
//                     "Wellness": [
//                         {
//                             "id": "34d2aec8-abdb-4480-8ce3-20880f5f3cde",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "bc96b9f5-2aa1-4c4d-af32-73cb5edec97f",
//                             "question_text": "<p>Establish baseline ergonomic practices (materials, FF&amp;E procurement, standard operating procedurest) aligned with HR policies of the demand organization<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "2044d14b-765c-4493-99e7-d0123d00ca98",
//                             "subdomain": "Wellness",
//                             "mandatory_question": 9,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 551,
//                             "is_active": 1,
//                             "competency": "Ergonomics",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "3bbaed1e-acf7-487e-922c-b93fcccd2683",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "5413934f-3fb1-4999-968d-7b5714979dd6",
//                             "question_text": "<p>Manage the systems supporting the indoor environment to enhance occupant health, comfort and productivity (e.g., thermal comfort, visual comfort, acoustic comfort, and indoor air quality).<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "2044d14b-765c-4493-99e7-d0123d00ca98",
//                             "subdomain": "Wellness",
//                             "mandatory_question": 9,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 543,
//                             "is_active": 1,
//                             "competency": "Indoor environmental quality and health",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "4d16cf26-f880-4bb9-ba3c-e304697ebd0c",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "7a7ef84b-5669-41f2-8951-931eba75b8e1",
//                             "question_text": "<p>Facilitate the review of workplace ergonomics and establish an accountability plan within the FM team to address and maintain ergonomics associated with the work environment. <\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "2044d14b-765c-4493-99e7-d0123d00ca98",
//                             "subdomain": "Wellness",
//                             "mandatory_question": 9,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 553,
//                             "is_active": 1,
//                             "competency": "Ergonomics",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "4f163cb7-4452-4740-9036-4262333ace51",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "8b98b4bb-d9a1-4118-a3e2-e1212e0c255e",
//                             "question_text": "<p>Assist with monitoring and maintenance of systems supporting indoor environmental quality (IEQ)<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "2044d14b-765c-4493-99e7-d0123d00ca98",
//                             "subdomain": "Wellness",
//                             "mandatory_question": 9,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 545,
//                             "is_active": 1,
//                             "competency": "Indoor environmental quality and health",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "509c9655-0a17-459a-8171-7dc3f6790aad",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "752c8a00-e5eb-49d1-8d0e-f2097b522223",
//                             "question_text": "<p>Assess stakeholder perceptions and expectations.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "2044d14b-765c-4493-99e7-d0123d00ca98",
//                             "subdomain": "Wellness",
//                             "mandatory_question": 9,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 546,
//                             "is_active": 1,
//                             "competency": "Customer service",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "5a5de246-2452-4a81-a9ea-1a60070ce78d",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "99410594-9b6c-48e4-a091-aa4ad9fe290e",
//                             "question_text": "<p>Integrate the systems supporting the indoor environment into operational practices as it relates to occupant health, comfort and productivity (e.g., thermal comfort, visual comfort, acoustic comfort, and indoor air quality).<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "2044d14b-765c-4493-99e7-d0123d00ca98",
//                             "subdomain": "Wellness",
//                             "mandatory_question": 9,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 541,
//                             "is_active": 1,
//                             "competency": "Indoor environmental quality and health",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "6048991b-d7ba-40f2-8ea9-e4725ceac529",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "ce6d723a-57bd-4e17-b258-dcd171c60863",
//                             "question_text": "<p>Explain the importance of stakeholder  satisfaction and engagement.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "2044d14b-765c-4493-99e7-d0123d00ca98",
//                             "subdomain": "Wellness",
//                             "mandatory_question": 9,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 549,
//                             "is_active": 1,
//                             "competency": "Customer service",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "7314a286-50f2-4500-b5ec-ddf491f41835",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "a96fd45a-2c1e-4c4c-a572-e71d1d907d2a",
//                             "question_text": "<p>Assist with implementation and monitoring of standard operating procedures. Evaluate the success of the ergonomic program and recommend improvements.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "2044d14b-765c-4493-99e7-d0123d00ca98",
//                             "subdomain": "Wellness",
//                             "mandatory_question": 9,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 556,
//                             "is_active": 1,
//                             "competency": "Ergonomics",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "af61e2d1-bd79-4b18-8365-28bc3e398050",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "2f27c8df-6bfa-47f7-a62a-0c22a7244296",
//                             "question_text": "<p>Solicit stakeholder satisfaction and  engagement data.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "2044d14b-765c-4493-99e7-d0123d00ca98",
//                             "subdomain": "Wellness",
//                             "mandatory_question": 9,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 547,
//                             "is_active": 1,
//                             "competency": "Customer service",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         }
//                     ],
//                     "Site management": [
//                         {
//                             "id": "39ccd6b7-558b-426c-9970-f6b4a5db3a67",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "8d6358d7-0155-4563-9c4b-0d1a3a3ae773",
//                             "question_text": "<p>Assist with the implementation of sustainable landscaping practices.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "79d96da6-1a59-42f3-a6ac-295b00bf34fc",
//                             "subdomain": "Site management",
//                             "mandatory_question": 12,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 565,
//                             "is_active": 1,
//                             "competency": "Sustainable landscape management",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "39d2f15a-2744-486c-b50e-99e7037cd265",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "c6963e03-5f70-43db-8cba-19bafe227980",
//                             "question_text": "<p>Evaluate the light pollution mitigation efforts on the site.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "79d96da6-1a59-42f3-a6ac-295b00bf34fc",
//                             "subdomain": "Site management",
//                             "mandatory_question": 12,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 581,
//                             "is_active": 1,
//                             "competency": "Light pollution",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "3ad3d1c0-83f7-4aaf-9cab-a0668b8a842a",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "133033b5-177a-4efa-a16b-5e8b1bc483ed",
//                             "question_text": "<p>Implement sustainable landscaping practices.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "79d96da6-1a59-42f3-a6ac-295b00bf34fc",
//                             "subdomain": "Site management",
//                             "mandatory_question": 12,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 562,
//                             "is_active": 1,
//                             "competency": "Sustainable landscape management",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "416435f6-a829-4d60-b6b8-5cd1624f9866",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "1b29ff08-3138-4c60-a2cc-f290c5cb4785",
//                             "question_text": "<p>Assist with sustainable hardscape management initiatives.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "79d96da6-1a59-42f3-a6ac-295b00bf34fc",
//                             "subdomain": "Site management",
//                             "mandatory_question": 12,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 577,
//                             "is_active": 1,
//                             "competency": "Sustainable hardscape management",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "58b6fe56-bf70-4982-87ba-59a4263363a3",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "86b15822-a28b-4786-82e7-447958f86f34",
//                             "question_text": "<p>Develop a plan for light pollution management on the site.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "79d96da6-1a59-42f3-a6ac-295b00bf34fc",
//                             "subdomain": "Site management",
//                             "mandatory_question": 12,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 583,
//                             "is_active": 1,
//                             "competency": "Light pollution",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "726a70b4-d803-41e0-a899-a336ec8d63af",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "411772e8-2f87-4040-8fe4-5e5bf6af1018",
//                             "question_text": "<p>Explain stormwater regulations and assist with implementing sustainable stormwater initiatives.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "79d96da6-1a59-42f3-a6ac-295b00bf34fc",
//                             "subdomain": "Site management",
//                             "mandatory_question": 12,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 597,
//                             "is_active": 1,
//                             "competency": "Stormwater management",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "803b5756-ce11-476a-9c78-9ab1af3d71a5",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "1d470487-0ca5-45a6-9ec4-b2b903b1bce8",
//                             "question_text": "<p>Implement sustainable hardscape management initiatives.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "79d96da6-1a59-42f3-a6ac-295b00bf34fc",
//                             "subdomain": "Site management",
//                             "mandatory_question": 12,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 572,
//                             "is_active": 1,
//                             "competency": "Sustainable hardscape management",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "86ecfbcb-9cd4-49a0-9c8f-c31146bb7c86",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "135b62af-0a25-4b69-b2d0-77d3219828b9",
//                             "question_text": "<p>Identify light pollution sources on the site.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "79d96da6-1a59-42f3-a6ac-295b00bf34fc",
//                             "subdomain": "Site management",
//                             "mandatory_question": 12,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 588,
//                             "is_active": 1,
//                             "competency": "Light pollution",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "cdae66ae-b911-4898-979d-bf76f58d7cfd",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "0c7063da-7021-4f22-b4cc-498fc3f4621a",
//                             "question_text": "<p>Integrate sustainable landscape practices into operations.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "79d96da6-1a59-42f3-a6ac-295b00bf34fc",
//                             "subdomain": "Site management",
//                             "mandatory_question": 12,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 560,
//                             "is_active": 1,
//                             "competency": "Sustainable landscape management",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "d6dd2350-625a-489f-8bcf-2239181ea42a",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "fef80e89-5946-41db-a5a2-7ce8880c345c",
//                             "question_text": "<p>Integrate sustainable hardscape management into operations.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "79d96da6-1a59-42f3-a6ac-295b00bf34fc",
//                             "subdomain": "Site management",
//                             "mandatory_question": 12,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 568,
//                             "is_active": 1,
//                             "competency": "Sustainable hardscape management",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "dd87d228-4596-45ea-916e-fa2815a85d21",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "a71c3f6a-1fd7-442e-a15e-24a75d509f54",
//                             "question_text": "<p>Integrate sustainable stormwater initiatives into operations in accordance with stormwater regulations.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "79d96da6-1a59-42f3-a6ac-295b00bf34fc",
//                             "subdomain": "Site management",
//                             "mandatory_question": 12,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 591,
//                             "is_active": 1,
//                             "competency": "Stormwater management",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "fd4a6b40-0b07-4d11-80f5-f9fd8255ab19",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "d442a15a-395f-41d9-af28-a112f9f9d2fe",
//                             "question_text": "<p>Implement sustainable stormwater initiatives in accordance with stormwater regulations.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "79d96da6-1a59-42f3-a6ac-295b00bf34fc",
//                             "subdomain": "Site management",
//                             "mandatory_question": 12,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 593,
//                             "is_active": 1,
//                             "competency": "Stormwater management",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         }
//                     ],
//                     "Measure and Monitor": [
//                         {
//                             "id": "3b56e9d2-70cf-4a29-8891-ae152882c0b7",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "e8892b28-ad44-4432-83c2-45a9e4ead164",
//                             "question_text": "<p>Analyze energy and water consumption patterns by examining building meter and submeter data.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "0edfd78e-445f-4a4d-b227-9d0d833fa104",
//                             "subdomain": "Measure and Monitor",
//                             "mandatory_question": 3,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 517,
//                             "is_active": 1,
//                             "competency": "Utility meters",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "59d06812-ca01-47f2-87d4-e81b4aee61d4",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "fb0b4dd6-ecda-4848-8838-c3861443bab5",
//                             "question_text": "<p>Evaluate and confirm building meter and submeter requirements are optimizing energy and water consumption patterns.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "0edfd78e-445f-4a4d-b227-9d0d833fa104",
//                             "subdomain": "Measure and Monitor",
//                             "mandatory_question": 3,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 514,
//                             "is_active": 1,
//                             "competency": "Utility meters",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "88d6830d-dbbc-43ac-bb10-9afacef45fbc",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "138dafcf-fa15-4404-85ca-5c8cd8c67b98",
//                             "question_text": "<p>Assist in management of building meters and submeters in accordance with energy and water consumption patterns.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "0edfd78e-445f-4a4d-b227-9d0d833fa104",
//                             "subdomain": "Measure and Monitor",
//                             "mandatory_question": 3,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 519,
//                             "is_active": 1,
//                             "competency": "Utility meters",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         }
//                     ],
//                     "Materials and Consumables": [
//                         {
//                             "id": "5250c9cc-4d7e-4bdf-ac71-d91567b66244",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "4745e54d-053a-419f-9752-4550d1e12646",
//                             "question_text": "<p>Help define sustainability factors for procurement procedures (e.g., outsourcing, products, services, contractors, designers, whole buildings).<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "94bde05f-bacf-4bc7-9dbd-4ac8a824d543",
//                             "subdomain": "Materials and Consumables",
//                             "mandatory_question": 6,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 528,
//                             "is_active": 1,
//                             "competency": "Procurement",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "55f4064e-697e-4974-b957-f256b2fa74f1",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "30b21035-2fc4-47fc-8117-769cc9905187",
//                             "question_text": "<p>Integrate sustainability into the organization's supply chain, factoring in global initiatives.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "94bde05f-bacf-4bc7-9dbd-4ac8a824d543",
//                             "subdomain": "Materials and Consumables",
//                             "mandatory_question": 6,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 530,
//                             "is_active": 1,
//                             "competency": "Supply chain Sustainability",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "7152d873-8fbc-4b68-a6f8-76d15b558ac0",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "8c3c8bc6-031d-4aec-b716-55634b4d6a91",
//                             "question_text": "<p>Implement a sustainable procurement policy that includes applicable sustainability factors (e.g., outsourcing, products, services, contractors, designers, whole buildings).<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "94bde05f-bacf-4bc7-9dbd-4ac8a824d543",
//                             "subdomain": "Materials and Consumables",
//                             "mandatory_question": 6,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 527,
//                             "is_active": 1,
//                             "competency": "Procurement",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "9acf50de-034c-43a9-a82c-1e4834a3976e",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "e36e5bed-951f-41c6-9f1c-9dc4c3615943",
//                             "question_text": "<p>Assist in the incorporation of sustainability within the supply chain that supports the facility.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "94bde05f-bacf-4bc7-9dbd-4ac8a824d543",
//                             "subdomain": "Materials and Consumables",
//                             "mandatory_question": 6,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 533,
//                             "is_active": 1,
//                             "competency": "Supply chain Sustainability",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "e9e80535-6fbf-49ed-8728-58e6eaae4f4a",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "1e87f639-d730-4687-8c73-f916f8090105",
//                             "question_text": "<p>Collaborate with finance to develop and evaluate a sustainable procurement policy that includes applicable sustainability factors (e.g., outsourcing, products, services, contractors, designers, whole buildings).<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "94bde05f-bacf-4bc7-9dbd-4ac8a824d543",
//                             "subdomain": "Materials and Consumables",
//                             "mandatory_question": 6,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 525,
//                             "is_active": 1,
//                             "competency": "Procurement",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "ed3e9b39-537f-4a30-a1e9-e9b3b9883552",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "b3262f0b-a05d-4c31-9b16-65c4bab0921a",
//                             "question_text": "<p>Examine issues of incorporating sustainability within the  supply chain that supports the facility.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "94bde05f-bacf-4bc7-9dbd-4ac8a824d543",
//                             "subdomain": "Materials and Consumables",
//                             "mandatory_question": 6,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 532,
//                             "is_active": 1,
//                             "competency": "Supply chain Sustainability",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         }
//                     ],
//                     "Waste": [
//                         {
//                             "id": "58e65b09-aeda-491b-9bbd-208cb5a238ec",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "b53b45eb-79bd-42d2-9ebf-f57b6de203f1",
//                             "question_text": "<p>Evaluate and implement a facility solid waste management program.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "f48d945a-5965-467e-bddc-7eb20c12384a",
//                             "subdomain": "Waste",
//                             "mandatory_question": 3,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 534,
//                             "is_active": 1,
//                             "competency": "Solid Waste management",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "ab6400dd-da27-4178-828d-f3b856056b22",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "047709b4-4afb-4ef2-aeb2-7657aac576ad",
//                             "question_text": "<p>Develop and analyze strategies for reducing waste, encouraging reuse and ethical disposal when necessary. Communicate facility solid waste management program and objectives to all stakeholders.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "f48d945a-5965-467e-bddc-7eb20c12384a",
//                             "subdomain": "Waste",
//                             "mandatory_question": 3,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 537,
//                             "is_active": 1,
//                             "competency": "Solid Waste management",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "f039c60e-9565-40b0-9bcb-9276d261f97a",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "0031a97a-0389-4fca-8c0d-d90eae835cd1",
//                             "question_text": "<p>Identify and monitor total waste generated at the facility. Oversee implementation of programs.<\/p>",
//                             "domain_id": "7143e1a4-e755-4a60-830b-82c442d29dc9",
//                             "domain": "Sustainability",
//                             "subdomain_id": "f48d945a-5965-467e-bddc-7eb20c12384a",
//                             "subdomain": "Waste",
//                             "mandatory_question": 3,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 539,
//                             "is_active": 1,
//                             "competency": "Solid Waste management",
//                             "domain_seqeunce": 5,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         }
//                     ]
//                 },
//                 "Communication": {
//                     "Communication management": [
//                         {
//                             "id": "07c9273b-db51-47e9-88af-493f89c55811",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "a3690280-1551-4714-ac23-27dc4c8b8383",
//                             "question_text": "<p>Approves non-routine communications to external organizations.<\/p>",
//                             "domain_id": "1832c147-8232-46f7-ae25-4e37dd049c85",
//                             "domain": "Communication",
//                             "subdomain_id": "39299ac0-2f57-418b-be74-63420bf9c328",
//                             "subdomain": "Communication management",
//                             "mandatory_question": 27,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 323,
//                             "is_active": 1,
//                             "competency": "Communication planning",
//                             "domain_seqeunce": 6,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "1446d168-0eda-4e7d-a582-a8b991c59a82",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "c35e79ff-2452-4ee7-a3b1-6c9821620945",
//                             "question_text": "<p>Define strategy for facility management social media communication methods, modes, format, and activation plan in conjunction with demand organization public communication policies and practices.<\/p>",
//                             "domain_id": "1832c147-8232-46f7-ae25-4e37dd049c85",
//                             "domain": "Communication",
//                             "subdomain_id": "39299ac0-2f57-418b-be74-63420bf9c328",
//                             "subdomain": "Communication management",
//                             "mandatory_question": 27,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 382,
//                             "is_active": 1,
//                             "competency": "Social technology and social media savvy",
//                             "domain_seqeunce": 6,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "24cf05b5-697b-4ae5-9f00-49dc08b1e9b8",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "d774e9eb-b239-43c0-bd5b-9f5e72201a52",
//                             "question_text": "<p>Ensure timely and quality service delivery to clients by ensuring all complaints are responded to and followed up on to confirm customer satisfaction.<\/p>",
//                             "domain_id": "1832c147-8232-46f7-ae25-4e37dd049c85",
//                             "domain": "Communication",
//                             "subdomain_id": "39299ac0-2f57-418b-be74-63420bf9c328",
//                             "subdomain": "Communication management",
//                             "mandatory_question": 27,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 368,
//                             "is_active": 1,
//                             "competency": "Customer service",
//                             "domain_seqeunce": 6,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "38ead815-751c-44bb-9bba-8dd7245fa727",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "c38825c1-4e9e-4279-9963-6b3592f45261",
//                             "question_text": "<p>Define strategy with clients, executives, and major stakeholders to ensure needs are proactively gathered, expectations are set and met, and that priorities are considered.<\/p>",
//                             "domain_id": "1832c147-8232-46f7-ae25-4e37dd049c85",
//                             "domain": "Communication",
//                             "subdomain_id": "39299ac0-2f57-418b-be74-63420bf9c328",
//                             "subdomain": "Communication management",
//                             "mandatory_question": 27,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 377,
//                             "is_active": 1,
//                             "competency": "Stakeholder management",
//                             "domain_seqeunce": 6,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "4202a48a-5997-4bb7-8fb7-c4744fa0dfec",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "fe331beb-1aba-4a62-894a-81c14d386305",
//                             "question_text": "<p>Approves the release of any special or critical communications.<\/p>",
//                             "domain_id": "1832c147-8232-46f7-ae25-4e37dd049c85",
//                             "domain": "Communication",
//                             "subdomain_id": "39299ac0-2f57-418b-be74-63420bf9c328",
//                             "subdomain": "Communication management",
//                             "mandatory_question": 27,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 320,
//                             "is_active": 1,
//                             "competency": "Communication planning",
//                             "domain_seqeunce": 6,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "43027a1b-cc75-4c75-9e2e-f42f752d9fc1",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "138086de-9aa6-473f-a8e7-bcc62221c74b",
//                             "question_text": "<p>Assists with formatting and proofreading of all communications before distribution.<\/p>",
//                             "domain_id": "1832c147-8232-46f7-ae25-4e37dd049c85",
//                             "domain": "Communication",
//                             "subdomain_id": "39299ac0-2f57-418b-be74-63420bf9c328",
//                             "subdomain": "Communication management",
//                             "mandatory_question": 27,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 350,
//                             "is_active": 1,
//                             "competency": "Communication planning",
//                             "domain_seqeunce": 6,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "45ad3e7d-c8af-4208-8c66-3a199e0f614f",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "ca9eea1f-9819-4ef1-9cde-a42abb10c9b6",
//                             "question_text": "<p>Oversee communication, management, and reporting systems to ensure effectiveness.<\/p>",
//                             "domain_id": "1832c147-8232-46f7-ae25-4e37dd049c85",
//                             "domain": "Communication",
//                             "subdomain_id": "39299ac0-2f57-418b-be74-63420bf9c328",
//                             "subdomain": "Communication management",
//                             "mandatory_question": 27,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 337,
//                             "is_active": 1,
//                             "competency": "Communication planning",
//                             "domain_seqeunce": 6,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "48d09f98-f471-4f7e-95a0-04b68010587b",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "7f2e5e56-646e-4773-aa33-602f28499677",
//                             "question_text": "<p>Create and monitor effective social media FM communication, management, and responsibilities for updating communications.<\/p>",
//                             "domain_id": "1832c147-8232-46f7-ae25-4e37dd049c85",
//                             "domain": "Communication",
//                             "subdomain_id": "39299ac0-2f57-418b-be74-63420bf9c328",
//                             "subdomain": "Communication management",
//                             "mandatory_question": 27,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 384,
//                             "is_active": 1,
//                             "competency": "Social technology and social media savvy",
//                             "domain_seqeunce": 6,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "54e10145-3d60-410c-a9e2-d76368ce8838",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "a3d3aaf4-e51f-49d9-bd0a-90b9d6cf862d",
//                             "question_text": "<p>Assists with identification of messaging and proofreading of social media FM communications before posting.<\/p>",
//                             "domain_id": "1832c147-8232-46f7-ae25-4e37dd049c85",
//                             "domain": "Communication",
//                             "subdomain_id": "39299ac0-2f57-418b-be74-63420bf9c328",
//                             "subdomain": "Communication management",
//                             "mandatory_question": 27,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 387,
//                             "is_active": 1,
//                             "competency": "Social technology and social media savvy",
//                             "domain_seqeunce": 6,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "5614d0d9-398d-4d1f-8bb5-ae6c8025accf",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "76c6660a-faea-4a00-8889-9d401138579d",
//                             "question_text": "<p>Define strategy for communication method, mode, format, and plan for stakeholders.<\/p>",
//                             "domain_id": "1832c147-8232-46f7-ae25-4e37dd049c85",
//                             "domain": "Communication",
//                             "subdomain_id": "39299ac0-2f57-418b-be74-63420bf9c328",
//                             "subdomain": "Communication management",
//                             "mandatory_question": 27,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 315,
//                             "is_active": 1,
//                             "competency": "Communication planning",
//                             "domain_seqeunce": 6,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "655d21da-506a-4293-975b-ba181ae10409",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "b7a6eb0d-29b9-4681-91d3-86b18028c6d3",
//                             "question_text": "<p>Support the communications with engineers, suppliers, contractors, and plant partners to ensure customer satisfaction.<\/p>",
//                             "domain_id": "1832c147-8232-46f7-ae25-4e37dd049c85",
//                             "domain": "Communication",
//                             "subdomain_id": "39299ac0-2f57-418b-be74-63420bf9c328",
//                             "subdomain": "Communication management",
//                             "mandatory_question": 27,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 362,
//                             "is_active": 1,
//                             "competency": "Communication planning",
//                             "domain_seqeunce": 6,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "68afd7dd-8fee-4604-b1a0-a8d2c0693669",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "bd16b821-ae0b-43ab-8940-19479d506a23",
//                             "question_text": "<p>Review plan effectiveness of communication plan with senior management.<\/p>",
//                             "domain_id": "1832c147-8232-46f7-ae25-4e37dd049c85",
//                             "domain": "Communication",
//                             "subdomain_id": "39299ac0-2f57-418b-be74-63420bf9c328",
//                             "subdomain": "Communication management",
//                             "mandatory_question": 27,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 393,
//                             "is_active": 1,
//                             "competency": "Critical Thinking",
//                             "domain_seqeunce": 6,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "6becf3ff-d6b4-46bb-b371-5a4034d6447c",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "e325fae8-ff5d-47b5-aa4a-15b37cdca904",
//                             "question_text": "<p>Establish minimum acceptable practices for follow-up and performance measurements with internal and external stakeholders.<\/p>",
//                             "domain_id": "1832c147-8232-46f7-ae25-4e37dd049c85",
//                             "domain": "Communication",
//                             "subdomain_id": "39299ac0-2f57-418b-be74-63420bf9c328",
//                             "subdomain": "Communication management",
//                             "mandatory_question": 27,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 402,
//                             "is_active": 1,
//                             "competency": "Effective timely feedback",
//                             "domain_seqeunce": 6,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "7f8f2f2a-235e-4a6b-9595-8b080f485551",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "3708c81d-fb28-45f9-9529-4de51eeef87f",
//                             "question_text": "<p>Provide instruction\/direction to engineers, suppliers, contractors, and plant partners to ensure customer satisfaction.<\/p>",
//                             "domain_id": "1832c147-8232-46f7-ae25-4e37dd049c85",
//                             "domain": "Communication",
//                             "subdomain_id": "39299ac0-2f57-418b-be74-63420bf9c328",
//                             "subdomain": "Communication management",
//                             "mandatory_question": 27,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 372,
//                             "is_active": 1,
//                             "competency": "Customer service",
//                             "domain_seqeunce": 6,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "845612b7-ca06-4791-ab43-5639ceac14e7",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "0b98ebde-f0be-4180-8b57-e421f6e9b1ea",
//                             "question_text": "<p>Provide instruction\/direction to engineers, suppliers, contractors, and plant partners to ensure customer satisfaction.<\/p>",
//                             "domain_id": "1832c147-8232-46f7-ae25-4e37dd049c85",
//                             "domain": "Communication",
//                             "subdomain_id": "39299ac0-2f57-418b-be74-63420bf9c328",
//                             "subdomain": "Communication management",
//                             "mandatory_question": 27,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 396,
//                             "is_active": 1,
//                             "competency": "Critical Thinking",
//                             "domain_seqeunce": 6,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "92af04d1-5a00-4b8b-99fa-f40f480559c0",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "f6139e8c-f279-49c8-af9b-2a026b9017e4",
//                             "question_text": "<p>Responds to more complex client inquires and manages all complaint responses.<\/p>",
//                             "domain_id": "1832c147-8232-46f7-ae25-4e37dd049c85",
//                             "domain": "Communication",
//                             "subdomain_id": "39299ac0-2f57-418b-be74-63420bf9c328",
//                             "subdomain": "Communication management",
//                             "mandatory_question": 27,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 345,
//                             "is_active": 1,
//                             "competency": "Communication planning",
//                             "domain_seqeunce": 6,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "9a8e7588-67f7-473a-9849-161b60ba5bb3",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "b50c36d2-008d-4136-8e06-68ae0021372c",
//                             "question_text": "<p>Assist in dissemination of information to garner stakeholder feedback.<\/p>",
//                             "domain_id": "1832c147-8232-46f7-ae25-4e37dd049c85",
//                             "domain": "Communication",
//                             "subdomain_id": "39299ac0-2f57-418b-be74-63420bf9c328",
//                             "subdomain": "Communication management",
//                             "mandatory_question": 27,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 407,
//                             "is_active": 1,
//                             "competency": "Effective timely feedback",
//                             "domain_seqeunce": 6,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "9d9d6d25-282a-47b0-83c9-d321b9efdc55",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "79a16f8e-1eac-4725-a81e-06bed0d49c5e",
//                             "question_text": "<p>Responds to basic client inquires and complaints.<\/p>",
//                             "domain_id": "1832c147-8232-46f7-ae25-4e37dd049c85",
//                             "domain": "Communication",
//                             "subdomain_id": "39299ac0-2f57-418b-be74-63420bf9c328",
//                             "subdomain": "Communication management",
//                             "mandatory_question": 27,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 357,
//                             "is_active": 1,
//                             "competency": "Communication planning",
//                             "domain_seqeunce": 6,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "a5311592-a514-4fd2-8a47-5ecce88cc361",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "3a37170a-db60-45a3-aa36-37aae67a336f",
//                             "question_text": "<p>Ensure timely and quality service delivery to clients by ensuring all complaints are responded to and followed up on to confirm customer satisfaction.<\/p>",
//                             "domain_id": "1832c147-8232-46f7-ae25-4e37dd049c85",
//                             "domain": "Communication",
//                             "subdomain_id": "39299ac0-2f57-418b-be74-63420bf9c328",
//                             "subdomain": "Communication management",
//                             "mandatory_question": 27,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 379,
//                             "is_active": 1,
//                             "competency": "Stakeholder management",
//                             "domain_seqeunce": 6,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "ade0c969-3efe-433e-b53a-2ce7871fffb4",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "a49e05f7-73e1-46e6-9ab9-b2a0276faad8",
//                             "question_text": "<p>Delegate specific levels of authority relative to communications to others in the organization.<\/p>",
//                             "domain_id": "1832c147-8232-46f7-ae25-4e37dd049c85",
//                             "domain": "Communication",
//                             "subdomain_id": "39299ac0-2f57-418b-be74-63420bf9c328",
//                             "subdomain": "Communication management",
//                             "mandatory_question": 27,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 325,
//                             "is_active": 1,
//                             "competency": "Communication planning",
//                             "domain_seqeunce": 6,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "b28fb421-7a7a-4bdf-af37-e7667952caad",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "b78011c9-1cb5-4db8-96b6-f33f587462cd",
//                             "question_text": "<p>Define communication frequency, mode, and method for all services.<\/p>",
//                             "domain_id": "1832c147-8232-46f7-ae25-4e37dd049c85",
//                             "domain": "Communication",
//                             "subdomain_id": "39299ac0-2f57-418b-be74-63420bf9c328",
//                             "subdomain": "Communication management",
//                             "mandatory_question": 27,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 339,
//                             "is_active": 1,
//                             "competency": "Communication planning",
//                             "domain_seqeunce": 6,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "c1a0732e-6c28-437f-97d6-f1be8f993b96",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "9df7e6ec-771b-4d36-bb98-60183faba63f",
//                             "question_text": "<p>Responds to \"basic\" client inquiries and complaints<\/p>",
//                             "domain_id": "1832c147-8232-46f7-ae25-4e37dd049c85",
//                             "domain": "Communication",
//                             "subdomain_id": "39299ac0-2f57-418b-be74-63420bf9c328",
//                             "subdomain": "Communication management",
//                             "mandatory_question": 27,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 375,
//                             "is_active": 1,
//                             "competency": "Customer service",
//                             "domain_seqeunce": 6,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "c5e6b5f8-1dc7-4263-b9dc-44a03c5a96c7",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "5d75ae88-e32c-453a-8e34-9e31c3c4e816",
//                             "question_text": "<p>Create effective communication, management, and reporting systems for routine communications.<\/p>",
//                             "domain_id": "1832c147-8232-46f7-ae25-4e37dd049c85",
//                             "domain": "Communication",
//                             "subdomain_id": "39299ac0-2f57-418b-be74-63420bf9c328",
//                             "subdomain": "Communication management",
//                             "mandatory_question": 27,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 333,
//                             "is_active": 1,
//                             "competency": "Communication planning",
//                             "domain_seqeunce": 6,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "d43f99ca-2940-4205-8790-86f845c89bfb",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "d6f5f62f-48a7-4e5a-8710-b8c5e5b652c5",
//                             "question_text": "<p>Prepare and collect information and content for the communication plan.<\/p>",
//                             "domain_id": "1832c147-8232-46f7-ae25-4e37dd049c85",
//                             "domain": "Communication",
//                             "subdomain_id": "39299ac0-2f57-418b-be74-63420bf9c328",
//                             "subdomain": "Communication management",
//                             "mandatory_question": 27,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 354,
//                             "is_active": 1,
//                             "competency": "Communication planning",
//                             "domain_seqeunce": 6,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "d78d77cb-0368-4b0d-a3bf-fb20b093f249",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "b203d013-fbd9-48d8-b8a2-3017c07727ab",
//                             "question_text": "<p>Collect feedback, information, and data related to evaluation of the communication plan and strategy.<\/p>",
//                             "domain_id": "1832c147-8232-46f7-ae25-4e37dd049c85",
//                             "domain": "Communication",
//                             "subdomain_id": "39299ac0-2f57-418b-be74-63420bf9c328",
//                             "subdomain": "Communication management",
//                             "mandatory_question": 27,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 399,
//                             "is_active": 1,
//                             "competency": "Critical Thinking",
//                             "domain_seqeunce": 6,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "f2da2bab-2910-4421-b89c-88a4f121f1f1",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "d6f69fc1-474c-4c84-b486-10e00bb9981a",
//                             "question_text": "<p>Notify supervisor\/manager when incoming communications warrant their attention\/input.<\/p>",
//                             "domain_id": "1832c147-8232-46f7-ae25-4e37dd049c85",
//                             "domain": "Communication",
//                             "subdomain_id": "39299ac0-2f57-418b-be74-63420bf9c328",
//                             "subdomain": "Communication management",
//                             "mandatory_question": 27,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 381,
//                             "is_active": 1,
//                             "competency": "Stakeholder management",
//                             "domain_seqeunce": 6,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "f641d4db-2bc2-4978-87f1-e48ff4b19c85",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "14b116f6-6198-4124-800b-6d855bb304ed",
//                             "question_text": "<p>Create scorecard and monitor effectiveness of feedback loop using 360 approach (or similar) to obtain constructive input on FM practices and personnel interactions.<\/p>",
//                             "domain_id": "1832c147-8232-46f7-ae25-4e37dd049c85",
//                             "domain": "Communication",
//                             "subdomain_id": "39299ac0-2f57-418b-be74-63420bf9c328",
//                             "subdomain": "Communication management",
//                             "mandatory_question": 27,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 404,
//                             "is_active": 1,
//                             "competency": "Effective timely feedback",
//                             "domain_seqeunce": 6,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         }
//                     ]
//                 },
//                 "Quality": {
//                     "Quality management": [
//                         {
//                             "id": "08158cda-8de4-4cdd-a028-cb85aa5374bf",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "b791ee00-879f-4008-b328-f962c309a737",
//                             "question_text": "<p>Examine service contract standards and language to confirm consistency with SLAs and SOWs. <\/p>",
//                             "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                             "domain": "Quality",
//                             "subdomain_id": "a6081fb2-7af5-44ac-b195-3ae0adee021b",
//                             "subdomain": "Quality management",
//                             "mandatory_question": 21,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 555,
//                             "is_active": 1,
//                             "competency": "Contract Oversight",
//                             "domain_seqeunce": 7,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "14d21a50-e301-41dc-8fd7-453aa291378e",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "e608c0a4-1081-4e41-a293-101b750a299b",
//                             "question_text": "<p>Assist in conducting inspections and preparing reports (e.g., reliability, costs, regulatory compliance, asset availability, etc.).<\/p>",
//                             "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                             "domain": "Quality",
//                             "subdomain_id": "a6081fb2-7af5-44ac-b195-3ae0adee021b",
//                             "subdomain": "Quality management",
//                             "mandatory_question": 21,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 459,
//                             "is_active": 1,
//                             "competency": "Performance measures",
//                             "domain_seqeunce": 7,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "2e7b9a89-917e-4587-b8d6-01a797ab0b0e",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "c4f5bbc5-8aa0-4062-b920-fcfc04a7950d",
//                             "question_text": "<p>Design methods, policies, and procedures for setting quality standards and evaluating FM service delivery.<\/p>",
//                             "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                             "domain": "Quality",
//                             "subdomain_id": "a6081fb2-7af5-44ac-b195-3ae0adee021b",
//                             "subdomain": "Quality management",
//                             "mandatory_question": 21,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 417,
//                             "is_active": 1,
//                             "competency": "Quality management system",
//                             "domain_seqeunce": 7,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "2ff82ba1-8079-4ca5-8075-50c1fc0ecb0e",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "1619ec1e-5f66-4293-b7c3-524db58185e3",
//                             "question_text": "<p>Manage contractors to make sure their work satisfies the service contract standards.<\/p>",
//                             "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                             "domain": "Quality",
//                             "subdomain_id": "a6081fb2-7af5-44ac-b195-3ae0adee021b",
//                             "subdomain": "Quality management",
//                             "mandatory_question": 21,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 563,
//                             "is_active": 1,
//                             "competency": "Contract Oversight",
//                             "domain_seqeunce": 7,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "4b1a8ea0-0dee-42d5-8311-61bbcee78615",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "5d288fa3-f727-4294-bccd-810a8d1f9a7d",
//                             "question_text": "<p>Participate in quality assurance and quality control activities.<\/p>",
//                             "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                             "domain": "Quality",
//                             "subdomain_id": "a6081fb2-7af5-44ac-b195-3ae0adee021b",
//                             "subdomain": "Quality management",
//                             "mandatory_question": 21,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 504,
//                             "is_active": 1,
//                             "competency": "Quality control",
//                             "domain_seqeunce": 7,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "56df59ab-207c-4901-ab52-d499262a9705",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "62de78e1-1e7c-43c8-b1ee-1ab692efcebc",
//                             "question_text": "<p>Perform work in accordance with the organization's quality standards and established FM services methods, policies, and procedures.<\/p>",
//                             "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                             "domain": "Quality",
//                             "subdomain_id": "a6081fb2-7af5-44ac-b195-3ae0adee021b",
//                             "subdomain": "Quality management",
//                             "mandatory_question": 21,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 433,
//                             "is_active": 1,
//                             "competency": "Quality management system",
//                             "domain_seqeunce": 7,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "5903445e-3f80-4171-ac98-be6c932786d9",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "e40b461f-9ed3-484a-89f5-162bc8ce8d46",
//                             "question_text": "<p>Establish performance measures to monitor and identify improvements in reliability, costs, regulatory compliance, and asset availability.<\/p>",
//                             "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                             "domain": "Quality",
//                             "subdomain_id": "a6081fb2-7af5-44ac-b195-3ae0adee021b",
//                             "subdomain": "Quality management",
//                             "mandatory_question": 21,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 447,
//                             "is_active": 1,
//                             "competency": "Performance measures",
//                             "domain_seqeunce": 7,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "5d70122b-b81d-4abd-8e10-d31b488eb79a",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "79fb45a0-641d-491b-9043-d15e59b6b4c5",
//                             "question_text": "<p>Confirm all regulatory conmpliances are met, take corrective action as needed and inform senior management.<\/p>",
//                             "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                             "domain": "Quality",
//                             "subdomain_id": "a6081fb2-7af5-44ac-b195-3ae0adee021b",
//                             "subdomain": "Quality management",
//                             "mandatory_question": 21,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 518,
//                             "is_active": 1,
//                             "competency": "Compliance",
//                             "domain_seqeunce": 7,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "6253fad3-3b96-4ba4-a2d4-dbe00fa401a5",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "f8319177-40f5-4993-8ecb-7e75ac8c4297",
//                             "question_text": "<p>Perform quality assurance, and quality control activities.<\/p>",
//                             "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                             "domain": "Quality",
//                             "subdomain_id": "a6081fb2-7af5-44ac-b195-3ae0adee021b",
//                             "subdomain": "Quality management",
//                             "mandatory_question": 21,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 499,
//                             "is_active": 1,
//                             "competency": "Quality control",
//                             "domain_seqeunce": 7,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "70f96579-b60b-4fdd-8b08-63dc118ce2a3",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "fb2ba1e0-1460-4fa9-b5ec-6b5ee17c4f4c",
//                             "question_text": "<p>Help monitor contractor performance to confirm it meets contract requirements.<\/p>",
//                             "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                             "domain": "Quality",
//                             "subdomain_id": "a6081fb2-7af5-44ac-b195-3ae0adee021b",
//                             "subdomain": "Quality management",
//                             "mandatory_question": 21,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 573,
//                             "is_active": 1,
//                             "competency": "Contract Oversight",
//                             "domain_seqeunce": 7,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "755feaa2-cabe-4e7b-a5f6-11b394c29801",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "fb658245-99a0-470e-88ba-2f694db67e5b",
//                             "question_text": "<p>Collect, assess, and create regulatory compliance documentation.<\/p>",
//                             "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                             "domain": "Quality",
//                             "subdomain_id": "a6081fb2-7af5-44ac-b195-3ae0adee021b",
//                             "subdomain": "Quality management",
//                             "mandatory_question": 21,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 520,
//                             "is_active": 1,
//                             "competency": "Compliance",
//                             "domain_seqeunce": 7,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "7777164e-c4f4-4147-abec-9cac36a535db",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "d8769071-1cf6-46e5-a0e9-a91366a47dbe",
//                             "question_text": "<p>Participate in quality assurance and quality control activities.<\/p>",
//                             "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                             "domain": "Quality",
//                             "subdomain_id": "a6081fb2-7af5-44ac-b195-3ae0adee021b",
//                             "subdomain": "Quality management",
//                             "mandatory_question": 21,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 480,
//                             "is_active": 1,
//                             "competency": "Quality assurance",
//                             "domain_seqeunce": 7,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "7a90ca3f-fa4a-436b-a28d-6c9ac19b2ed0",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "44303020-e616-4291-b29c-3091fd93b5e0",
//                             "question_text": "<p>Assist with regulatory inspections.<\/p>",
//                             "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                             "domain": "Quality",
//                             "subdomain_id": "a6081fb2-7af5-44ac-b195-3ae0adee021b",
//                             "subdomain": "Quality management",
//                             "mandatory_question": 21,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 523,
//                             "is_active": 1,
//                             "competency": "Compliance",
//                             "domain_seqeunce": 7,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "7b0ecc11-e544-4315-bc92-bb9678117678",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "1261a768-d7a8-4f41-a085-bfe7fe0772d9",
//                             "question_text": "<p>Rate continuous improvement of service delivery based on  established metrics. <\/p>",
//                             "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                             "domain": "Quality",
//                             "subdomain_id": "a6081fb2-7af5-44ac-b195-3ae0adee021b",
//                             "subdomain": "Quality management",
//                             "mandatory_question": 21,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 569,
//                             "is_active": 1,
//                             "competency": "Contract Oversight",
//                             "domain_seqeunce": 7,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "b0632a40-a42c-464a-9d71-c51bdd83c540",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "70bc8f47-a510-4184-aaff-2bbbce055f27",
//                             "question_text": "<p>Perform work based on established metrics and measure service levels based on standards.<\/p>",
//                             "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                             "domain": "Quality",
//                             "subdomain_id": "a6081fb2-7af5-44ac-b195-3ae0adee021b",
//                             "subdomain": "Quality management",
//                             "mandatory_question": 21,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 576,
//                             "is_active": 1,
//                             "competency": "Contract Oversight",
//                             "domain_seqeunce": 7,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "b6aae5d2-9001-4347-88fc-2712cb1ee23a",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "db85ab9a-7617-4ce9-9ffa-8e827face375",
//                             "question_text": "<p>Advocate for facility inspections and communicate inspection results, including recommendations and risk mitigation strategies, to the appropriate customers or stakeholders. <\/p>",
//                             "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                             "domain": "Quality",
//                             "subdomain_id": "a6081fb2-7af5-44ac-b195-3ae0adee021b",
//                             "subdomain": "Quality management",
//                             "mandatory_question": 21,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 473,
//                             "is_active": 1,
//                             "competency": "Quality assurance",
//                             "domain_seqeunce": 7,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "e23354f6-1742-4835-b873-f2db7ec689b1",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "a44cf384-826a-4531-ab03-a133699472c4",
//                             "question_text": "<p>Assign and review work for all FM staff in accordance with the organization's quality standards and established methods, policies, and procedures.<\/p>",
//                             "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                             "domain": "Quality",
//                             "subdomain_id": "a6081fb2-7af5-44ac-b195-3ae0adee021b",
//                             "subdomain": "Quality management",
//                             "mandatory_question": 21,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 423,
//                             "is_active": 1,
//                             "competency": "Quality management system",
//                             "domain_seqeunce": 7,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "e682079b-5d3b-41f9-9d06-4f1cc6446ac7",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "b855d1b4-bfa4-4964-9b66-ab69a8a9e262",
//                             "question_text": "<p>Perform quality assurance, and quality control activities.<\/p>",
//                             "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                             "domain": "Quality",
//                             "subdomain_id": "a6081fb2-7af5-44ac-b195-3ae0adee021b",
//                             "subdomain": "Quality management",
//                             "mandatory_question": 21,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 475,
//                             "is_active": 1,
//                             "competency": "Quality assurance",
//                             "domain_seqeunce": 7,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "ee18545c-9cb1-4a85-89d9-973398945681",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "605572d8-fd35-426b-a479-e655e96cc7b2",
//                             "question_text": "<p>Confirm service level metrics are the best metrics for the organization and adjust as needed.<\/p>",
//                             "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                             "domain": "Quality",
//                             "subdomain_id": "a6081fb2-7af5-44ac-b195-3ae0adee021b",
//                             "subdomain": "Quality management",
//                             "mandatory_question": 21,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 558,
//                             "is_active": 1,
//                             "competency": "Contract Oversight",
//                             "domain_seqeunce": 7,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "ee332443-035d-4c5d-b696-ec3949225675",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "426907f2-1aec-4fa9-a1d6-98599ba78186",
//                             "question_text": "<p>Design a performance management program that includes metrics and key performance indicators (KPIs) that align with organizational strategies and goals.<\/p>",
//                             "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                             "domain": "Quality",
//                             "subdomain_id": "a6081fb2-7af5-44ac-b195-3ae0adee021b",
//                             "subdomain": "Quality management",
//                             "mandatory_question": 21,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 441,
//                             "is_active": 1,
//                             "competency": "Performance measures",
//                             "domain_seqeunce": 7,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "fad516e2-4f0b-419b-aa05-0c330130d865",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "431a2e3b-afa2-4071-ae30-baa7f0c81791",
//                             "question_text": "<p>Advocate for facility inspections and communicate inspection results, including recommendations and risk mitigation strategies, to the appropriate customers or stakeholders. <\/p>",
//                             "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                             "domain": "Quality",
//                             "subdomain_id": "a6081fb2-7af5-44ac-b195-3ae0adee021b",
//                             "subdomain": "Quality management",
//                             "mandatory_question": 21,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 488,
//                             "is_active": 1,
//                             "competency": "Quality control",
//                             "domain_seqeunce": 7,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         }
//                     ],
//                     "Quality improvement": [
//                         {
//                             "id": "0be7fa39-0552-45ec-b97b-fffbcc859185",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "abff153a-51cb-4cb0-9abc-b68f1c2acaf9",
//                             "question_text": "<p>Advocate for facility inspections and communicate inspection results, including recommendations and risk mitigation strategies, to the appropriate customers or stakeholders. <\/p>",
//                             "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                             "domain": "Quality",
//                             "subdomain_id": "d7452919-bef6-498a-abf1-6ac26a3df0da",
//                             "subdomain": "Quality improvement",
//                             "mandatory_question": 21,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 600,
//                             "is_active": 1,
//                             "competency": "Quality improvement",
//                             "domain_seqeunce": 7,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "2401c8d7-84a5-4804-a5d7-85894dc27b73",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "ad49b0f4-d822-4fd8-973d-ccf64ce1ec78",
//                             "question_text": "<p>Confirm all regulatory conmpliances are met, take corrective action as needed and inform senior management.<\/p>",
//                             "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                             "domain": "Quality",
//                             "subdomain_id": "d7452919-bef6-498a-abf1-6ac26a3df0da",
//                             "subdomain": "Quality improvement",
//                             "mandatory_question": 21,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 611,
//                             "is_active": 1,
//                             "competency": "Quality improvement",
//                             "domain_seqeunce": 7,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "2b53ce14-d77a-4589-a118-2760291b5250",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "a8f52d65-1aaf-46f2-9168-8e366d2e8de6",
//                             "question_text": "<p>Perform quality assurance, and quality control activities.<\/p>",
//                             "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                             "domain": "Quality",
//                             "subdomain_id": "d7452919-bef6-498a-abf1-6ac26a3df0da",
//                             "subdomain": "Quality improvement",
//                             "mandatory_question": 21,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 602,
//                             "is_active": 1,
//                             "competency": "Quality improvement",
//                             "domain_seqeunce": 7,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "379a3953-3d2c-42cb-be31-37fb07395185",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "4c0892fe-5870-4fcb-ad4d-263026e32def",
//                             "question_text": "<p>Assess customer and stakeholder feedback results to adjust or align services with the organization's objectives. <\/p>",
//                             "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                             "domain": "Quality",
//                             "subdomain_id": "d7452919-bef6-498a-abf1-6ac26a3df0da",
//                             "subdomain": "Quality improvement",
//                             "mandatory_question": 21,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 610,
//                             "is_active": 1,
//                             "competency": "Quality improvement",
//                             "domain_seqeunce": 7,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "43f1c5e1-9515-49da-b249-5c7f73627aae",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "1b57a419-e0f9-44fc-936d-bedebd6d42c2",
//                             "question_text": "<p>Assess the impact of recommendations and advocate for initiatives to improve processes, reduce costs, enhance employee productivity, and maximize best-in-class service.<\/p>",
//                             "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                             "domain": "Quality",
//                             "subdomain_id": "d7452919-bef6-498a-abf1-6ac26a3df0da",
//                             "subdomain": "Quality improvement",
//                             "mandatory_question": 21,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 580,
//                             "is_active": 1,
//                             "competency": "Quality improvement",
//                             "domain_seqeunce": 7,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "497af1f8-923d-40e8-bf14-93c32468d20d",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "10631e14-2148-4671-a0c3-9f1eaff8bc07",
//                             "question_text": "<p>Collect and report on facility\u2019s data and expenditures for developing recommendations on how to improve processes, reduce costs, enhance employee productivity, and maximize best-in-class service.<\/p>",
//                             "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                             "domain": "Quality",
//                             "subdomain_id": "d7452919-bef6-498a-abf1-6ac26a3df0da",
//                             "subdomain": "Quality improvement",
//                             "mandatory_question": 21,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 587,
//                             "is_active": 1,
//                             "competency": "Quality improvement",
//                             "domain_seqeunce": 7,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "5e45bc97-adca-4374-9881-d5cf939cf1ff",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "d96078a2-9700-4916-bca5-0eaaa493c721",
//                             "question_text": "<p>Conduct root causes investigations and recommended corrective\/preventative actions.<\/p>",
//                             "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                             "domain": "Quality",
//                             "subdomain_id": "d7452919-bef6-498a-abf1-6ac26a3df0da",
//                             "subdomain": "Quality improvement",
//                             "mandatory_question": 21,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 612,
//                             "is_active": 1,
//                             "competency": "Quality improvement",
//                             "domain_seqeunce": 7,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "64ef5911-0943-454b-8e4a-208d1eaf1094",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "35d4436b-d834-481c-8fa1-ed4c40ae94e4",
//                             "question_text": "<p>Participate in collecting and analyzing data relevant to developing quality and continuous improvement programs.<\/p>",
//                             "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                             "domain": "Quality",
//                             "subdomain_id": "d7452919-bef6-498a-abf1-6ac26a3df0da",
//                             "subdomain": "Quality improvement",
//                             "mandatory_question": 21,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 596,
//                             "is_active": 1,
//                             "competency": "Quality improvement",
//                             "domain_seqeunce": 7,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "661b4ea0-0740-46cf-8dc8-c5590e30c55c",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "b45a6328-8c10-4644-8631-de6e59a72b6a",
//                             "question_text": "<p>Assist with regulatory inspections.<\/p>",
//                             "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                             "domain": "Quality",
//                             "subdomain_id": "d7452919-bef6-498a-abf1-6ac26a3df0da",
//                             "subdomain": "Quality improvement",
//                             "mandatory_question": 21,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 618,
//                             "is_active": 1,
//                             "competency": "Quality improvement",
//                             "domain_seqeunce": 7,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "6e8b1791-6a93-4384-be6c-7688eb68f581",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "1dd59755-b556-4623-8938-d6b7705d12e0",
//                             "question_text": "<p>Participate in quality assurance and quality control activities.<\/p>",
//                             "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                             "domain": "Quality",
//                             "subdomain_id": "d7452919-bef6-498a-abf1-6ac26a3df0da",
//                             "subdomain": "Quality improvement",
//                             "mandatory_question": 21,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 604,
//                             "is_active": 1,
//                             "competency": "Quality improvement",
//                             "domain_seqeunce": 7,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "8ef241f4-a9bd-401a-8d0a-150ba2b48301",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "4e8b4e48-6722-43cf-81ff-4ca14542b194",
//                             "question_text": "<p>Direct implementation of quality and continuous improvement system.<\/p>",
//                             "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                             "domain": "Quality",
//                             "subdomain_id": "d7452919-bef6-498a-abf1-6ac26a3df0da",
//                             "subdomain": "Quality improvement",
//                             "mandatory_question": 21,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 590,
//                             "is_active": 1,
//                             "competency": "Quality improvement",
//                             "domain_seqeunce": 7,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "a0106aab-2733-4e8c-aa51-edc68ff900c5",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "dee5467d-b4cb-4f8e-801b-d4625d53cc54",
//                             "question_text": "<p>Analyze facility\u2019s data and expenditures to develop and propose recommendations on how to improve processes, reduce costs, enhance employee productivity, and maximize best-in-class service.<\/p>",
//                             "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                             "domain": "Quality",
//                             "subdomain_id": "d7452919-bef6-498a-abf1-6ac26a3df0da",
//                             "subdomain": "Quality improvement",
//                             "mandatory_question": 21,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 584,
//                             "is_active": 1,
//                             "competency": "Quality improvement",
//                             "domain_seqeunce": 7,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "b2839497-4370-46d9-ba17-28bbd71f1909",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "781c8482-10ff-4812-b266-462a2e82ec52",
//                             "question_text": "<p>Develop quality and continuous improvement plans.<\/p>",
//                             "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                             "domain": "Quality",
//                             "subdomain_id": "d7452919-bef6-498a-abf1-6ac26a3df0da",
//                             "subdomain": "Quality improvement",
//                             "mandatory_question": 21,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 594,
//                             "is_active": 1,
//                             "competency": "Quality improvement",
//                             "domain_seqeunce": 7,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "ba7c10e0-21aa-4bba-8d1a-7649402d3cb0",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "4e9cd2cf-4dca-43e1-8f96-14920db7e7bd",
//                             "question_text": "<p>Collect, assess, and create regulatory compliance documentation.<\/p>",
//                             "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                             "domain": "Quality",
//                             "subdomain_id": "d7452919-bef6-498a-abf1-6ac26a3df0da",
//                             "subdomain": "Quality improvement",
//                             "mandatory_question": 21,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 615,
//                             "is_active": 1,
//                             "competency": "Quality improvement",
//                             "domain_seqeunce": 7,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "bc78acac-1009-4fd9-a14b-c61383c629e7",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "dce5f4cb-abd9-4d10-a1d4-af0d0ea8db82",
//                             "question_text": "<p>Confirm best course of action for implementing strategies for risk management mitigation.<\/p>",
//                             "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                             "domain": "Quality",
//                             "subdomain_id": "d7452919-bef6-498a-abf1-6ac26a3df0da",
//                             "subdomain": "Quality improvement",
//                             "mandatory_question": 21,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 609,
//                             "is_active": 1,
//                             "competency": "Quality improvement",
//                             "domain_seqeunce": 7,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "c8fa05a4-3b46-4f2b-84e1-04494656d274",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "4a4bb0a4-7b04-473c-86e2-0ddad53e083d",
//                             "question_text": "<p>Recommend and implement corrective and preventive actions based on established acceptable deviations, and change controls.<\/p>",
//                             "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                             "domain": "Quality",
//                             "subdomain_id": "d7452919-bef6-498a-abf1-6ac26a3df0da",
//                             "subdomain": "Quality improvement",
//                             "mandatory_question": 21,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 606,
//                             "is_active": 1,
//                             "competency": "Quality improvement",
//                             "domain_seqeunce": 7,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "deda2c37-8bb6-48b2-bdf3-aefa3033db5e",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "902cf868-6021-443a-b5f6-22ffd6141f1d",
//                             "question_text": "<p>Confirm that corrective and preventive actions were carried out in compliance with established deviations and change controls.<\/p>",
//                             "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                             "domain": "Quality",
//                             "subdomain_id": "d7452919-bef6-498a-abf1-6ac26a3df0da",
//                             "subdomain": "Quality improvement",
//                             "mandatory_question": 21,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 607,
//                             "is_active": 1,
//                             "competency": "Quality improvement",
//                             "domain_seqeunce": 7,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "e43ec490-5cfe-4f13-99b5-8484b16c15ac",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "fb6a61d3-697d-4563-a3ba-4ad19aa99f2b",
//                             "question_text": "<p>Collect customer and stakeholder feedback. <\/p>",
//                             "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                             "domain": "Quality",
//                             "subdomain_id": "d7452919-bef6-498a-abf1-6ac26a3df0da",
//                             "subdomain": "Quality improvement",
//                             "mandatory_question": 21,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 617,
//                             "is_active": 1,
//                             "competency": "Quality improvement",
//                             "domain_seqeunce": 7,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "ece0e421-8afc-4ba5-9065-bd02c4f6c957",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "34e05f72-bd91-49aa-87a7-a9031dc13701",
//                             "question_text": "<p>Develop and implement customer and stakeholder feedback methods and processes.<\/p>",
//                             "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                             "domain": "Quality",
//                             "subdomain_id": "d7452919-bef6-498a-abf1-6ac26a3df0da",
//                             "subdomain": "Quality improvement",
//                             "mandatory_question": 21,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 614,
//                             "is_active": 1,
//                             "competency": "Quality improvement",
//                             "domain_seqeunce": 7,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "f2f09def-d41f-4268-8cb7-edb2b4ae7178",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "6dd9cfd0-1680-47ff-a11f-b079873e4e0e",
//                             "question_text": "<p>Help audit corrective action initiatives based on established acceptable deviations and change controls.<\/p>",
//                             "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                             "domain": "Quality",
//                             "subdomain_id": "d7452919-bef6-498a-abf1-6ac26a3df0da",
//                             "subdomain": "Quality improvement",
//                             "mandatory_question": 21,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 608,
//                             "is_active": 1,
//                             "competency": "Quality improvement",
//                             "domain_seqeunce": 7,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "fe8fcd7a-7f25-4bc9-93f7-611cd12c955b",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "9eb987db-1a2c-434b-a41a-cd39acbf4883",
//                             "question_text": "<p>Assist with root cause investigations and recommending corrective\/preventative actions.<\/p>",
//                             "domain_id": "8b0bc436-4d61-469a-8498-c3c6d59fb9a7",
//                             "domain": "Quality",
//                             "subdomain_id": "d7452919-bef6-498a-abf1-6ac26a3df0da",
//                             "subdomain": "Quality improvement",
//                             "mandatory_question": 21,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 616,
//                             "is_active": 1,
//                             "competency": "Quality improvement",
//                             "domain_seqeunce": 7,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         }
//                     ]
//                 },
//                 "Real Estate": {
//                     "Functional Programming\/Planning": [
//                         {
//                             "id": "0454ec68-0187-4fa7-83e6-c27a325b7ec1",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "e3102b47-1bf5-4080-9c13-b4dee354639f",
//                             "question_text": "<p>Review and approve space solutions that prioritize and focus on the organization's and stakeholder's strategic requirements enabling smooth operations and space flexibility based on best industry practices, employing Universal Design where applicable.<\/p>",
//                             "domain_id": "672a939f-344f-40eb-a897-c60987831da2",
//                             "domain": "Real Estate",
//                             "subdomain_id": "12824b8b-1a17-482a-9c97-0963708e386e",
//                             "subdomain": "Functional Programming\/Planning",
//                             "mandatory_question": 12,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 341,
//                             "is_active": 1,
//                             "competency": "Space design and planning",
//                             "domain_seqeunce": 8,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "0981b203-2b72-4fb6-8e66-1b9ada4b2d40",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "b5d84f37-0d08-4160-b278-2e993a9da8f7",
//                             "question_text": "<p>Manage the collection of relevant data to support the development of a real estate strategic plan, including space utilization, cost allocation, and so forth.<\/p>",
//                             "domain_id": "672a939f-344f-40eb-a897-c60987831da2",
//                             "domain": "Real Estate",
//                             "subdomain_id": "12824b8b-1a17-482a-9c97-0963708e386e",
//                             "subdomain": "Functional Programming\/Planning",
//                             "mandatory_question": 12,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 366,
//                             "is_active": 1,
//                             "competency": "Workplace Utilization",
//                             "domain_seqeunce": 8,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "0c0f3b96-a12d-432f-a989-8b54ba49eaff",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "797ae3ba-bf1c-4981-9041-4bca1104db6e",
//                             "question_text": "<p>Assist in collecting occupancy data required for reporting and making recommendations on how to support best use and portfolio optimization.<\/p>",
//                             "domain_id": "672a939f-344f-40eb-a897-c60987831da2",
//                             "domain": "Real Estate",
//                             "subdomain_id": "12824b8b-1a17-482a-9c97-0963708e386e",
//                             "subdomain": "Functional Programming\/Planning",
//                             "mandatory_question": 12,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 397,
//                             "is_active": 1,
//                             "competency": "Workplace Utilization",
//                             "domain_seqeunce": 8,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "169aa013-16ac-46a0-a3a7-ce05d25089b9",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "56fa0375-d590-4a6b-8b51-7815696eba91",
//                             "question_text": "<p>Collaborate with senior leadership to develop and oversee a real estate strategic master plan that manages the real estate portfolio consistent with the organization's master plan<\/p>",
//                             "domain_id": "672a939f-344f-40eb-a897-c60987831da2",
//                             "domain": "Real Estate",
//                             "subdomain_id": "12824b8b-1a17-482a-9c97-0963708e386e",
//                             "subdomain": "Functional Programming\/Planning",
//                             "mandatory_question": 12,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 365,
//                             "is_active": 1,
//                             "competency": "Workplace Utilization",
//                             "domain_seqeunce": 8,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "53518077-7cd3-4042-bbfc-949b81e22525",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "16bd763d-2224-411f-963c-32438ff40459",
//                             "question_text": "<p>Propose and recommend space solutions based on prioritized requirements that support efficient operations and space flexibility incorporating DEIB practices for privacy and equity (signage, services options, safe and inclusive spaces). Prioritize a process to continually review and revise policies and guidelines using best practices for inclusive utilization of facilities (public spaces in particular).<\/p>",
//                             "domain_id": "672a939f-344f-40eb-a897-c60987831da2",
//                             "domain": "Real Estate",
//                             "subdomain_id": "12824b8b-1a17-482a-9c97-0963708e386e",
//                             "subdomain": "Functional Programming\/Planning",
//                             "mandatory_question": 12,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 344,
//                             "is_active": 1,
//                             "competency": "Space design and planning",
//                             "domain_seqeunce": 8,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "58bd8079-4997-4434-9525-af4c5e8ff479",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "7cd05287-f448-4272-84b4-8bc7dacfc70f",
//                             "question_text": "<p>Manage space allocations, moves of departments, and the overall floor plan layout, to include tracking all facility projects<\/p>",
//                             "domain_id": "672a939f-344f-40eb-a897-c60987831da2",
//                             "domain": "Real Estate",
//                             "subdomain_id": "12824b8b-1a17-482a-9c97-0963708e386e",
//                             "subdomain": "Functional Programming\/Planning",
//                             "mandatory_question": 12,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 356,
//                             "is_active": 1,
//                             "competency": "Space management",
//                             "domain_seqeunce": 8,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "675cde6c-75f6-4b7e-b33d-a874bfad9393",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "0eabbb8f-9a09-4d8b-bac7-31de5585a81d",
//                             "question_text": "<p>Assist in collecting data relevant to support the development of a real estate strategic plan, including space utilization, cost allocation, and so forth.<\/p>",
//                             "domain_id": "672a939f-344f-40eb-a897-c60987831da2",
//                             "domain": "Real Estate",
//                             "subdomain_id": "12824b8b-1a17-482a-9c97-0963708e386e",
//                             "subdomain": "Functional Programming\/Planning",
//                             "mandatory_question": 12,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 371,
//                             "is_active": 1,
//                             "competency": "Workplace Utilization",
//                             "domain_seqeunce": 8,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "710f155a-f9c9-4aba-b422-0adb4024c7ac",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "e8798f8a-73b9-4a12-b480-5482bb604bcd",
//                             "question_text": "<p>Assist in managing space allocations, moves of departments, and the overall floor plan layout, to include tracking all facility projects<\/p>",
//                             "domain_id": "672a939f-344f-40eb-a897-c60987831da2",
//                             "domain": "Real Estate",
//                             "subdomain_id": "12824b8b-1a17-482a-9c97-0963708e386e",
//                             "subdomain": "Functional Programming\/Planning",
//                             "mandatory_question": 12,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 358,
//                             "is_active": 1,
//                             "competency": "Space management",
//                             "domain_seqeunce": 8,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "7eb0493c-a400-4a12-9ddd-6d50cbd08f72",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "ee6896f3-bd2d-4d35-91d3-7827dc71036d",
//                             "question_text": "<p>Oversee all facility projects to include the coordination of building space allocation, move management of departments, and overall floor plan layout<\/p>",
//                             "domain_id": "672a939f-344f-40eb-a897-c60987831da2",
//                             "domain": "Real Estate",
//                             "subdomain_id": "12824b8b-1a17-482a-9c97-0963708e386e",
//                             "subdomain": "Functional Programming\/Planning",
//                             "mandatory_question": 12,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 353,
//                             "is_active": 1,
//                             "competency": "Space management",
//                             "domain_seqeunce": 8,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "8fab8f2a-10b0-49e7-8533-901a087f54e2",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "5d4dbcae-3844-40ca-883d-587a19ef267c",
//                             "question_text": "<p>Assist in identifying space solutions based on prioritized requirements that support efficient operations and space flexibility.<\/p>",
//                             "domain_id": "672a939f-344f-40eb-a897-c60987831da2",
//                             "domain": "Real Estate",
//                             "subdomain_id": "12824b8b-1a17-482a-9c97-0963708e386e",
//                             "subdomain": "Functional Programming\/Planning",
//                             "mandatory_question": 12,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 348,
//                             "is_active": 1,
//                             "competency": "Space design and planning",
//                             "domain_seqeunce": 8,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "966247fb-211d-4e5e-94af-f2bb3c6b51da",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "2d2dc8a3-2566-4f83-abec-e747db4cdb51",
//                             "question_text": "<p>Manage the collection of occupancy data to develop reports and make recommendations on how to support best use and portfolio optimization<\/p>",
//                             "domain_id": "672a939f-344f-40eb-a897-c60987831da2",
//                             "domain": "Real Estate",
//                             "subdomain_id": "12824b8b-1a17-482a-9c97-0963708e386e",
//                             "subdomain": "Functional Programming\/Planning",
//                             "mandatory_question": 12,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 392,
//                             "is_active": 1,
//                             "competency": "Workplace Utilization",
//                             "domain_seqeunce": 8,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "9ca2a408-e9cd-486f-acb3-52e3b486a0b7",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "3d71a0a6-a9ca-4b12-96ce-b496d8877c82",
//                             "question_text": "<p>Make recommendations to leadership with the necessary metrics (for example, occupancy utilization) and industry best practices, if available, to support portfolio optimization<\/p>",
//                             "domain_id": "672a939f-344f-40eb-a897-c60987831da2",
//                             "domain": "Real Estate",
//                             "subdomain_id": "12824b8b-1a17-482a-9c97-0963708e386e",
//                             "subdomain": "Functional Programming\/Planning",
//                             "mandatory_question": 12,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 386,
//                             "is_active": 1,
//                             "competency": "Workplace Utilization",
//                             "domain_seqeunce": 8,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         }
//                     ],
//                     "Master Planning": [
//                         {
//                             "id": "0ef6b4f9-59e1-462d-bcff-a03648916941",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "df05c730-10d3-4d00-b003-2836bf633ef7",
//                             "question_text": "<p>Assist in making sure new and existing properties are in alignment with the organization's strategic plan, its portfolio strategy, and its governance policies.<\/p>",
//                             "domain_id": "672a939f-344f-40eb-a897-c60987831da2",
//                             "domain": "Real Estate",
//                             "subdomain_id": "e3da8b31-eebd-4143-b4c7-e6c71fc28057",
//                             "subdomain": "Master Planning",
//                             "mandatory_question": 15,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 280,
//                             "is_active": 1,
//                             "competency": "Real estate strategies",
//                             "domain_seqeunce": 8,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "13c1b5ab-9f23-476c-a105-02a2b16122b7",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "e41a1b11-2cc0-438f-841a-309f87701528",
//                             "question_text": "<p>Collect requirements and relevant data and conduct a needs analysis for new markets to find optimal partners and locations that align with strategy.<\/p>",
//                             "domain_id": "672a939f-344f-40eb-a897-c60987831da2",
//                             "domain": "Real Estate",
//                             "subdomain_id": "e3da8b31-eebd-4143-b4c7-e6c71fc28057",
//                             "subdomain": "Master Planning",
//                             "mandatory_question": 15,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 265,
//                             "is_active": 1,
//                             "competency": "Real estate strategies",
//                             "domain_seqeunce": 8,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "160b9849-ec6e-4197-bcd8-26e949947f14",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "89bae192-6858-47f7-930f-96846cbbee9b",
//                             "question_text": "<p>Manage the collection of relevant data to support the development of a real estate strategic plan, including space utilization, cost allocation, and so forth.<\/p>",
//                             "domain_id": "672a939f-344f-40eb-a897-c60987831da2",
//                             "domain": "Real Estate",
//                             "subdomain_id": "e3da8b31-eebd-4143-b4c7-e6c71fc28057",
//                             "subdomain": "Master Planning",
//                             "mandatory_question": 15,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 289,
//                             "is_active": 1,
//                             "competency": "Real Estate Master Plan",
//                             "domain_seqeunce": 8,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "1c98e7e1-29e4-421e-93e4-c5f6d91e8a13",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "178286a6-52db-44bb-9ba1-c86933beb9d2",
//                             "question_text": "<p>Recommend real estate decisions and strategies to senior management, such as stay vs go, buy vs lease, and so forth, to achieve the optimal terms and conditions required to meet the needs of the organization.<\/p>",
//                             "domain_id": "672a939f-344f-40eb-a897-c60987831da2",
//                             "domain": "Real Estate",
//                             "subdomain_id": "e3da8b31-eebd-4143-b4c7-e6c71fc28057",
//                             "subdomain": "Master Planning",
//                             "mandatory_question": 15,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 249,
//                             "is_active": 1,
//                             "competency": "Real estate strategies",
//                             "domain_seqeunce": 8,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "37410767-2e27-4b35-9f0f-d1b52bc218e5",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "732277e9-2b35-4fa2-867f-2436bf91e363",
//                             "question_text": "<p>Oversee the process for developing strategies for managing new and existing properties so they are in alignment with  the organization's strategic plan.<\/p>",
//                             "domain_id": "672a939f-344f-40eb-a897-c60987831da2",
//                             "domain": "Real Estate",
//                             "subdomain_id": "e3da8b31-eebd-4143-b4c7-e6c71fc28057",
//                             "subdomain": "Master Planning",
//                             "mandatory_question": 15,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 275,
//                             "is_active": 1,
//                             "competency": "Real estate strategies",
//                             "domain_seqeunce": 8,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "4c601109-dcf1-4ebd-88ee-2c3233c86bb4",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "a3573fc1-8ded-4184-94c7-7bd547e707ce",
//                             "question_text": "<p>Confirm ongoing maintenance and repair requirements are considered and needs are integrated into plans for renovations, expansions, and new site projects to support the organization's vision\/desired outcomes (such as sufficient storage, design for maintainability, and so forth).<\/p>",
//                             "domain_id": "672a939f-344f-40eb-a897-c60987831da2",
//                             "domain": "Real Estate",
//                             "subdomain_id": "e3da8b31-eebd-4143-b4c7-e6c71fc28057",
//                             "subdomain": "Master Planning",
//                             "mandatory_question": 15,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 282,
//                             "is_active": 1,
//                             "competency": "Real estate strategies",
//                             "domain_seqeunce": 8,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "828ef063-c792-413f-af08-b765705aa4e6",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "d0aab615-a8c1-4b5e-8d89-2397a2cee0a0",
//                             "question_text": "<p>Assist in collecting data relevant to support the development of a real estate strategic plan, including space utilization, cost allocation, and so forth.<\/p>",
//                             "domain_id": "672a939f-344f-40eb-a897-c60987831da2",
//                             "domain": "Real Estate",
//                             "subdomain_id": "e3da8b31-eebd-4143-b4c7-e6c71fc28057",
//                             "subdomain": "Master Planning",
//                             "mandatory_question": 15,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 296,
//                             "is_active": 1,
//                             "competency": "Real Estate Master Plan",
//                             "domain_seqeunce": 8,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "9c61e8bf-7888-4d58-93b7-7509c2dc2e88",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "fd215291-1aeb-4db7-a9f7-b04c14ccb70c",
//                             "question_text": "<p>Create and recommend a strategy for finding optimal partners and locations in new markets.<\/p>",
//                             "domain_id": "672a939f-344f-40eb-a897-c60987831da2",
//                             "domain": "Real Estate",
//                             "subdomain_id": "e3da8b31-eebd-4143-b4c7-e6c71fc28057",
//                             "subdomain": "Master Planning",
//                             "mandatory_question": 15,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 262,
//                             "is_active": 1,
//                             "competency": "Real estate strategies",
//                             "domain_seqeunce": 8,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "b353cdc6-6280-48f6-a570-04bbeb8749d3",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "01b752e7-badf-4715-9c62-cb3bf8e4b894",
//                             "question_text": "<p>Provide information to leadership so that ongoing maintenance and repair requirements are considered and integrated into plans for renovations and expansions, and new site projects support the organization's vision\/desired outcomes (such as, sufficient storage, design for maintainability, and so forth).<\/p>",
//                             "domain_id": "672a939f-344f-40eb-a897-c60987831da2",
//                             "domain": "Real Estate",
//                             "subdomain_id": "e3da8b31-eebd-4143-b4c7-e6c71fc28057",
//                             "subdomain": "Master Planning",
//                             "mandatory_question": 15,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 286,
//                             "is_active": 1,
//                             "competency": "Real estate strategies",
//                             "domain_seqeunce": 8,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "b7947a6c-1017-42b3-9b6a-68404167d3ba",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "79912b10-627e-4427-a413-40422cf53b14",
//                             "question_text": "<p>Assist in collecting data relevant to new markets to find optimal partners and locations that align with strategy.<\/p>",
//                             "domain_id": "672a939f-344f-40eb-a897-c60987831da2",
//                             "domain": "Real Estate",
//                             "subdomain_id": "e3da8b31-eebd-4143-b4c7-e6c71fc28057",
//                             "subdomain": "Master Planning",
//                             "mandatory_question": 15,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 268,
//                             "is_active": 1,
//                             "competency": "Real estate strategies",
//                             "domain_seqeunce": 8,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "bcbf197c-9b3a-4b49-8dc4-6efb674d4c28",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "1795af3c-38bc-41ac-97cc-b9097105dc62",
//                             "question_text": "<p>Assist in collecting data for leadership as necessary so that ongoing maintenance and repair requirements are considered and integrated into plans for renovations and expansions, and new site projects support of the organization's vision\/desired outcomes (such as, sufficient storage, design for maintainability, and so forth).<\/p>",
//                             "domain_id": "672a939f-344f-40eb-a897-c60987831da2",
//                             "domain": "Real Estate",
//                             "subdomain_id": "e3da8b31-eebd-4143-b4c7-e6c71fc28057",
//                             "subdomain": "Master Planning",
//                             "mandatory_question": 15,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 287,
//                             "is_active": 1,
//                             "competency": "Real estate strategies",
//                             "domain_seqeunce": 8,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "c21b7db8-2ef2-40b3-8041-c5bfc3bfd2ad",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "fbb82b8c-b2e1-4cdb-811f-dbe12b3d7813",
//                             "question_text": "<p>Collaborate with all levels of the real estate portfolio team and manage the collection of relevant data that will support setting real estate priorities and developing strategies.<\/p>",
//                             "domain_id": "672a939f-344f-40eb-a897-c60987831da2",
//                             "domain": "Real Estate",
//                             "subdomain_id": "e3da8b31-eebd-4143-b4c7-e6c71fc28057",
//                             "subdomain": "Master Planning",
//                             "mandatory_question": 15,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 257,
//                             "is_active": 1,
//                             "competency": "Real estate strategies",
//                             "domain_seqeunce": 8,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "cd28f603-c5f5-48c7-b40b-1114c825bb9b",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "6ff303ef-a778-4b54-9a09-7835c935257d",
//                             "question_text": "<p>Assist in collecting data relevant to developing real estate strategies as needed.<\/p>",
//                             "domain_id": "672a939f-344f-40eb-a897-c60987831da2",
//                             "domain": "Real Estate",
//                             "subdomain_id": "e3da8b31-eebd-4143-b4c7-e6c71fc28057",
//                             "subdomain": "Master Planning",
//                             "mandatory_question": 15,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 259,
//                             "is_active": 1,
//                             "competency": "Real estate strategies",
//                             "domain_seqeunce": 8,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "d7db276a-37c6-4582-aa28-96c25a1f7c3c",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "4cc343ec-aa73-4f99-be68-743b5e988a3a",
//                             "question_text": "<p>Collaborate with senior leadership to develop and oversee a real estate strategic master plan that manages the real estate portfolio consistent with the organization's master plan.<\/p>",
//                             "domain_id": "672a939f-344f-40eb-a897-c60987831da2",
//                             "domain": "Real Estate",
//                             "subdomain_id": "e3da8b31-eebd-4143-b4c7-e6c71fc28057",
//                             "subdomain": "Master Planning",
//                             "mandatory_question": 15,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 288,
//                             "is_active": 1,
//                             "competency": "Real Estate Master Plan",
//                             "domain_seqeunce": 8,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "e2ead091-fcfa-4478-8dd1-b14670f97613",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "95c4eb4f-cbda-43c9-ad03-e7ec5d540eda",
//                             "question_text": "<p>Confirm new and existing properties are in alignment with the organization's strategic plan, portfolio strategy, and its governance policies.<\/p>",
//                             "domain_id": "672a939f-344f-40eb-a897-c60987831da2",
//                             "domain": "Real Estate",
//                             "subdomain_id": "e3da8b31-eebd-4143-b4c7-e6c71fc28057",
//                             "subdomain": "Master Planning",
//                             "mandatory_question": 15,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 277,
//                             "is_active": 1,
//                             "competency": "Real estate strategies",
//                             "domain_seqeunce": 8,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         }
//                     ],
//                     "Real Estate Management": [
//                         {
//                             "id": "1051b2d1-960a-4fd0-a9d3-fa79b9dd523f",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "06fba511-e0a7-4240-b3b8-036d9d48d6b5",
//                             "question_text": "<p>Assist in keeping property inspections plans complete and accurate, including the scope and frequency of the inspections, so facility master plans, budgets, and capital plans are updated as necessary.<\/p>",
//                             "domain_id": "672a939f-344f-40eb-a897-c60987831da2",
//                             "domain": "Real Estate",
//                             "subdomain_id": "a489498c-e604-4451-965a-84bd5b2e601f",
//                             "subdomain": "Real Estate Management",
//                             "mandatory_question": 11,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 321,
//                             "is_active": 1,
//                             "competency": "Property Inspections",
//                             "domain_seqeunce": 8,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "1f0b3573-30b9-4f18-8b9c-4c6fd96f3fd3",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "c536a3c5-e762-4075-8a0f-22cb9cabc605",
//                             "question_text": "<p>Participate in developing property inspection plans and updating the plans' scope and frequency so facility master plans, budgets, and capital plans are kept current as necessary. <\/p>",
//                             "domain_id": "672a939f-344f-40eb-a897-c60987831da2",
//                             "domain": "Real Estate",
//                             "subdomain_id": "a489498c-e604-4451-965a-84bd5b2e601f",
//                             "subdomain": "Real Estate Management",
//                             "mandatory_question": 11,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 316,
//                             "is_active": 1,
//                             "competency": "Property Inspections",
//                             "domain_seqeunce": 8,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "26e22fe0-bb2e-4b0e-b6cf-832405bb1e2c",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "5ff485ac-75e2-4feb-929a-092d85034a86",
//                             "question_text": "<p>Make sure landlords are meeting all physical and financial lease obligations of the property (for example, O&amp;M, health, safety and security).<\/p>",
//                             "domain_id": "672a939f-344f-40eb-a897-c60987831da2",
//                             "domain": "Real Estate",
//                             "subdomain_id": "a489498c-e604-4451-965a-84bd5b2e601f",
//                             "subdomain": "Real Estate Management",
//                             "mandatory_question": 11,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 328,
//                             "is_active": 1,
//                             "competency": "Lease management",
//                             "domain_seqeunce": 8,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "3121c6d4-ce2f-4286-b349-e776f0404158",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "efe3184a-b96c-4342-a595-192741ecb3d3",
//                             "question_text": "<p>Manage real estate improvement plans so the maximum asset value is achieved.<\/p>",
//                             "domain_id": "672a939f-344f-40eb-a897-c60987831da2",
//                             "domain": "Real Estate",
//                             "subdomain_id": "a489498c-e604-4451-965a-84bd5b2e601f",
//                             "subdomain": "Real Estate Management",
//                             "mandatory_question": 11,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 300,
//                             "is_active": 1,
//                             "competency": "Asset management",
//                             "domain_seqeunce": 8,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "5a2ede81-8f6f-40f6-adfb-026337f91871",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "73318a45-1a66-433b-a8e3-a9402fd722f8",
//                             "question_text": "<p>Monitor and report if landlords are or are not meeting all physical and financial lease obligations of the property, provide feedback, and advise if action is needed.<\/p>",
//                             "domain_id": "672a939f-344f-40eb-a897-c60987831da2",
//                             "domain": "Real Estate",
//                             "subdomain_id": "a489498c-e604-4451-965a-84bd5b2e601f",
//                             "subdomain": "Real Estate Management",
//                             "mandatory_question": 11,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 331,
//                             "is_active": 1,
//                             "competency": "Lease management",
//                             "domain_seqeunce": 8,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "6517a7db-bb14-4e8c-9d2a-b0efe0d022b1",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "accbe8b2-9cbf-4ad3-9084-a5314911d40e",
//                             "question_text": "<p>Oversee the development and execution of property inspections plans and update the facility master plan, budgets, and capital plan as necessary.<\/p>",
//                             "domain_id": "672a939f-344f-40eb-a897-c60987831da2",
//                             "domain": "Real Estate",
//                             "subdomain_id": "a489498c-e604-4451-965a-84bd5b2e601f",
//                             "subdomain": "Real Estate Management",
//                             "mandatory_question": 11,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 313,
//                             "is_active": 1,
//                             "competency": "Property Inspections",
//                             "domain_seqeunce": 8,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "a36ca858-aa09-424b-abd7-91ca5ca2590f",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "eb5519bd-46c0-4efa-905e-82f2717aca8e",
//                             "question_text": "<p>Implement the due diligence process related to acquiring new properties, the redevelopment of properties, and value add strategies.<\/p>",
//                             "domain_id": "672a939f-344f-40eb-a897-c60987831da2",
//                             "domain": "Real Estate",
//                             "subdomain_id": "a489498c-e604-4451-965a-84bd5b2e601f",
//                             "subdomain": "Real Estate Management",
//                             "mandatory_question": 11,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 307,
//                             "is_active": 1,
//                             "competency": "Due diligence",
//                             "domain_seqeunce": 8,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "b51a78ae-38b8-47f6-827c-1a1089bea118",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "7712edfe-ec50-49d3-a502-1f3162ddd3c8",
//                             "question_text": "<p>Lead and manage the due diligence process of new property acquisitions and disposition, redevelopments, and value add strategies.<\/p>",
//                             "domain_id": "672a939f-344f-40eb-a897-c60987831da2",
//                             "domain": "Real Estate",
//                             "subdomain_id": "a489498c-e604-4451-965a-84bd5b2e601f",
//                             "subdomain": "Real Estate Management",
//                             "mandatory_question": 11,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 303,
//                             "is_active": 1,
//                             "competency": "Due diligence",
//                             "domain_seqeunce": 8,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "c725cb6e-e0a9-4880-9e30-1fd427e0bd26",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "245f299a-f408-4f7d-979f-4be780dd1b13",
//                             "question_text": "<p>Assist in monitoring and reporting if landlords' are or are not meeting all physical and financial lease obligations of the property, provide feedback, and advise if action is needed.<\/p>",
//                             "domain_id": "672a939f-344f-40eb-a897-c60987831da2",
//                             "domain": "Real Estate",
//                             "subdomain_id": "a489498c-e604-4451-965a-84bd5b2e601f",
//                             "subdomain": "Real Estate Management",
//                             "mandatory_question": 11,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 336,
//                             "is_active": 1,
//                             "competency": "Lease management",
//                             "domain_seqeunce": 8,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "ee16c61c-9bad-44e6-b029-57b35586863c",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "067cad86-658f-4edb-b425-1b3ab048078e",
//                             "question_text": "<p> Participate in the due diligence process of new property acquisitions, redevelopments, and value add strategies.<\/p>",
//                             "domain_id": "672a939f-344f-40eb-a897-c60987831da2",
//                             "domain": "Real Estate",
//                             "subdomain_id": "a489498c-e604-4451-965a-84bd5b2e601f",
//                             "subdomain": "Real Estate Management",
//                             "mandatory_question": 11,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 309,
//                             "is_active": 1,
//                             "competency": "Due diligence",
//                             "domain_seqeunce": 8,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "ff1fcd1f-8e77-46f6-95e8-1326103b36f6",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "0ce16523-4f78-4945-a515-6d1b2b66e6d8",
//                             "question_text": "<p>Oversee real estate improvement plans to ensure maximum asset value.<\/p>",
//                             "domain_id": "672a939f-344f-40eb-a897-c60987831da2",
//                             "domain": "Real Estate",
//                             "subdomain_id": "a489498c-e604-4451-965a-84bd5b2e601f",
//                             "subdomain": "Real Estate Management",
//                             "mandatory_question": 11,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 298,
//                             "is_active": 1,
//                             "competency": "Asset management",
//                             "domain_seqeunce": 8,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         }
//                     ]
//                 },
//                 "Facility Technology & Data Management": {
//                     "Information protection & cyber-security": [
//                         {
//                             "id": "0225afe6-2304-4aaf-937c-be31240cb22c",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "9e5942a5-819a-4060-993c-c57d6e3504bd",
//                             "question_text": "<p><span style=\"font-size: 14pt; font-family: Calibri, Arial; font-weight: normal; font-style: normal;\">Participate in the implementation of information protection and cyber-security policies and procedures based on risk assessments (policies may come from IT).<\/span><\/p>",
//                             "domain_id": "ee3222f0-84a2-42a6-bf79-db378189fc74",
//                             "domain": "Facility Technology & Data Management",
//                             "subdomain_id": "635a4dda-bf5d-4f7d-aeb4-5d5b3f62e90d",
//                             "subdomain": "Information protection & cyber-security",
//                             "mandatory_question": 20,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 133,
//                             "is_active": 1,
//                             "competency": "Cybersecurity in FM & Facility Operations",
//                             "domain_seqeunce": 9,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "0d43735f-debe-4826-ab17-ce191f54a07e",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "d101fad6-e7eb-4917-8b61-21d94f260fea",
//                             "question_text": "<p>Conduct cybersecurity risk assessments for building systems, including inventory of critical assets, and identify vulnerable systems.<\/p>",
//                             "domain_id": "ee3222f0-84a2-42a6-bf79-db378189fc74",
//                             "domain": "Facility Technology & Data Management",
//                             "subdomain_id": "635a4dda-bf5d-4f7d-aeb4-5d5b3f62e90d",
//                             "subdomain": "Information protection & cyber-security",
//                             "mandatory_question": 20,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 422,
//                             "is_active": 1,
//                             "competency": "Cybersecurity Threat Identification, Risk Assessment and Mitigation",
//                             "domain_seqeunce": 9,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "16739ca1-7642-40f9-bc3b-398a306ba379",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "a2623a4e-a038-4c44-8fe1-dfefe07df2b0",
//                             "question_text": "<p>Participate in the performance of cybersecurity risk assessments for building systems, including inventory of critical assets, and identify vulnerable systems with applicable cybersecurity penetration tests.<\/p>",
//                             "domain_id": "ee3222f0-84a2-42a6-bf79-db378189fc74",
//                             "domain": "Facility Technology & Data Management",
//                             "subdomain_id": "635a4dda-bf5d-4f7d-aeb4-5d5b3f62e90d",
//                             "subdomain": "Information protection & cyber-security",
//                             "mandatory_question": 20,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 426,
//                             "is_active": 1,
//                             "competency": "Cybersecurity Threat Identification, Risk Assessment and Mitigation",
//                             "domain_seqeunce": 9,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "220492ab-2869-4662-add9-1c38efd3af9b",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "aab20755-c43d-40dd-a501-2f7b4574f50b",
//                             "question_text": "<p><span style=\"font-size: 14pt; font-family: Calibri, Arial; font-weight: normal; font-style: normal;\">Assist in the incorporation of cybersecurity requirements into lease language and occupancy agreements for systems, subsystems, sensors, and other component devices.<\/span><\/p>",
//                             "domain_id": "ee3222f0-84a2-42a6-bf79-db378189fc74",
//                             "domain": "Facility Technology & Data Management",
//                             "subdomain_id": "635a4dda-bf5d-4f7d-aeb4-5d5b3f62e90d",
//                             "subdomain": "Information protection & cyber-security",
//                             "mandatory_question": 20,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 190,
//                             "is_active": 1,
//                             "competency": "Cybersecurity in FM & Facility Operations",
//                             "domain_seqeunce": 9,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "2b07ad02-4ede-49ad-9d5d-f7cc59a24f2e",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "57a0212b-3ee8-404c-81e1-45508bcc4c54",
//                             "question_text": "<p><span style=\"font-size: 14pt; font-family: Calibri, Arial; font-weight: normal; font-style: normal;\">Confirm cybersecurity requirements are appropriately addressed in contract procedures and requirements for long-term maintenance agreements.<\/span><\/p>",
//                             "domain_id": "ee3222f0-84a2-42a6-bf79-db378189fc74",
//                             "domain": "Facility Technology & Data Management",
//                             "subdomain_id": "635a4dda-bf5d-4f7d-aeb4-5d5b3f62e90d",
//                             "subdomain": "Information protection & cyber-security",
//                             "mandatory_question": 20,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 142,
//                             "is_active": 1,
//                             "competency": "Cybersecurity in FM & Facility Operations",
//                             "domain_seqeunce": 9,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "3e3dcb9b-9bfd-475d-97ea-884f9e4f60df",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "2d32109f-0d32-412a-bd3d-dbc65bc45b24",
//                             "question_text": "<p>Respond to cyber alerts, vulnerabilities, changes in system controls, and incident responses regarding threats to the cybersecurity of systems, subsystems, sensors, and other component devices.<\/p>",
//                             "domain_id": "ee3222f0-84a2-42a6-bf79-db378189fc74",
//                             "domain": "Facility Technology & Data Management",
//                             "subdomain_id": "635a4dda-bf5d-4f7d-aeb4-5d5b3f62e90d",
//                             "subdomain": "Information protection & cyber-security",
//                             "mandatory_question": 20,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 434,
//                             "is_active": 1,
//                             "competency": "Cybersecurity Threat Identification, Risk Assessment and Mitigation",
//                             "domain_seqeunce": 9,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "416c16f8-f67f-4306-812b-4528631f34af",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "af9ec289-1ab7-43fd-953a-9a57250ddf25",
//                             "question_text": "<p>Recognize cyber alerts, event logs, vulnerabilities, changes in system controls, and incident responses regarding threats to the cybersecurity of systems, subsystems, sensors, and other component devices.<\/p>",
//                             "domain_id": "ee3222f0-84a2-42a6-bf79-db378189fc74",
//                             "domain": "Facility Technology & Data Management",
//                             "subdomain_id": "635a4dda-bf5d-4f7d-aeb4-5d5b3f62e90d",
//                             "subdomain": "Information protection & cyber-security",
//                             "mandatory_question": 20,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 438,
//                             "is_active": 1,
//                             "competency": "Cybersecurity Threat Identification, Risk Assessment and Mitigation",
//                             "domain_seqeunce": 9,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "5058121d-2c83-428d-ae69-5dc1b6c5edac",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "9472e34b-50c9-4476-bf4c-b336267c0310",
//                             "question_text": "<p><span style=\"font-size: 14pt; font-family: Calibri, Arial; font-weight: normal; font-style: normal;\">Implement information protection and cyber-security policies and procedures that are based on risk assessments (policies may come from IT).<\/span><\/p>",
//                             "domain_id": "ee3222f0-84a2-42a6-bf79-db378189fc74",
//                             "domain": "Facility Technology & Data Management",
//                             "subdomain_id": "635a4dda-bf5d-4f7d-aeb4-5d5b3f62e90d",
//                             "subdomain": "Information protection & cyber-security",
//                             "mandatory_question": 20,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 126,
//                             "is_active": 1,
//                             "competency": "Cybersecurity in FM & Facility Operations",
//                             "domain_seqeunce": 9,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "758bef28-54e2-4600-bfd1-69bfbea42c36",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "152c0471-f12b-43fc-86c5-89ebc11e8f7b",
//                             "question_text": "<p>Make sure cybersecurity risk assessments are performed for building systems, all third party monitoring systems, including an inventory of critical assets, and the identification of vulnerable systems.<\/p>",
//                             "domain_id": "ee3222f0-84a2-42a6-bf79-db378189fc74",
//                             "domain": "Facility Technology & Data Management",
//                             "subdomain_id": "635a4dda-bf5d-4f7d-aeb4-5d5b3f62e90d",
//                             "subdomain": "Information protection & cyber-security",
//                             "mandatory_question": 20,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 420,
//                             "is_active": 1,
//                             "competency": "Cybersecurity Threat Identification, Risk Assessment and Mitigation",
//                             "domain_seqeunce": 9,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "78015107-7f42-4fc6-8f34-57c44181761d",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "19c6e88f-f5f6-48fe-b873-0911fa886366",
//                             "question_text": "<p>Participate in the monitoring of external vendors and contractors regarding compliance with cyber hygiene requirements.<\/p>",
//                             "domain_id": "ee3222f0-84a2-42a6-bf79-db378189fc74",
//                             "domain": "Facility Technology & Data Management",
//                             "subdomain_id": "635a4dda-bf5d-4f7d-aeb4-5d5b3f62e90d",
//                             "subdomain": "Information protection & cyber-security",
//                             "mandatory_question": 20,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 457,
//                             "is_active": 1,
//                             "competency": "Cybersecurity Threat Identification, Risk Assessment and Mitigation",
//                             "domain_seqeunce": 9,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "79c08906-535f-4bba-81d5-3c232946a98d",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "91ce1f41-9993-4ff7-a67a-1ad978334676",
//                             "question_text": "<p>Identify and report potential issues or irregularities  where conflicting or competing policies, standards, and regulations create vulnerabilities in facility technology systems.<\/p>",
//                             "domain_id": "ee3222f0-84a2-42a6-bf79-db378189fc74",
//                             "domain": "Facility Technology & Data Management",
//                             "subdomain_id": "635a4dda-bf5d-4f7d-aeb4-5d5b3f62e90d",
//                             "subdomain": "Information protection & cyber-security",
//                             "mandatory_question": 20,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 450,
//                             "is_active": 1,
//                             "competency": "Cybersecurity Threat Identification, Risk Assessment and Mitigation",
//                             "domain_seqeunce": 9,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "86cbfd29-19aa-4169-be36-be860637a8b6",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "473d1e87-dff4-4901-a907-c441895b1a15",
//                             "question_text": "<p>Confirm the effectiveness of responses to cyber alerts, vulnerabilities, changes in system controls, and incidents regarding threats to the cybersecurity of systems, subsystems, sensors, and other component devices. Analyze the effectiveness of cyber security penetration testing administered by IT.<\/p>",
//                             "domain_id": "ee3222f0-84a2-42a6-bf79-db378189fc74",
//                             "domain": "Facility Technology & Data Management",
//                             "subdomain_id": "635a4dda-bf5d-4f7d-aeb4-5d5b3f62e90d",
//                             "subdomain": "Information protection & cyber-security",
//                             "mandatory_question": 20,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 430,
//                             "is_active": 1,
//                             "competency": "Cybersecurity Threat Identification, Risk Assessment and Mitigation",
//                             "domain_seqeunce": 9,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "88e4ee2b-bdd2-4a89-acdf-7123788f3d5d",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "629ef691-75cc-445e-8c31-fd68f0b0f6f3",
//                             "question_text": "<p>Address and escalate, when necessary, issues where conflicting or competing policies, standards, and regulations create vulnerabilities in facility control systems.<\/p>",
//                             "domain_id": "ee3222f0-84a2-42a6-bf79-db378189fc74",
//                             "domain": "Facility Technology & Data Management",
//                             "subdomain_id": "635a4dda-bf5d-4f7d-aeb4-5d5b3f62e90d",
//                             "subdomain": "Information protection & cyber-security",
//                             "mandatory_question": 20,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 446,
//                             "is_active": 1,
//                             "competency": "Cybersecurity Threat Identification, Risk Assessment and Mitigation",
//                             "domain_seqeunce": 9,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "8a9314a5-d617-4613-ac1c-0413c729acb9",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "97cbed23-9d12-41f2-bc28-4fb43c8b23b3",
//                             "question_text": "<p><span style=\"font-size: 14pt; font-family: Calibri, Arial; font-weight: normal; font-style: normal;\">Confirm cybersecurity requirements are incorporated into lease language and occupancy agreements for systems, subsystems, sensors, and other component devices.<\/span><\/p>",
//                             "domain_id": "ee3222f0-84a2-42a6-bf79-db378189fc74",
//                             "domain": "Facility Technology & Data Management",
//                             "subdomain_id": "635a4dda-bf5d-4f7d-aeb4-5d5b3f62e90d",
//                             "subdomain": "Information protection & cyber-security",
//                             "mandatory_question": 20,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 180,
//                             "is_active": 1,
//                             "competency": "Cybersecurity in FM & Facility Operations",
//                             "domain_seqeunce": 9,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "8b1aedb1-15c6-40ce-a30d-f7be635a93ec",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "501fca81-3d73-486f-af75-f972ff560b7f",
//                             "question_text": "<p><span style=\"font-size: 14pt; font-family: Calibri, Arial; font-weight: normal; font-style: normal;\">Incorporate cybersecurity requirements into lease language and occupancy agreements for systems, subsystems, sensors, and other component devices.<\/span><\/p>",
//                             "domain_id": "ee3222f0-84a2-42a6-bf79-db378189fc74",
//                             "domain": "Facility Technology & Data Management",
//                             "subdomain_id": "635a4dda-bf5d-4f7d-aeb4-5d5b3f62e90d",
//                             "subdomain": "Information protection & cyber-security",
//                             "mandatory_question": 20,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 186,
//                             "is_active": 1,
//                             "competency": "Cybersecurity in FM & Facility Operations",
//                             "domain_seqeunce": 9,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "b5eefad3-bb84-4f77-9a68-a646175ebf0f",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "bc5284b9-8de3-4327-8e87-21bd779c1a09",
//                             "question_text": "<p><span style=\"font-size: 14pt; font-family: Calibri, Arial; font-weight: normal; font-style: normal;\">Assist in the incorporation of cybersecurity requirements in contract procedures and requirements for long-term maintenance agreements.<\/span><\/p>",
//                             "domain_id": "ee3222f0-84a2-42a6-bf79-db378189fc74",
//                             "domain": "Facility Technology & Data Management",
//                             "subdomain_id": "635a4dda-bf5d-4f7d-aeb4-5d5b3f62e90d",
//                             "subdomain": "Information protection & cyber-security",
//                             "mandatory_question": 20,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 157,
//                             "is_active": 1,
//                             "competency": "Cybersecurity in FM & Facility Operations",
//                             "domain_seqeunce": 9,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "b9a02cc1-670f-4f3e-9b78-70874e00efc9",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "65159f0d-b98e-47d3-a6b7-50a5af4f6614",
//                             "question_text": "<p><span style=\"font-size: 14pt; font-family: Calibri, Arial; font-weight: normal; font-style: normal;\">Address cybersecurity requirements in contract procedures and requirements for long-term maintenance agreements.<\/span><\/p>",
//                             "domain_id": "ee3222f0-84a2-42a6-bf79-db378189fc74",
//                             "domain": "Facility Technology & Data Management",
//                             "subdomain_id": "635a4dda-bf5d-4f7d-aeb4-5d5b3f62e90d",
//                             "subdomain": "Information protection & cyber-security",
//                             "mandatory_question": 20,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 151,
//                             "is_active": 1,
//                             "competency": "Cybersecurity in FM & Facility Operations",
//                             "domain_seqeunce": 9,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "d78e17e0-830c-4269-b6c5-713762e9e15f",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "74958ce7-0244-4eb8-8fa7-9586f8349154",
//                             "question_text": "<p><span style=\"font-size: 14pt; font-family: Calibri, Arial; font-weight: normal; font-style: normal;\">Make sure cybersecurity policies and procedures are implemented that protect information based on risk assessments (policies may come from IT).<\/span><\/p>",
//                             "domain_id": "ee3222f0-84a2-42a6-bf79-db378189fc74",
//                             "domain": "Facility Technology & Data Management",
//                             "subdomain_id": "635a4dda-bf5d-4f7d-aeb4-5d5b3f62e90d",
//                             "subdomain": "Information protection & cyber-security",
//                             "mandatory_question": 20,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 120,
//                             "is_active": 1,
//                             "competency": "Cybersecurity in FM & Facility Operations",
//                             "domain_seqeunce": 9,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "de2f28d1-d4a9-404a-9f10-adffd09d31d3",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "9599c4c8-8127-4eda-a635-933472ebb983",
//                             "question_text": "<p>Evaluate and mitigate issues where conflicting or competing policies, standards, and regulations create vulnerabilities in facility control systems.<\/p>",
//                             "domain_id": "ee3222f0-84a2-42a6-bf79-db378189fc74",
//                             "domain": "Facility Technology & Data Management",
//                             "subdomain_id": "635a4dda-bf5d-4f7d-aeb4-5d5b3f62e90d",
//                             "subdomain": "Information protection & cyber-security",
//                             "mandatory_question": 20,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 442,
//                             "is_active": 1,
//                             "competency": "Cybersecurity Threat Identification, Risk Assessment and Mitigation",
//                             "domain_seqeunce": 9,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "f51af087-a65a-49f5-ae4a-5110089816da",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "c1f4e488-0f42-4097-8f85-f5f2f0892d70",
//                             "question_text": "<p>Monitor external vendors and contractors regarding their compliance with cyber hygiene requirements.<\/p>",
//                             "domain_id": "ee3222f0-84a2-42a6-bf79-db378189fc74",
//                             "domain": "Facility Technology & Data Management",
//                             "subdomain_id": "635a4dda-bf5d-4f7d-aeb4-5d5b3f62e90d",
//                             "subdomain": "Information protection & cyber-security",
//                             "mandatory_question": 20,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 454,
//                             "is_active": 1,
//                             "competency": "Cybersecurity Threat Identification, Risk Assessment and Mitigation",
//                             "domain_seqeunce": 9,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         }
//                     ],
//                     "Data strategy and information management": [
//                         {
//                             "id": "043e9424-9f0a-4e41-9c9d-5f4d8ea80327",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "f0a24628-e4da-4709-9e47-4ef2c72e1b88",
//                             "question_text": "<p><span style=\"font-size: 14pt; font-family: Calibri, Arial; font-weight: normal; font-style: normal; color: rgb(0, 0, 0);\">Generate and analyze information through the use of FM technology that can be used to improve delivery of FM services and the built environment.<\/span><\/p>",
//                             "domain_id": "ee3222f0-84a2-42a6-bf79-db378189fc74",
//                             "domain": "Facility Technology & Data Management",
//                             "subdomain_id": "2b482725-9a69-4c18-8114-3889de74c9d6",
//                             "subdomain": "Data strategy and information management",
//                             "mandatory_question": 3,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 12,
//                             "is_active": 1,
//                             "competency": "Data collection & information management",
//                             "domain_seqeunce": 9,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "532008ba-6b0e-45a0-9c8f-b3b1fa372e46",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "de902db5-ce61-4cf1-99a3-ae6958789f49",
//                             "question_text": "<p><span style=\"font-size: 14pt; font-family: Calibri, Arial; font-weight: normal; font-style: normal; color: rgb(0, 0, 0);\">Confirm the requirements of the data structure and data integrity before the input of data into technology software to improve delivery of FM service.<\/span><\/p>",
//                             "domain_id": "ee3222f0-84a2-42a6-bf79-db378189fc74",
//                             "domain": "Facility Technology & Data Management",
//                             "subdomain_id": "2b482725-9a69-4c18-8114-3889de74c9d6",
//                             "subdomain": "Data strategy and information management",
//                             "mandatory_question": 3,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 20,
//                             "is_active": 1,
//                             "competency": "Data collection & information management",
//                             "domain_seqeunce": 9,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "917686b6-cb5e-4fd6-a40f-8aa0e31f983c",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "44424ce8-0d5b-49a5-92c1-eb19e3b39174",
//                             "question_text": "<p><span style=\"color: rgb(0, 0, 0); font-family: docs-Calibri; font-size: 19px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: left; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: pre-wrap; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; float: none;\">Track and trend information from FM technology and use as part of FM decision making.<\/span><\/p>",
//                             "domain_id": "ee3222f0-84a2-42a6-bf79-db378189fc74",
//                             "domain": "Facility Technology & Data Management",
//                             "subdomain_id": "2b482725-9a69-4c18-8114-3889de74c9d6",
//                             "subdomain": "Data strategy and information management",
//                             "mandatory_question": 3,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 6,
//                             "is_active": 1,
//                             "competency": "Data collection & information management",
//                             "domain_seqeunce": 9,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         }
//                     ],
//                     "Facility Technology": [
//                         {
//                             "id": "2ae6ae2b-270a-417e-b7a6-bdf21d32b1c5",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "e45efdcf-39b3-4320-8991-a207bf51c737",
//                             "question_text": "<p><span style=\"font-size: 14pt; font-family: Calibri, Arial; font-weight: normal; font-style: normal;\">Evaluate and recommend facility system upgrades, based on feedback, operational requirements, and the capabilities of new technologies for improved facility performance measured against established benchmarks.<\/span><\/p>",
//                             "domain_id": "ee3222f0-84a2-42a6-bf79-db378189fc74",
//                             "domain": "Facility Technology & Data Management",
//                             "subdomain_id": "99d522f2-4575-4915-b06a-03fa4dac7215",
//                             "subdomain": "Facility Technology",
//                             "mandatory_question": 6,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 90,
//                             "is_active": 1,
//                             "competency": "Life cycle management",
//                             "domain_seqeunce": 9,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "4f8ba07c-5095-4ea6-846d-bbb0891ac723",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "c3f2952b-b3f6-4c88-bcaa-924d0a3ec118",
//                             "question_text": "<p><span style=\"font-size: 14pt; font-family: Calibri, Arial; font-weight: normal; font-style: normal;\">Review, implement, test, and maintain technologies that would improve the delivery of FM services and the built environment<\/span><\/p>",
//                             "domain_id": "ee3222f0-84a2-42a6-bf79-db378189fc74",
//                             "domain": "Facility Technology & Data Management",
//                             "subdomain_id": "99d522f2-4575-4915-b06a-03fa4dac7215",
//                             "subdomain": "Facility Technology",
//                             "mandatory_question": 6,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 33,
//                             "is_active": 1,
//                             "competency": "FM Technology",
//                             "domain_seqeunce": 9,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "7e86c254-9502-4871-9863-acae877f6780",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "9cfc4ca6-60a6-4bbc-908e-06fc396f3013",
//                             "question_text": "<p><span style=\"font-size: 14pt; font-family: Calibri, Arial; font-weight: normal; font-style: normal;\">Participate in the implementation of FM technology systems including learning how to integrate the system into day-to-day work.<\/span><\/p>",
//                             "domain_id": "ee3222f0-84a2-42a6-bf79-db378189fc74",
//                             "domain": "Facility Technology & Data Management",
//                             "subdomain_id": "99d522f2-4575-4915-b06a-03fa4dac7215",
//                             "subdomain": "Facility Technology",
//                             "mandatory_question": 6,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 69,
//                             "is_active": 1,
//                             "competency": "FM Technology",
//                             "domain_seqeunce": 9,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "e590435c-f215-4e75-88e6-a371452af70d",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "be4f021c-b3d2-41d2-a3f4-2a77eb8d0f6a",
//                             "question_text": "<p><span style=\"font-size: 14pt; font-family: Calibri, Arial; font-weight: normal; font-style: normal;\">Provide input into requirements for facility technology system upgrades.<\/span><\/p>",
//                             "domain_id": "ee3222f0-84a2-42a6-bf79-db378189fc74",
//                             "domain": "Facility Technology & Data Management",
//                             "subdomain_id": "99d522f2-4575-4915-b06a-03fa4dac7215",
//                             "subdomain": "Facility Technology",
//                             "mandatory_question": 6,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 97,
//                             "is_active": 1,
//                             "competency": "Life cycle management",
//                             "domain_seqeunce": 9,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "ed7e20e0-d54b-40d9-9e07-037ab0aa9372",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "14cbe825-39b2-4ca2-b103-7bd9b55278c3",
//                             "question_text": "<p><span style=\"font-size: 14pt; font-family: Calibri, Arial; font-weight: normal; font-style: normal;\">Evaluate and recommend facility system upgrades, based on feedback, operational requirements, the capabilities of new technologies for improved facility performance and aligned with the enterprise's strategic plan.<\/span><\/p>",
//                             "domain_id": "ee3222f0-84a2-42a6-bf79-db378189fc74",
//                             "domain": "Facility Technology & Data Management",
//                             "subdomain_id": "99d522f2-4575-4915-b06a-03fa4dac7215",
//                             "subdomain": "Facility Technology",
//                             "mandatory_question": 6,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 83,
//                             "is_active": 1,
//                             "competency": "Life cycle management",
//                             "domain_seqeunce": 9,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "f55dbfec-dda6-49e5-9e07-b27f2e971658",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "be5f17f4-0473-4653-a8ca-4ca78156a3d9",
//                             "question_text": "<p><span style=\"font-size: 14pt; font-family: Calibri, Arial; font-weight: normal; font-style: normal;\">Analyze and propose technologies that would improve delivery of FM services\/built environment and support organizational goals and objectives.<\/span><\/p>",
//                             "domain_id": "ee3222f0-84a2-42a6-bf79-db378189fc74",
//                             "domain": "Facility Technology & Data Management",
//                             "subdomain_id": "99d522f2-4575-4915-b06a-03fa4dac7215",
//                             "subdomain": "Facility Technology",
//                             "mandatory_question": 6,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 28,
//                             "is_active": 1,
//                             "competency": "FM Technology",
//                             "domain_seqeunce": 9,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         }
//                     ]
//                 },
//                 "Project Management": {
//                     "Project Closeout": [
//                         {
//                             "id": "04cb7d2f-08e9-4dab-97ec-188f15e5162c",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "4780d952-7dcb-471f-944a-fccebae8d3d2",
//                             "question_text": "<p>Coordinate with external stakeholders to collect data and documents for project closeouts and handovers.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "6cf5a5fd-9b28-49da-99ce-d78925a104ab",
//                             "subdomain": "Project Closeout",
//                             "mandatory_question": 15,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 263,
//                             "is_active": 1,
//                             "competency": "Project closeout",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "0c3d2b4e-f409-47a4-be80-01124f5c7a24",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "4fdff6ee-0d07-453a-a2be-8ad7c52eef63",
//                             "question_text": "<p>Collect data in support of project evaluations and identify any challenges and potential process improvements.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "6cf5a5fd-9b28-49da-99ce-d78925a104ab",
//                             "subdomain": "Project Closeout",
//                             "mandatory_question": 15,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 284,
//                             "is_active": 1,
//                             "competency": "Process improvement",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "0f0bd4f6-7226-447a-a275-89cf61b7e71a",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "1ccb0e1c-0df2-426e-9534-0fadffc5b7c8",
//                             "question_text": "<p>Facilitate and document project evaluation activities such as lessons learned workshop, prepare case studies, and compare the project outcome with the project objective.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "6cf5a5fd-9b28-49da-99ce-d78925a104ab",
//                             "subdomain": "Project Closeout",
//                             "mandatory_question": 15,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 276,
//                             "is_active": 1,
//                             "competency": "Process improvement",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "14b4947c-18d9-4c6a-824a-d1a0dca2d4e1",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "eb57c215-8e90-443d-b969-3bb39084d199",
//                             "question_text": "<p>Create and analyze project evaluation report, and adopt findings and recommendations to improve the planning and execution of the next project.  Document lessons learned and benchmark project cost against similar projects.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "6cf5a5fd-9b28-49da-99ce-d78925a104ab",
//                             "subdomain": "Project Closeout",
//                             "mandatory_question": 15,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 281,
//                             "is_active": 1,
//                             "competency": "Process improvement",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "19c49bee-6017-437a-bf56-cdc6978b2466",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "182d5b7a-68aa-4cfc-9736-e58cfcd06995",
//                             "question_text": "<p>Perform an internal audit to ensure the project is properly closed out and complete with a sign-off on deliverables and documentation.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "6cf5a5fd-9b28-49da-99ce-d78925a104ab",
//                             "subdomain": "Project Closeout",
//                             "mandatory_question": 15,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 237,
//                             "is_active": 1,
//                             "competency": "Project closeout",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "1b8117e8-1af8-4355-931a-c550502df85a",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "0a801bbd-a99e-4503-a6b7-054240c21b76",
//                             "question_text": "<p>Review and analyze post-implementation and consultant and contractor evaluations following completion of projects; recommend required followup actions, and identify if consultants\/contractors remain on the qualification list to do work for FM.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "6cf5a5fd-9b28-49da-99ce-d78925a104ab",
//                             "subdomain": "Project Closeout",
//                             "mandatory_question": 15,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 273,
//                             "is_active": 1,
//                             "competency": "Process improvement",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "46021f1c-43c5-42ce-9953-6b1c1f38f18f",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "61de4934-72fd-4195-b18f-64c237270cff",
//                             "question_text": "<p>Assist consultants to administer the correction of defects and snagging\/punch lists.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "6cf5a5fd-9b28-49da-99ce-d78925a104ab",
//                             "subdomain": "Project Closeout",
//                             "mandatory_question": 15,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 267,
//                             "is_active": 1,
//                             "competency": "Project closeout",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "58d1f9f5-d133-40ee-843d-f8dc0fb4462b",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "89b7cb56-e72e-4847-8c5f-2c79db8487eb",
//                             "question_text": "<p>Assist with post implementation and consultant and contractor evaluations following the completion of projects.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "6cf5a5fd-9b28-49da-99ce-d78925a104ab",
//                             "subdomain": "Project Closeout",
//                             "mandatory_question": 15,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 283,
//                             "is_active": 1,
//                             "competency": "Process improvement",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "6745cc2d-71c3-4f0d-82fc-930291274189",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "70773f24-51f7-43f7-a127-6bdecb54fec1",
//                             "question_text": "<p>Monitor compliance on all regulatory required testing, inspection, and permit applications.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "6cf5a5fd-9b28-49da-99ce-d78925a104ab",
//                             "subdomain": "Project Closeout",
//                             "mandatory_question": 15,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 260,
//                             "is_active": 1,
//                             "competency": "Project closeout",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "6bcb06a9-8f39-4790-9b9c-6c5586c56f78",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "15eeb36d-5c47-4dfe-829d-76b43de4c720",
//                             "question_text": "<p>Ensure the project is properly closed out and handover includes use and occupancy permits, sign-off on deliverables and documentation, for example, record drawings, operations and maintenance, and warranties, certificate of completion.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "6cf5a5fd-9b28-49da-99ce-d78925a104ab",
//                             "subdomain": "Project Closeout",
//                             "mandatory_question": 15,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 246,
//                             "is_active": 1,
//                             "competency": "Project closeout",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "a4ad4213-fcd2-490d-8a8a-d7f72f489000",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "9e7e8fa9-4792-489b-96c0-fb382dc57f39",
//                             "question_text": "<p>Confirm occupancy permits are obtained before site occupancy.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "6cf5a5fd-9b28-49da-99ce-d78925a104ab",
//                             "subdomain": "Project Closeout",
//                             "mandatory_question": 15,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 241,
//                             "is_active": 1,
//                             "competency": "Project closeout",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "c56b29b9-4fb9-48d4-a821-c6d950d35f0f",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "5e71c99c-97a2-4c6d-8177-ece4b7f1c02d",
//                             "question_text": "<p>Sign off completion of snagging\/punch list and close the project finance account accordingly.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "6cf5a5fd-9b28-49da-99ce-d78925a104ab",
//                             "subdomain": "Project Closeout",
//                             "mandatory_question": 15,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 240,
//                             "is_active": 1,
//                             "competency": "Project closeout",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "e9adfa49-d101-45ac-9b6f-280932903b14",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "fee60149-cded-4fc1-9eb9-c29d9204d878",
//                             "question_text": "<p>Manage and ensure that defects are rectified and complete the snagging\/punch lists.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "6cf5a5fd-9b28-49da-99ce-d78925a104ab",
//                             "subdomain": "Project Closeout",
//                             "mandatory_question": 15,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 253,
//                             "is_active": 1,
//                             "competency": "Project closeout",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "e9d0135d-1e29-42fe-bc79-fce794351d10",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "a7cb971f-259d-4ad5-ac9e-b83be0290da9",
//                             "question_text": "<p>Coordinate and assist with permit inspections and close-outs.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "6cf5a5fd-9b28-49da-99ce-d78925a104ab",
//                             "subdomain": "Project Closeout",
//                             "mandatory_question": 15,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 269,
//                             "is_active": 1,
//                             "competency": "Project closeout",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "ff74a71a-ded0-4a20-a7bc-df171f3a7e30",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "cea24909-033f-4fed-8ab4-e9b96a8a114c",
//                             "question_text": "<p>Administer post-implementation and consultant and contractor evaluations following the completion of projects and identify the need for follow-up actions<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "6cf5a5fd-9b28-49da-99ce-d78925a104ab",
//                             "subdomain": "Project Closeout",
//                             "mandatory_question": 15,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 278,
//                             "is_active": 1,
//                             "competency": "Process improvement",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         }
//                     ],
//                     "Planning & Design": [
//                         {
//                             "id": "06ca5d95-2abd-436c-bde7-34681fedb8c5",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "eb356a9d-5844-4884-8cc6-e47445ac8946",
//                             "question_text": "<p>Assists in the development of project schedule, including phasing strategies, with a focus on operational impacts.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "998350dd-f1d0-470c-9a81-6c6ace822836",
//                             "subdomain": "Planning & Design",
//                             "mandatory_question": 33,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 18,
//                             "is_active": 1,
//                             "competency": "Project initiation planning",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "15f72905-efb7-447e-b977-4bb4299d558d",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "ce27332c-9657-4ece-aae7-086f66dc7103",
//                             "question_text": "<p>Determine best procurement method and criteria and Work with Procurement to ensure tendering of projects falls within all policies and trade agreements and meets project requirements.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "998350dd-f1d0-470c-9a81-6c6ace822836",
//                             "subdomain": "Planning & Design",
//                             "mandatory_question": 33,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 99,
//                             "is_active": 1,
//                             "competency": "Procurement",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "1ecc3076-0c58-4282-a3a8-d1c53d3699b7",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "78bf2ed2-6a85-439d-a306-4d4609434a1b",
//                             "question_text": "<p>Lead and facilitate user requirement brief, establish project requirement brief that is aligned with organizational requirements, culture and missions.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "998350dd-f1d0-470c-9a81-6c6ace822836",
//                             "subdomain": "Planning & Design",
//                             "mandatory_question": 33,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 4,
//                             "is_active": 1,
//                             "competency": "Project initiation planning",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "2553cda0-13da-4189-b8c5-481d75845474",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "9c7d084f-be8a-4732-9589-b257f378de93",
//                             "question_text": "<p>Establish and present criteria and framework of feasibility studies, including preparation of concept plans, cost-benefit study, and budget.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "998350dd-f1d0-470c-9a81-6c6ace822836",
//                             "subdomain": "Planning & Design",
//                             "mandatory_question": 33,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 2,
//                             "is_active": 1,
//                             "competency": "Project initiation planning",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "285c3483-a666-448a-b383-c30c8cd2f8af",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "9f1c5e91-94d4-4886-9ac0-ca819af2734a",
//                             "question_text": "<p>Provides expert advice during the creation of project documents.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "998350dd-f1d0-470c-9a81-6c6ace822836",
//                             "subdomain": "Planning & Design",
//                             "mandatory_question": 33,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 30,
//                             "is_active": 1,
//                             "competency": "Project resource planning",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "2a8daa6f-e067-40e0-9b71-b0e53e63a9fb",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "fb8ad431-c4c5-404e-b304-1a1f12ef622c",
//                             "question_text": "<p>Support project managers and architectural design team in the creation of project documents.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "998350dd-f1d0-470c-9a81-6c6ace822836",
//                             "subdomain": "Planning & Design",
//                             "mandatory_question": 33,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 35,
//                             "is_active": 1,
//                             "competency": "Project resource planning",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "40b0a9b0-5d51-4eac-a93b-087662af97a3",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "b338b1e0-d8ed-4b46-9d6f-d7b621522833",
//                             "question_text": "<p>Review contract documents with the consulting team and subject matter experts specific to codes and regulations.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "998350dd-f1d0-470c-9a81-6c6ace822836",
//                             "subdomain": "Planning & Design",
//                             "mandatory_question": 33,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 123,
//                             "is_active": 1,
//                             "competency": "Regulations",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "4679f4da-1419-468f-8967-21d885db69d0",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "7ee17352-c870-4fa8-a93b-d0b543ede48c",
//                             "question_text": "<p>Assist as required in the planning and execution of disruption mitigations for projects.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "998350dd-f1d0-470c-9a81-6c6ace822836",
//                             "subdomain": "Planning & Design",
//                             "mandatory_question": 33,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 66,
//                             "is_active": 1,
//                             "competency": "Risk management strategies",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "4f584e3a-8e78-4f58-83c1-d5e02cafff0b",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "9b5abda4-6624-4c00-a7c9-3fe301e8eb64",
//                             "question_text": "<p>Assist in the establishment and management of the project risk register and risk management plan.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "998350dd-f1d0-470c-9a81-6c6ace822836",
//                             "subdomain": "Planning & Design",
//                             "mandatory_question": 33,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 54,
//                             "is_active": 1,
//                             "competency": "Project risk management",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "51470ab4-8a94-4b3a-a3e2-6ba067782c2a",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "4fd68cfb-8925-4d4c-afbf-c4936f93556d",
//                             "question_text": "<p>Obtain a clear understanding of project scope from demand organization and project requirements and help develop the project charter.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "998350dd-f1d0-470c-9a81-6c6ace822836",
//                             "subdomain": "Planning & Design",
//                             "mandatory_question": 33,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 5,
//                             "is_active": 1,
//                             "competency": "Project initiation planning",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "53f54226-11a4-47fe-a266-42cf7333980a",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "cd4e23ff-8d6a-43ac-a412-e61e39656b6f",
//                             "question_text": "<p>oversee the implementation of project mitigation plans to minimize disruptions to stakeholders.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "998350dd-f1d0-470c-9a81-6c6ace822836",
//                             "subdomain": "Planning & Design",
//                             "mandatory_question": 33,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 62,
//                             "is_active": 1,
//                             "competency": "Risk management strategies",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "55f24930-5196-4b59-8cfd-c634b800d9e2",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "263200a9-990e-474c-bfde-98948fdfd3b2",
//                             "question_text": "<p>Define and manage risk-based thinking and methodology to avoid and mitigate project-related risk.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "998350dd-f1d0-470c-9a81-6c6ace822836",
//                             "subdomain": "Planning & Design",
//                             "mandatory_question": 33,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 48,
//                             "is_active": 1,
//                             "competency": "Project risk management",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "58ccc241-a38b-4bf7-8a1c-7498e4cfb786",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "36405a00-4056-4c87-9d3d-8fdda1c5f23e",
//                             "question_text": "<p>Confirm contract documents as produced by the consulting team have met all standards, codes, and regulations.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "998350dd-f1d0-470c-9a81-6c6ace822836",
//                             "subdomain": "Planning & Design",
//                             "mandatory_question": 33,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 118,
//                             "is_active": 1,
//                             "competency": "Regulations",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "6a472c69-d0a8-4b3d-bbc4-3695fe347877",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "ea030369-9574-4a05-a971-8b9e5603c034",
//                             "question_text": "<p>Collect preliminary cost data to support budget creation.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "998350dd-f1d0-470c-9a81-6c6ace822836",
//                             "subdomain": "Planning & Design",
//                             "mandatory_question": 33,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 85,
//                             "is_active": 1,
//                             "competency": "Budget planning",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "6c7f5c63-f58a-4cb9-ab15-bf94811a135a",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "e0c71fa9-b6c2-462e-91f3-4e48ec7197c7",
//                             "question_text": "<p>Observe, identify, document, and report environmental and safety hazards as related to project requirement.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "998350dd-f1d0-470c-9a81-6c6ace822836",
//                             "subdomain": "Planning & Design",
//                             "mandatory_question": 33,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 113,
//                             "is_active": 1,
//                             "competency": "Environmental hazards",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "7b1921d3-a2e2-48f5-9cd2-17036bedc840",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "7bd2aa21-c9b1-4e92-9015-ffba9289af5a",
//                             "question_text": "<p>Assist and support the development of feasibility studies related to new projects.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "998350dd-f1d0-470c-9a81-6c6ace822836",
//                             "subdomain": "Planning & Design",
//                             "mandatory_question": 33,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 14,
//                             "is_active": 1,
//                             "competency": "Project initiation planning",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "8f88e877-cbb6-4d6f-bf4a-f1e737c989d5",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "60059541-ff8d-45c4-a4fd-be56cbc6ca04",
//                             "question_text": "<p>Engage and oversee the performance of environmental adn safety hazard management as related to project requirements.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "998350dd-f1d0-470c-9a81-6c6ace822836",
//                             "subdomain": "Planning & Design",
//                             "mandatory_question": 33,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 110,
//                             "is_active": 1,
//                             "competency": "Environmental hazards",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "92556de0-d7f8-41ab-acf6-b97d36ce6ab1",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "87ede36a-3cff-4e23-8021-923a8020ff51",
//                             "question_text": "<p>Approves project risk mitigation plans and strategies.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "998350dd-f1d0-470c-9a81-6c6ace822836",
//                             "subdomain": "Planning & Design",
//                             "mandatory_question": 33,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 58,
//                             "is_active": 1,
//                             "competency": "Risk management strategies",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "9b722e95-db43-4359-95b9-b845007c3f3b",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "e8768fa6-6466-4ba2-8d6f-aab100b03fbc",
//                             "question_text": "<p>Ensure that the proposed project schedule minimizes operational impacts on other stakeholders.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "998350dd-f1d0-470c-9a81-6c6ace822836",
//                             "subdomain": "Planning & Design",
//                             "mandatory_question": 33,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 3,
//                             "is_active": 1,
//                             "competency": "Project initiation planning",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "aa607c13-c1ab-4050-807b-4aacdc1dc0c0",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "0a147ffc-4504-4e54-91b3-193f014cf4e9",
//                             "question_text": "<p>Establish and manage a project risk register and risk management plan to address and mitigate the risk including the review of the safety plan.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "998350dd-f1d0-470c-9a81-6c6ace822836",
//                             "subdomain": "Planning & Design",
//                             "mandatory_question": 33,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 51,
//                             "is_active": 1,
//                             "competency": "Project risk management",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "ac6b867e-a072-4c14-bfe2-a8b1d90ddea2",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "5583bd9e-0b28-4811-8b54-4189275328e9",
//                             "question_text": "<p>Conduct feasibility studies to assess new projects, including preparation of concept plans, cost-benefit study, programs, and performance specifications.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "998350dd-f1d0-470c-9a81-6c6ace822836",
//                             "subdomain": "Planning & Design",
//                             "mandatory_question": 33,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 7,
//                             "is_active": 1,
//                             "competency": "Project initiation planning",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "af0cbe65-7619-48bd-9ab9-41ed2359c8b5",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "c82a8e5a-0137-4d7f-8101-5b84280b1a53",
//                             "question_text": "<p>Assist with developing project scope for procurement.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "998350dd-f1d0-470c-9a81-6c6ace822836",
//                             "subdomain": "Planning & Design",
//                             "mandatory_question": 33,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 102,
//                             "is_active": 1,
//                             "competency": "Procurement",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "af1b3dae-e7d9-4234-bcc5-0430da06604e",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "c606116c-97c2-45a1-9f5f-e327f6bc94e2",
//                             "question_text": "<p>Assist in the review of designs, drawings, submittals, and approvals for all projects.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "998350dd-f1d0-470c-9a81-6c6ace822836",
//                             "subdomain": "Planning & Design",
//                             "mandatory_question": 33,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 38,
//                             "is_active": 1,
//                             "competency": "Project resource planning",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "b1cd1ba8-36dd-47fb-8f85-14fff8d53eb2",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "ef190d24-d70b-429f-99c4-56ed57c82e42",
//                             "question_text": "<p>Develop of project schedule, including phasing strategies, with a focus on operational impacts and establish the frequency of schedule updates.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "998350dd-f1d0-470c-9a81-6c6ace822836",
//                             "subdomain": "Planning & Design",
//                             "mandatory_question": 33,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 8,
//                             "is_active": 1,
//                             "competency": "Project initiation planning",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "b8bc3208-4949-4b3f-8432-181fd77cfd81",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "ef054ce3-5579-48cd-b5e1-1feb166706f8",
//                             "question_text": "<p>Be aware of codes, regulations, and available resources specific to projects.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "998350dd-f1d0-470c-9a81-6c6ace822836",
//                             "subdomain": "Planning & Design",
//                             "mandatory_question": 33,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 128,
//                             "is_active": 1,
//                             "competency": "Regulations",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "b99f7594-6ffe-4d71-874c-fe6f6cce64c9",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "798b5c4f-6d83-4801-8867-540c33af735c",
//                             "question_text": "<p>Confirm the project scope aligns with FM standards. Balance project scope, budget, schedule, and create a project charter. Manage project governance charter to ensure the project execution and delivery per plan.1<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "998350dd-f1d0-470c-9a81-6c6ace822836",
//                             "subdomain": "Planning & Design",
//                             "mandatory_question": 33,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 1,
//                             "is_active": 1,
//                             "competency": "Project initiation planning",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "bc168a3c-b5ab-419a-9ff1-5c088aee88c9",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "44cec419-e854-4829-842c-ec7b766de7a2",
//                             "question_text": "<p>Confirm that the project requirements include appropriate mitigation strategies for environmental risks applicable to the area of work<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "998350dd-f1d0-470c-9a81-6c6ace822836",
//                             "subdomain": "Planning & Design",
//                             "mandatory_question": 33,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 106,
//                             "is_active": 1,
//                             "competency": "Environmental hazards",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "c2ff96b9-2dde-445f-b58f-985f8eb04fdc",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "6b5145dc-8481-420e-82eb-45253f675426",
//                             "question_text": "<p>Gather facility-related information for stakeholders for a project, for example, but not limited to as-builts, plans, surveys, Geotech\/environmental, investigation reports, water pressure, and forth.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "998350dd-f1d0-470c-9a81-6c6ace822836",
//                             "subdomain": "Planning & Design",
//                             "mandatory_question": 33,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 11,
//                             "is_active": 1,
//                             "competency": "Project initiation planning",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "d3d8df6c-0eca-45af-8bd1-5af94b1d8e51",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "cec3d688-fa94-4a94-a979-88ad5b15dc24",
//                             "question_text": "<p>Analyze market data and approve provided cost estimates after stakeholder input and present to demand organization<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "998350dd-f1d0-470c-9a81-6c6ace822836",
//                             "subdomain": "Planning & Design",
//                             "mandatory_question": 33,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 76,
//                             "is_active": 1,
//                             "competency": "Budget planning",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "e1918064-d46f-435a-b22a-1aad79488d05",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "b37645cc-c5bd-4590-9f0f-c4afdc03f8a1",
//                             "question_text": "<p>Ensure that project processes adhere to the demand organization's purchasing policies and procedures.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "998350dd-f1d0-470c-9a81-6c6ace822836",
//                             "subdomain": "Planning & Design",
//                             "mandatory_question": 33,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 93,
//                             "is_active": 1,
//                             "competency": "Procurement",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "e9bd0e1b-551a-4128-84a6-19199ca7012c",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "6bbfb5a1-a85a-461b-a7b7-5d840a61bca7",
//                             "question_text": "<p>Collect and confirm detailed user requirements by coordinating with various project stakeholders.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "998350dd-f1d0-470c-9a81-6c6ace822836",
//                             "subdomain": "Planning & Design",
//                             "mandatory_question": 33,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 9,
//                             "is_active": 1,
//                             "competency": "Project initiation planning",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "ee2c9311-5849-4831-9602-c81648de1463",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "2bd14841-8f91-4f5f-a469-c00aecc3c28f",
//                             "question_text": "<p>Provide cost model information, phase estimates and cash flow forcasts<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "998350dd-f1d0-470c-9a81-6c6ace822836",
//                             "subdomain": "Planning & Design",
//                             "mandatory_question": 33,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 80,
//                             "is_active": 1,
//                             "competency": "Budget planning",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "f949b374-9660-4fab-9366-60523e8c476a",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "fef4e5a6-0cb3-4e58-a61d-25e423852c69",
//                             "question_text": "<p>Support management in working with other groups and stakeholders to identify user requirements.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "998350dd-f1d0-470c-9a81-6c6ace822836",
//                             "subdomain": "Planning & Design",
//                             "mandatory_question": 33,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 23,
//                             "is_active": 1,
//                             "competency": "Project initiation planning",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         }
//                     ],
//                     "Execution & Delivery": [
//                         {
//                             "id": "1c1762d3-542d-4a81-a456-4c4c604b3017",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "08448965-548a-405f-a6b7-7b63bf9ba80c",
//                             "question_text": "<p>Review and report on project outcomes.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "c5afed8e-2dde-4bab-95cc-5d71051ff298",
//                             "subdomain": "Execution & Delivery",
//                             "mandatory_question": 12,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 148,
//                             "is_active": 1,
//                             "competency": "Project management",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "2aff26b0-3761-4772-b784-b65733460ff7",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "249bfec2-1f10-4c52-af1c-3477271e53a8",
//                             "question_text": "<p>Participate in team meetings discussing project scopes, schedules and budgets.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "c5afed8e-2dde-4bab-95cc-5d71051ff298",
//                             "subdomain": "Execution & Delivery",
//                             "mandatory_question": 12,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 172,
//                             "is_active": 1,
//                             "competency": "Project management",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "462c4111-47e9-47cb-bb78-75c8b6bb1748",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "029c791f-a9be-412f-9688-8cc09b16b70c",
//                             "question_text": "<p>Monitor and update the status of a project to ensure completion within the project budget, timeline, and quality standards.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "c5afed8e-2dde-4bab-95cc-5d71051ff298",
//                             "subdomain": "Execution & Delivery",
//                             "mandatory_question": 12,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 162,
//                             "is_active": 1,
//                             "competency": "Project management",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "4791cb1f-1d14-47df-b65a-021fc3f28251",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "b59f9cde-429b-4460-8c45-d6c9aca1814f",
//                             "question_text": "<p>Assess change requests to determine impacts on scope, budget, schedule, quality, and risk.  Advise on contingency use and schedule recovery<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "c5afed8e-2dde-4bab-95cc-5d71051ff298",
//                             "subdomain": "Execution & Delivery",
//                             "mandatory_question": 12,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 229,
//                             "is_active": 1,
//                             "competency": "Change order",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "5c68b93c-f9de-44bb-83c4-acc6717a2463",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "e0e1eec6-770f-4735-b6c5-9ea66aa0ef55",
//                             "question_text": "<p>Coordinate and process project invoices.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "c5afed8e-2dde-4bab-95cc-5d71051ff298",
//                             "subdomain": "Execution & Delivery",
//                             "mandatory_question": 12,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 183,
//                             "is_active": 1,
//                             "competency": "Project management",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "60ff9fe1-4b1d-46a0-84be-64f742fbe86b",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "3ab4513a-de15-4e6c-953b-eb36e01951e6",
//                             "question_text": "<p>Collect and report on progress information on projects.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "c5afed8e-2dde-4bab-95cc-5d71051ff298",
//                             "subdomain": "Execution & Delivery",
//                             "mandatory_question": 12,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 178,
//                             "is_active": 1,
//                             "competency": "Project management",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "92502d9e-662a-461b-bfa3-92fb31d6eab9",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "dbbb98c3-5d27-4d73-8e51-f1dae6b5cbb5",
//                             "question_text": "<p>Monitor project scope and design that meets budget, standards and quality. Analyze results of consultant submission<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "c5afed8e-2dde-4bab-95cc-5d71051ff298",
//                             "subdomain": "Execution & Delivery",
//                             "mandatory_question": 12,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 155,
//                             "is_active": 1,
//                             "competency": "Project management",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "9e44953b-b5ae-4334-9c01-80c4961bfbd3",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "507b8e40-38f1-4e46-b96d-6e8314f4b767",
//                             "question_text": "<p>Confirm project financials are accurate and closed according to organization policy.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "c5afed8e-2dde-4bab-95cc-5d71051ff298",
//                             "subdomain": "Execution & Delivery",
//                             "mandatory_question": 12,
//                             "question_type": "Established",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 170,
//                             "is_active": 1,
//                             "competency": "Project management",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "ae58d9f0-fb5d-409b-a9bd-458637a2a346",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "b6d12a04-f7a6-48b6-87e2-377fc8696cf3",
//                             "question_text": "<p>Provide master advice, recommendations, approve change process, and manage execution of change orders.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "c5afed8e-2dde-4bab-95cc-5d71051ff298",
//                             "subdomain": "Execution & Delivery",
//                             "mandatory_question": 12,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 226,
//                             "is_active": 1,
//                             "competency": "Change order",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "b3515a75-a409-4d52-a049-7406bf6444c2",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "35bb5a2a-1cd6-4694-814a-9644dc381cc2",
//                             "question_text": "<p>Confirm that project scopes, schedules and budgets are created in ways that maximize the likelihood of project success.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "c5afed8e-2dde-4bab-95cc-5d71051ff298",
//                             "subdomain": "Execution & Delivery",
//                             "mandatory_question": 12,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 141,
//                             "is_active": 1,
//                             "competency": "Project management",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "cae87a6a-f7ee-46d0-b222-1c6b98c82dd7",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "c81c1880-3d28-4600-822c-f2c3fd851ab1",
//                             "question_text": "<p>Review change requests to determine impacts on scope, budget, schedule, quality, and risk.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "c5afed8e-2dde-4bab-95cc-5d71051ff298",
//                             "subdomain": "Execution & Delivery",
//                             "mandatory_question": 12,
//                             "question_type": "Emerging",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 231,
//                             "is_active": 1,
//                             "competency": "Change order",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         },
//                         {
//                             "id": "e275e6bb-028b-42ac-a15f-3c0d662a09c8",
//                             "assessment_id": "6f2b8c3b-0a1d-448a-a41e-20ca2096b27c",
//                             "question_id": "bc5deeb8-5f47-4f39-8019-5ecfec8cc882",
//                             "question_text": "<p>Implement corrective measures on projects to ensure completion within the project budget, timeline, and quality standards and lead recovery schedules.<\/p>",
//                             "domain_id": "b11cd4e0-8fb7-4a5f-b36e-1ee3f9ceab92",
//                             "domain": "Project Management",
//                             "subdomain_id": "c5afed8e-2dde-4bab-95cc-5d71051ff298",
//                             "subdomain": "Execution & Delivery",
//                             "mandatory_question": 12,
//                             "question_type": "Accomplished",
//                             "rating": null,
//                             "rating_text": null,
//                             "is_mark_as_review": 0,
//                             "question_provider_id": 145,
//                             "is_active": 1,
//                             "competency": "Project management",
//                             "domain_seqeunce": 10,
//                             "subdomain_seqeunce": null,
//                             "question_seqeunce": null
//                         }
//                     ]
//                 }
//             }
//         }
    
//     this.globals.isLoading = false;
    
//     this.rowAssessmentData.domain = this.rowAssessmentData.domain;
//     this.rowAssessmentData.domain.forEach((d_element, d_index, d_arr) => {
//       d_element.subdomain = d_element.subdomain;
//     });
//               this.prepareData();

//     // this.AssessmentService.getQuestions()
//     //   .then((data: any) => {
//     //     this.rowAssessmentData = data['data'][0];
//     //     this.globals.isLoading = false;
//     //     this.rowAssessmentData.domain = this.rowAssessmentData.domain;
//     //     this.rowAssessmentData.domain.forEach((d_element, d_index, d_arr) => {
//     //       d_element.subdomain = d_element.subdomain;
//     //     });
//     //     this.prepareData();
//     //   },
//     //     (error) => {
//     //       this.globals.isLoading = false;
//     //       this.router.navigate(['/assessment/my-assessments']);
//     //     });

//   }


  backToSubscription() {
    this.router.navigate(['/assessment/my-assessments']);


  }

  backToMain() {

      window.location.href =this.globals.dcmUrl +(['/assessment/purchase-subscription/',0]);

  }

  checkDomainSubdomainMatch(data: any) {
    const domains = data.domain;
    const questions = data.questions;

    const report: string[] = [];

    // Check if subdomains in domains array are present in questions
    domains.forEach((domain: any) => {
      const domainName = domain.name;

      if (!questions[domainName]) {
        report.push(`Domain "${domainName}" in domain array but missing in questions array.`);
      } else {
        const domainQuestions = questions[domainName];
        const subdomainNamesInQuestions = Object.keys(domainQuestions);

        domain.subdomain.forEach((subdomain: any) => {
          const subdomainName = subdomain.name;

          if (!domainQuestions[subdomainName]) {
            report.push(`Subdomain "${subdomainName}" under domain "${domainName}" is in domain array but missing in questions.`);
          }
        });

        // Check for subdomains in questions that are missing in domain array
        domain.subdomain.forEach((subdomain: any) => {
          const subdomainName = subdomain.name;

          if (!subdomainNamesInQuestions.includes(subdomainName)) {
            report.push(`Subdomain "${subdomainName}" exists in questions but not in domains.`);
          }
        });
      }
    });

    // Check if any domain in questions is missing in domain array
    Object.keys(questions).forEach((domainName: string) => {
      const foundDomain = domains.some((domain: any) => domain.name === domainName);

      if (!foundDomain) {
        report.push(`Domain "${domainName}" in questions array but missing in domains array.`);
      }
    });

    // Check for subdomains in questions that are missing in domain subdomain array
    Object.keys(questions).forEach((domainName: string) => {
      const domainQuestions = questions[domainName];
      const domain = domains.find((d: any) => d.name === domainName);

      if (domain) {
        const subdomainNamesInDomain = domain.subdomain.map((subdomain: any) => subdomain.name);
        Object.keys(domainQuestions).forEach((subdomainName: string) => {
          if (!subdomainNamesInDomain.includes(subdomainName)) {
            report.push(`Subdomain "${subdomainName}" exists in questions under domain "${domainName}" but not in domain subdomain array.`);
          }
        });
      }
    });

    if (report.length === 0) {
    } else {
      report.forEach((issue: string) => console.error(issue));
    }
  }

  getConfigData() {
    this.globals.isLoading = true;
    this.ConfigurationService.getAll({ "key": ["Rating"], "status": "1" })
      .then((data: any) => {
        this.ratingList = data.sort(this.sortRating);
      },
        (error) => {
          this.globals.isLoading = false;
        });
  }

  prepareData() {
    this.assessmentDetails.assign_date = this.rowAssessmentData.assign_date;
    this.assessmentDetails.no_of_question = 0;
    this.assessmentDetails.attempt_question = 0;
    this.assessmentDetails.progress = 0;
    this.domainData = this.rowAssessmentData.domain;

    Object.entries(this.rowAssessmentData.questions).forEach((d_element, d_index, d_arr) => {
      var domain = this.domainData[d_index];
      domain.no_of_question = 0;
      domain.attempt_question = 0;
      domain.progress = 0;
      Object.entries(d_element[1]).forEach((sd_element, sd_index, sd_arr) => {
        if(domain.subdomain[sd_index]!==undefined) {
          domain.subdomain[sd_index].questions = sd_element[1];

          domain.subdomain[sd_index].mandatory_question = domain.subdomain[sd_index].questions[0].mandatory_question;
        }
        // else{

        // }

      });
      if (domain.subdomain.length > 0) {
        this.assessmentData.push(domain);

      }
    });
    this.checkProgress();
    this.getDomain();
  }

  getDomain() {
    $("#domain_block").show();
    $("#subdomain_block").hide();
    $("#item_block").hide();
    this.domain_block = true;
    this.subdomain_block = false;
    this.item_block = false;
    $("#help_btn").hide();
    this.currentDomain = { 'subdomain': [] };

    // domain loop
    this.assessmentData.forEach((d_element, d_index, d_arr) => {
      setTimeout(() => {
        $('#Domain' + d_index).circleProgress({
          value: d_element.progress / 100,
          size: 50.0,
          emptyFill: '#ccc',
          fill: { gradient: ['#6e61aa', '#209cb7'] }
        }).on('circle-animation-progress', function (event) {
          $(this).find('strong').html('<span>' + d_element.progress + '%</span>');
        });
      }, 200);
    });
    $('.owl.carousel').trigger('destroy.owl.carousel');
    $('#main-block').data('owlCarousel')?.destroy();
    $('#thumbs-block').data('owlCarousel')?.destroy();
  }

  domainChangeFormSubd(domain, index) {
    this.domain_index = index;
    this.currentDomain = domain;
    if (this.HideCompleted && this.currentDomain.progress < 100) {
      this.currentDomain.subdomain1 = this.currentDomain.subdomain.filter(
        s => s.progress < 100
      );
    } else {
      this.currentDomain.subdomain1 = this.currentDomain.subdomain;
    }
  }

  domainChangeFormBtn(index) {

    this.domain_index = index;

    this.currentDomain = this.assessmentData[index];
    if (this.HideCompleted && this.currentDomain.progress < 100) {
      this.currentDomain.subdomain1 = this.currentDomain.subdomain.filter(
        s => s.progress < 100
      );
    } else {
      this.currentDomain.subdomain1 = this.currentDomain.subdomain;
    }
    $('.domainc').removeClass('active');
    $('#assdomain' + this.domain_index + '-tab').addClass('active');
    this.currentDomain.subdomain1.forEach((sd_element, sd_index, sd_arr) => {
      setTimeout(() => {
        $('#SubDomainCard' + sd_index).circleProgress({
          value: sd_element.progress / 100,
          size: 50.0,
          emptyFill: '#ccc',
          fill: { gradient: ['#6e61aa', '#209cb7'] }
        }).on('circle-animation-progress', function (event) {
          $(this).find('strong').html('<span>' + sd_element.progress + '%</span>');
        });
        $('#SubDomainCardMobile' + sd_index).circleProgress({
          value: sd_element.progress / 100,
          size: 50.0,
          emptyFill: '#ccc',
          fill: { gradient: ['#6e61aa', '#209cb7'] }
        }).on('circle-animation-progress', function (event) {
          $(this).find('strong').html('<span>' + sd_element.progress + '%</span>');
        });
        $('#SubDomain' + sd_index).circleProgress({
          value: sd_element.progress / 100,
          size: 50.0,
          emptyFill: '#ccc',
          fill: { gradient: ['#6e61aa', '#209cb7'] }
        }).on('circle-animation-progress', function (event) {
          $(this).find('strong').html('<span>' + sd_element.progress + '%</span>');
        });
      }, 200);
    });
    $('.owl.carousel').trigger('destroy.owl.carousel');
    $('#main-block').data('owlCarousel').destroy();
    $('#thumbs-block').data('owlCarousel').destroy();
    this.loadSlider(0);
  }

  domainChangeFromItem(domain, index) {
    this.domain_index = index;
    this.currentDomain = domain;
    if (this.HideCompleted && this.currentDomain.progress < 100) {
      this.currentDomain.subdomain1 = this.currentDomain.subdomain.filter(
        s => s.progress < 100
      );
    } else {
      this.currentDomain.subdomain1 = this.currentDomain.subdomain;
    }
    this.currentDomain.subdomain1.forEach((sd_element, sd_index, sd_arr) => {
      this.globals.isLoading = true;
      setTimeout(() => {
        $('#SubDomainCard' + sd_index).circleProgress({
          value: sd_element.progress / 100,
          size: 50.0,
          emptyFill: '#ccc',
          fill: { gradient: ['#6e61aa', '#209cb7'] }
        }).on('circle-animation-progress', function (event) {
          $(this).find('strong').html('<span>' + sd_element.progress + '%</span>');
        });
        $('#SubDomainCardMobile' + sd_index).circleProgress({
          value: sd_element.progress / 100,
          size: 50.0,
          emptyFill: '#ccc',
          fill: { gradient: ['#6e61aa', '#209cb7'] }
        }).on('circle-animation-progress', function (event) {
          $(this).find('strong').html('<span>' + sd_element.progress + '%</span>');
        });
        $('#SubDomain' + sd_index).circleProgress({
          value: sd_element.progress / 100,
          size: 50.0,
          emptyFill: '#ccc',
          fill: { gradient: ['#6e61aa', '#209cb7'] }
        }).on('circle-animation-progress', function (event) {
          $(this).find('strong').html('<span>' + sd_element.progress + '%</span>');
        });
      }, 200);
    });

    $('.owl.carousel').trigger('destroy.owl.carousel');
    $('#main-block').data('owlCarousel').destroy();
    $('#thumbs-block').data('owlCarousel').destroy();
    this.loadSlider(0);
  }

  getSubDomain(domain, index) {
    $("#domain_block").hide();
    $("#subdomain_block").show();
    $("#item_block").hide();
    this.domain_block = false;
    this.subdomain_block = true;
    this.item_block = false;
    $("#help_btn").hide();
    this.currentDomain = domain;
    if (this.HideCompleted && this.currentDomain.progress < 100) {
      this.currentDomain.subdomain1 = this.currentDomain.subdomain.filter(
        s => s.progress < 100
      );
    } else {
      this.currentDomain.subdomain1 = this.currentDomain.subdomain;
    }

    this.domain_index = index;
    $('.domainc').removeClass('active');
    $('#domain' + this.domain_index + '-tab').addClass('active');
    for (let i = 0; i < this.assessmentData.length; i++) {
      setTimeout(() => {
        var progress = this.assessmentData[i].progress;
        $('#domain_circle' + i).circleProgress({
          value: progress / 100,
          size: 50.0,
          emptyFill: '#ccc',
          fill: { gradient: ['#6e61aa', '#209cb7'] }
        }).on('circle-animation-progress', function (event) {
          $(this).find('strong').html('<span>' + progress + '%</span>');
        });
        $('#domain_circle_' + i).circleProgress({
          value: progress / 100,
          size: 50.0,
          emptyFill: '#ccc',
          fill: { gradient: ['#6e61aa', '#209cb7'] }
        }).on('circle-animation-progress', function (event) {
          $(this).find('strong').html('<span>' + progress + '%</span>');
        });
      }, 100);
    }
    setTimeout(() => {
      var progress = this.currentDomain.progress;
      $('#current_domain_circle').circleProgress({
        value: progress / 100,
        size: 50.0,
        emptyFill: '#ccc',
        fill: { gradient: ['#6e61aa', '#209cb7'] }
      }).on('circle-animation-progress', function (event) {
        $(this).find('strong').html('<span>' + progress + '%</span>');
      });
    }, 200);
    this.currentDomain.subdomain1.forEach((sd_element, sd_index, sd_arr) => {
      setTimeout(() => {
        $('#SubDomainCard' + sd_index).circleProgress({
          value: sd_element.progress / 100,
          size: 50.0,
          emptyFill: '#ccc',
          fill: { gradient: ['#6e61aa', '#209cb7'] }
        }).on('circle-animation-progress', function (event) {
          $(this).find('strong').html('<span>' + sd_element.progress + '%</span>');
        });
        $('#SubDomainCardMobile' + sd_index).circleProgress({
          value: sd_element.progress / 100,
          size: 50.0,
          emptyFill: '#ccc',
          fill: { gradient: ['#6e61aa', '#209cb7'] }
        }).on('circle-animation-progress', function (event) {
          $(this).find('strong').html('<span>' + sd_element.progress + '%</span>');
        });
        $('#SubDomain' + sd_index).circleProgress({
          value: sd_element.progress / 100,
          size: 50.0,
          emptyFill: '#ccc',
          fill: { gradient: ['#6e61aa', '#209cb7'] }
        }).on('circle-animation-progress', function (event) {
          $(this).find('strong').html('<span>' + sd_element.progress + '%</span>');
        });
      }, 200);
    });

    $('.owl.carousel').trigger('destroy.owl.carousel');
    $('#main-block').data('owlCarousel')?.destroy();
    $('#thumbs-block').data('owlCarousel')?.destroy();


  }

  getItems(currentItem) {
    $("#domain_block").hide();
    $("#subdomain_block").hide();
    $("#item_block").show();
    this.domain_block = false;
    this.subdomain_block = false;
    this.item_block = true;
    $("#help_btn").show();
    //if(index!=null){
    $('.domainc').removeClass('active');
    $('#assdomain' + this.domain_index + '-tab').addClass('active');
    //}   
    // $('.owl.carousel').trigger('destroy.owl.carousel');
    // $('#main-block').data('owlCarousel').destroy();
    // $('#thumbs-block').data('owlCarousel').destroy();

    if (this.HideCompleted && this.currentDomain.progress < 100) {
      this.currentDomain.subdomain1 = this.currentDomain.subdomain.filter(
        s => s.progress < 100
      );
    } else {
      this.currentDomain.subdomain1 = this.currentDomain.subdomain;
    }
    this.currentDomain.subdomain1.forEach((sd_element, sd_index, sd_arr) => {
      setTimeout(() => {
        $('#SubDomainCard' + sd_index).circleProgress({
          value: sd_element.progress / 100,
          size: 50.0,
          emptyFill: '#ccc',
          fill: { gradient: ['#6e61aa', '#209cb7'] }
        }).on('circle-animation-progress', function (event) {
          $(this).find('strong').html('<span>' + sd_element.progress + '%</span>');
        });
        $('#SubDomainCardMobile' + sd_index).circleProgress({
          value: sd_element.progress / 100,
          size: 50.0,
          emptyFill: '#ccc',
          fill: { gradient: ['#6e61aa', '#209cb7'] }
        }).on('circle-animation-progress', function (event) {
          $(this).find('strong').html('<span>' + sd_element.progress + '%</span>');
        });
        $('#SubDomain' + sd_index).circleProgress({
          value: sd_element.progress / 100,
          size: 50.0,
          emptyFill: '#ccc',
          fill: { gradient: ['#6e61aa', '#209cb7'] }
        }).on('circle-animation-progress', function (event) {
          $(this).find('strong').html('<span>' + sd_element.progress + '%</span>');
        });
      }, 200);
    });
    this.loadSlider(currentItem);
    if (this.assessmentDetails.attempt_question == 0) {
      this.help(currentItem);
    }
    let self = this;
    setTimeout(() => {
      self.nextPrevButton();
    }, 500);
  }

  changeHide(currentItem) {
    if (this.HideCompleted && this.currentDomain.progress < 100) {
      this.currentDomain.subdomain1 = this.currentDomain.subdomain.filter(
        s => s.progress < 100
      );
    } else {
      this.currentDomain.subdomain1 = this.currentDomain.subdomain;
    }

    this.currentDomain.subdomain1.forEach((sd_element, sd_index, sd_arr) => {
      setTimeout(() => {
        $('#SubDomainCard' + sd_index).circleProgress({
          value: sd_element.progress / 100,
          size: 50.0,
          emptyFill: '#ccc',
          fill: { gradient: ['#6e61aa', '#209cb7'] }
        }).on('circle-animation-progress', function (event) {
          $(this).find('strong').html('<span>' + sd_element.progress + '%</span>');
        });
        $('#SubDomainCardMobile' + sd_index).circleProgress({
          value: sd_element.progress / 100,
          size: 50.0,
          emptyFill: '#ccc',
          fill: { gradient: ['#6e61aa', '#209cb7'] }
        }).on('circle-animation-progress', function (event) {
          $(this).find('strong').html('<span>' + sd_element.progress + '%</span>');
        });
        $('#SubDomain' + sd_index).circleProgress({
          value: sd_element.progress / 100,
          size: 50.0,
          emptyFill: '#ccc',
          fill: { gradient: ['#6e61aa', '#209cb7'] }
        }).on('circle-animation-progress', function (event) {
          $(this).find('strong').html('<span>' + sd_element.progress + '%</span>');
        });
      }, 200);
    });

    $('.owl.carousel').trigger('destroy.owl.carousel');
    $('#main-block').data('owlCarousel')?.destroy();
    $('#thumbs-block').data('owlCarousel')?.destroy();


    this.loadSlider(currentItem);
  }

  setAnswer(question, subdomain, sd_index) {

    this.checkProgress();

    if (subdomain.attempt_question == 1) {
      let update_start_time = { 'assessment_id': this.assessmentDetails.id, 'domain_id': question.domain_id, 'subdomain_id': question.subdomain_id };
      this.AssessmentService.startDomain(update_start_time)
        .then((data) => {
          subdomain.start_time = data['data']['started_time'];
          if (this.currentDomain.attempt_question == 1) {
            this.currentDomain.start_time = subdomain.start_time;
          }
        },
          (error) => {
            this.globals.isLoading = false;
          });
    }

    let answer = { 'id': question.id, 'rating': question.rating, 'question_text': question.question_text, 'is_mark_as_review': 0 };
    this.AssessmentService.setAnswer(this.assessmentDetails.id, answer)
      .then((data) => {
        subdomain.updated_at = data['data']['updated_at'];
        this.currentDomain.updated_at = subdomain.updated_at;
      },
        (error) => {
          this.globals.isLoading = false;
        });

    var d_element = this.currentDomain;
    var sd_element = this.currentDomain.subdomain1[sd_index];
    if (this.HideCompleted && this.currentDomain.progress == 100) {
      this.currentDomain.subdomain1 = this.currentDomain.subdomain;
      $('.owl.carousel').trigger('destroy.owl.carousel');
      $('#main-block').data('owlCarousel').destroy();
      $('#thumbs-block').data('owlCarousel').destroy();
      this.currentDomain.subdomain1.forEach((sd_element, sd_index, sd_arr) => {
        setTimeout(() => {
          $('#SubDomainCard' + sd_index).circleProgress({
            value: sd_element.progress / 100,
            size: 50.0,
            emptyFill: '#ccc',
            fill: { gradient: ['#6e61aa', '#209cb7'] }
          }).on('circle-animation-progress', function (event) {
            $(this).find('strong').html('<span>' + sd_element.progress + '%</span>');
          });
          $('#SubDomainCardMobile' + sd_index).circleProgress({
            value: sd_element.progress / 100,
            size: 50.0,
            emptyFill: '#ccc',
            fill: { gradient: ['#6e61aa', '#209cb7'] }
          }).on('circle-animation-progress', function (event) {
            $(this).find('strong').html('<span>' + sd_element.progress + '%</span>');
          });
          $('#SubDomain' + sd_index).circleProgress({
            value: sd_element.progress / 100,
            size: 50.0,
            emptyFill: '#ccc',
            fill: { gradient: ['#6e61aa', '#209cb7'] }
          }).on('circle-animation-progress', function (event) {
            $(this).find('strong').html('<span>' + sd_element.progress + '%</span>');
          });
        }, 200);
      });
      this.loadSlider(sd_index);
    }

    setTimeout(() => {
      $('#current_domain_circle').circleProgress({
        value: d_element.progress / 100,
        size: 50.0,
        emptyFill: '#ccc',
        fill: { gradient: ['#6e61aa', '#209cb7'] }
      }).on('circle-animation-progress', function (event) {
        $(this).find('strong').html('<span>' + d_element.progress + '%</span>');
      });
      $('#SubDomainCard' + sd_index).circleProgress({
        value: sd_element.progress / 100,
        size: 50.0,
        emptyFill: '#ccc',
        fill: { gradient: ['#6e61aa', '#209cb7'] }
      }).on('circle-animation-progress', function (event) {
        $(this).find('strong').html('<span>' + sd_element.progress + '%</span>');
      });
      $('#SubDomainCardMobile' + sd_index).circleProgress({
        value: sd_element.progress / 100,
        size: 50.0,
        emptyFill: '#ccc',
        fill: { gradient: ['#6e61aa', '#209cb7'] }
      }).on('circle-animation-progress', function (event) {
        $(this).find('strong').html('<span>' + sd_element.progress + '%</span>');
      });
      $('#SubDomain' + sd_index).circleProgress({
        value: sd_element.progress / 100,
        size: 50.0,
        emptyFill: '#ccc',
        fill: { gradient: ['#6e61aa', '#209cb7'] }
      }).on('circle-animation-progress', function (event) {
        $(this).find('strong').html('<span>' + sd_element.progress + '%</span>');
      });
    }, 200);
    var height_div = $("#main-block .owl-item.synced").height();
    $("#main-block .owl-wrapper-outer").css("height", height_div);

    // $('.owl.carousel').trigger('destroy.owl.carousel');
    // $('#main-block').data('owlCarousel').destroy();
    // $('#thumbs-block').data('owlCarousel').destroy();
    // this.loadSlider(0);


    if (this.assessmentDetails.valid) {
      var popupShown = localStorage.getItem("isPopupShown");
      var dataObject = popupShown ? JSON.parse(popupShown) : null;

      if (!this.isEditAssesment && (!dataObject || dataObject.id !== this.assessmentDetails.id || dataObject.isPopupShown !== "true")) {
        Swal.fire({
          icon: 'success',
          title: "All Knowledge Statements Attempted",
          text: "You've successfully completed all knowledge statements.",
          confirmButtonText: 'Back to subscriptions',
        }).then((result) => {
          if (result.isConfirmed) {
            this.backToSubscription();
          }
        });

        var data = {
          id: this.assessmentDetails.id,
          isPopupShown: "true"
        };
        localStorage.setItem("isPopupShown", JSON.stringify(data));
      }
    }


  }

  submitConfirm() {
    this.globals.isLoading = true;
    this.AssessmentService.getDefaultQuestions(this.assessmentDetails.id)
      .then((data) => {
        this.SurveyQuestions = data['data'];
        if (this.SurveyQuestions[0].question_text == null || this.SurveyQuestions[0].question_text == '') {
          this.SurveyQuestions[0].question_text = 'Please indicate your primary practice area.';
          this.SurveyQuestions[0].answer_text = [];
        } else {
          this.SurveyQuestions[0].answer_text = (this.SurveyQuestions[0].answer_text) ? this.SurveyQuestions[0].answer_text.split(',') : [];
        }
        if (this.SurveyQuestions[1].question_text == null || this.SurveyQuestions[1].question_text == '') {
          this.SurveyQuestions[1].question_text = 'Please provide feedback on the competencies. We are particularly interested if competencies are missing.';
        }
        if (this.SurveyQuestions[2].question_text == null || this.SurveyQuestions[2].question_text == '') {
          this.SurveyQuestions[2].question_text = 'Please indicate how well the competencies were adequately covered?';
        }
        this.globals.isLoading = false;
        $('#QuestionModal').modal('toggle');
      },
        (error) => {
          this.globals.isLoading = false;
        });
  }

  submitFinal() {
    $('#QuestionModal').modal('toggle');
    this.globals.isLoading = true;
    this.AssessmentService.finalSubmit(this.assessmentDetails.id)
      .then((data) => {
        this.globals.isLoading = false;
        //this.globals.sweetAlert('success','Assessment Submitted','Assessment has been submitted successfully.');
        this.router.navigate(['/assessment/result/1/' + this.assessmentDetails.id]);
      },
        (error) => {
          this.globals.isLoading = false;
        });
  }

  saveDefaultQuestion(index) {
    var defaultQuestion = this.SurveyQuestions[index];
    var answer = (defaultQuestion.answer_text) ? ((defaultQuestion.answer_text.constructor === Array) ? ((defaultQuestion.answer_text.join() == '') ? null : defaultQuestion.answer_text.join()) : defaultQuestion.answer_text) : null;
    let entity = { 'assessment_id': this.assessmentDetails.id, "question_id": defaultQuestion.question_id, "question_text": defaultQuestion.question_text, "answer_text": answer };
    this.AssessmentService.submitDefaultQuestion(entity)
      .then((data) => {
      },
        (error) => {
          this.globals.isLoading = false;
        });
  }

  checkProgress() {
    this.assessmentDetails.valid = true;
    this.assessmentDetails.no_of_question = 0;
    this.assessmentDetails.attempt_question = 0;
    this.assessmentDetails.progress = 0;
    this.assessmentData?.forEach((d_element, d_index, d_arr) => {
      d_element.subdomain?.forEach((sd_element, sd_index, sd_arr) => {
        sd_element.questions?.forEach((q_element, q_index, q_arr) => {
          if (sd_index == 0 && q_index == 0) {
            d_element.no_of_question = 0;
            d_element.attempt_question = 0;
          }
          if (q_index == 0) {
            sd_element.no_of_question = 0;
            sd_element.attempt_question = 0;
          }
          this.assessmentDetails.no_of_question++;
          d_element.no_of_question++;
          sd_element.no_of_question++;
          if (q_element.rating != null && q_element.rating != '' && q_element.rating != undefined) {
            this.assessmentDetails.attempt_question++;
            d_element.attempt_question++;
            sd_element.attempt_question++;
          }
          d_element.progress = Math.round((d_element.no_of_question > 0) ? (d_element.attempt_question * 100) / d_element.no_of_question : 0);
          sd_element.progress = Math.round((sd_element.no_of_question > 0) ? (sd_element.attempt_question * 100) / sd_element.no_of_question : 0);
          sd_element.progress_of_required = Math.round((sd_element.mandatory_question > 0) ? (sd_element.attempt_question * 100) / sd_element.mandatory_question : 100);
          this.assessmentDetails.progress = Math.round((this.assessmentDetails.no_of_question > 0) ? (this.assessmentDetails.attempt_question * 100) / this.assessmentDetails.no_of_question : 0);
          if (sd_element.progress_of_required < 100 && ((q_index + 1) == q_arr.length)) {
            this.assessmentDetails.valid = false;
          }
        });
      });
    });
    for (let i = 0; i < this.assessmentData.length; i++) {
      setTimeout(() => {
        var progress = this.assessmentData[i].progress;
        $('#domain_circle' + i).circleProgress({
          value: progress / 100,
          size: 50.0,
          emptyFill: '#ccc',
          fill: { gradient: ['#6e61aa', '#209cb7'] }
        }).on('circle-animation-progress', function (event) {
          $(this).find('strong').html('<span>' + progress + '%</span>');
        });
        $('#domain_circle_' + i).circleProgress({
          value: progress / 100,
          size: 50.0,
          emptyFill: '#ccc',
          fill: { gradient: ['#6e61aa', '#209cb7'] }
        }).on('circle-animation-progress', function (event) {
          $(this).find('strong').html('<span>' + progress + '%</span>');
        });
      }, 100);
    }
  }

  loadSlider(index) {
    let self = this;
    setTimeout(function () {
      var sync1 = $("#main-block");
      var sync2 = $("#thumbs-block");
      sync1.owlCarousel({
        singleItem: true,
        responsive: true,
        autoPlay: false,
        slideSpeed: 1000,
        navigation: true,
        navText: [
          "<button class='my-prev-class'>Previous to Subdomain</button>",  // Custom class for previous button
          "<button class='my-next-class'>Next to Subdomain</button>"   // Custom class for next button
        ],
        // navigationText: ["Previous Subdomain", "Next Subdomain"],
        loop: false,
        rewindNav: false,
        mouseDrag: false,
        touchDrag: false,
        pagination: false,
        autoHeight: true,
        afterAction: syncPosition,
        responsiveRefreshRate: 200,
      });
      sync2.owlCarousel({
        items: 4,
        itemsDesktop: [1499, 3],
        itemsDesktopSmall: [979, 2],
        itemsTablet: [768, 1],
        itemsMobile: [479, 1],
        pagination: false,
        responsive: true,
        loop: false,
        autoHeight: true,
        rewindNav: false,
        navigation: true,
        mouseDrag: false,
        touchDrag: false,
        navigationText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
        responsiveRefreshRate: 100,
        afterInit: function (el) {
          el.find(".owl-item").eq(index).addClass("synced");
        }
      });
      function syncPosition(el) {
        var current = this.currentItem;
        $("#thumbs-block")
          .find(".owl-item")
          .removeClass("synced")
          .eq(current)
          .addClass("synced")
        if ($("#thumbs-block").data("owlCarousel") !== undefined) {
          center(current)
        }
        $("#main-block")
          .find(".owl-item")
          .removeClass("synced")
          .eq(current)
          .addClass("synced");
        var height_div = $("#main-block .owl-item.synced").height();
        $("#main-block .owl-wrapper-outer").css("height", height_div);
      }
      $("#thumbs-block").on("click", ".owl-item", function (e) {
        e.preventDefault();
        var number = $(this).data("owlItem");
        sync1.trigger("owl.goTo", number);
        self.nextPrevButton();
      });

      function center(number) {
        var sync2visible = sync2.data("owlCarousel").owl.visibleItems;
        var num = number;
        var found = false;
        for (var i in sync2visible) {
          if (num === sync2visible[i]) {
            var found = true;
          }
        }
        if (found === false) {
          if (num > sync2visible[sync2visible.length - 1]) {
            sync2.trigger("owl.goTo", num - sync2visible.length + 2)
          } else {
            if (num - 1 === -1) {
              num = 0;
            }
            sync2.trigger("owl.goTo", num);
          }
        } else if (num === sync2visible[sync2visible.length - 1]) {
          sync2.trigger("owl.goTo", sync2visible[1])
        } else if (num === sync2visible[0]) {
          sync2.trigger("owl.goTo", num - 1)
        }
      }

      $('#thumbs-block .owl-next').click(function () {
      
        $("#main-block").trigger('owl.next');
        var height_div = $("#main-block .owl-item.synced").height();
        $("#main-block .owl-wrapper-outer").css("height", height_div);
        self.nextPrevButton();
        window.scrollTo({ top: 0, behavior: 'smooth' });
      });
      $('#thumbs-block .owl-prev').click(function () {
        $("#main-block").trigger('owl.prev');
        var height_div = $("#main-block .owl-item.synced").height();
        $("#main-block .owl-wrapper-outer").css("height", height_div);
        self.nextPrevButton();
        window.scrollTo({ top: 0, behavior: 'smooth' });
      });

      $('.owl-next').click(function () {
        var height_div = $("#main-block .owl-item.synced").height();
        $("#main-block .owl-wrapper-outer").css("height", height_div);
        self.nextPrevButton();
        window.scrollTo({ top: 0, behavior: 'smooth' });
      });
      $('.owl-prev').click(function () {
        var height_div = $("#main-block .owl-item.synced").height();
        $("#main-block .owl-wrapper-outer").css("height", height_div);
        self.nextPrevButton();
        window.scrollTo({ top: 0, behavior: 'smooth' });
      });

      var height_div = $("#main-block .owl-item.synced").height();
      $("#main-block .owl-wrapper-outer").css("height", height_div);

    }, 1000);

    setTimeout(() => {
      self.nextPrevButton();
    }, 100);
    setTimeout(() => {
      var sync1 = $("#main-block");
      var current = index;
      $("#thumbs-block")
        .find(".owl-item")
        .removeClass("synced")
        .eq(current)
        .addClass("synced");
      sync1.trigger("owl.goTo", index);
      this.globals.isLoading = false;
    }, 1000);
  }

  nextPrevButton() {
    $('#prev_btn').hide();
    $('#next_btn').hide();
    if ($('.synced .item').hasClass('firstdomain_last')) {
      $('#next_btn').show();
    } else {
      $('#next_btn').hide();
    }
    if ($('.synced .item').hasClass('seconddomain_first')) {
      $('#prev_btn').show();
    } else {
      $('#prev_btn').hide();
    }

  

   

  }

  getNextDomainButton(){
    if ($('.synced .item').hasClass('firstdomain_last') && (this.domain_index - (this.assessmentData.length - 1) != 0) ){
      
     return true;
    } else {
      return false;
    }
  }

  getPreviousDomainButton() {
    if (this.domain_index == 0) {
      return false;
    }else{
      
    }

    if ($('.synced .item').hasClass('seconddomain_first') && (this.domain_index + (this.assessmentData.length + 1) != 0)) {
      return true;
    } else {
      return false;
    }
  }

  sortRating(a, b) {
    const valueA = a.value.toUpperCase();
    const valueB = b.value.toUpperCase();
    let comparison = 0;
    if (valueA > valueB) {
      comparison = 1;
    } else if (valueA < valueB) {
      comparison = -1;
    }
    return comparison;
  }

  sortDomain(a, b) {
    const valueA = a.name.toUpperCase();
    const valueB = b.name.toUpperCase();
    let comparison = 0;
    if (valueA > valueB) {
      comparison = 1;
    } else if (valueA < valueB) {
      comparison = -1;
    }
    return comparison;
  }

  sortSubDomain(a, b) {
    const valueA = a[0].toUpperCase();
    const valueB = b[0].toUpperCase();
    let comparison = 0;
    if (valueA > valueB) {
      comparison = 1;
    } else if (valueA < valueB) {
      comparison = -1;
    }
    return comparison;
  }

  public isSurveyQuestionOptionsSelected(i) {
    return this.SurveyQuestions[0].answer_text.some((item) => item === i);
  }

  viewChange(data) {
    if (data == true) {
      if (this.IsGridView) {
        this.IsGridView = false;
      }
    } else {
      if (!this.IsGridView) {
        this.IsGridView = true;
      }
    }
  }

  getSubdomainName(subdomain: string) {
    return subdomain.slice(0, 60) + (subdomain.length > 60 ? '...' : '');
  }


}
