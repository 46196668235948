import { Component, OnInit } from '@angular/core';
import { Globals } from '../globals';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
declare var $, Swal: any;
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  ip=null;

  constructor(public globals: Globals, private router: Router, private AuthService: AuthService, 
    private formBuilder: UntypedFormBuilder, private http: HttpClient, private route: ActivatedRoute, private CookieService: CookieService) { }

  ngOnInit(): void { 
    // debugger
    
    // localStorage.removeItem('auth_user');
    this.CookieService.delete('auth_user','/', this.globals.CookieDomainUrl);

    this.CookieService.delete('expires_at','/', this.globals.CookieDomainUrl);
    this.CookieService.delete('access_token','/', this.globals.CookieDomainUrl);
    this.CookieService.delete('user_token', '/', this.globals.CookieDomainUrl);
    this.CookieService.delete('token_type','/', this.globals.CookieDomainUrl);
    this.CookieService.delete('institute_details','/', this.globals.CookieDomainUrl);
    this.CookieService.delete('current_role_value','/', this.globals.CookieDomainUrl);

    localStorage.removeItem('last_updated_date_role_permission');
    localStorage.removeItem('all_role_permission');
    localStorage.removeItem('user_role_permission');

    // this.AuthService.getIP()
    //   .then((data) => {
    //     this.ip = data['ip'];
    //   }, (error) => {
    //     this.globals.isLoading = false;
    //     // this.globals.errorSweetAlert();
    // });

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z]{1}[a-zA-Z0-9.\-_]*@[a-zA-Z]{1}[a-zA-Z.-]*[a-zA-Z]{1}[.][a-zA-Z]{2,4}$')]],
      password: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9_@$*&!#\-]{8,50}')]],
      ip_address:[this.ip],
      user_agent: [navigator.userAgent]
    });
    this.globals.isLoading = false;

    $("#password-show").click(function () {
      $(this).toggleClass("fa-eye fa-eye-slash")
      if ($("#password").attr("type") == "password") {
        $("#password").attr("type", "text");
      }
      else {
        $("#password").attr("type", "password");
      }
    });
  }

  login() { 
    // debugger
    this.loginForm.markAllAsTouched();
    if (this.loginForm.valid) {
      this.globals.isLoading = true;
      this.loginForm.controls['ip_address'].patchValue(this.ip);
      this.AuthService.checkOESSAdminLogin(this.loginForm.value)
        .then((data) => {
          this.AuthService.getAuthUser()
            .then((data) => {
              this.router.navigate(['/landing-dashboard']);
            }, (error) => {
              this.globals.isLoading = false;
              this.globals.errorSweetAlert();
          });
        },
        (error) => {
          this.globals.isLoading = false;
          if (error.status == 400) {
            this.globals.sweetAlert('warning','Deactive','Your account is deactive. so you cannot login.');
          } else if (error.status == 422 || error.status == 401) {
            this.globals.sweetAlert('warning','Invalid Login Credentials','Either email or password is incorrect.');
          } else {
            this.globals.errorSweetAlert();
          }
        });
    }
  }

}
