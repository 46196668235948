import { Injectable } from '@angular/core';
//import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../environments/environment';
import { HttpClient } from "@angular/common/http";
import { CookieService } from 'ngx-cookie-service';
declare var $, Swal: any;


import permissionText from "../assets/data/permissionTranslationText.json";

@Injectable()
export class Globals {

  constructor(private http: HttpClient, private CookieService: CookieService) { }

  baseAPIUrl: string = environment.apiUrl;
  baseUrl: string = environment.baseUrl;
  cndURL: string = environment.cndURL;
  TestMode: boolean = environment.TestMode;
  covidUrl: string = environment.covidUrl;
  dcmUrl: string = environment.dcmUrl;
  connectorUrl: string = environment.connectorUrl;
  adminUrl: string = environment.adminUrl;
  amsnLoginUrl: string = environment.ssoLoginUrl+environment.baseUrl+'/authentication';
  amsnLogoutUrl: string = environment.ssoLogoutUrl+environment.baseUrl+'/authentication';
  // amsnLoginUrl: string = 'https://login.ifma.org';
  // amsnLogoutUrl: string = 'https://login.ifma.org';
  headerpath: string = "{'Content-Type': 'application/json','Accept': 'application/json'}";
  IsLoggedIn: boolean = false;
  isLoading: boolean = false;
  // authData = localStorage.getItem('auth_user') ? JSON.parse(window.atob(localStorage.getItem('auth_user'))) : null;
  authData = this.CookieService.get('auth_user') ? JSON.parse(window.atob(this.CookieService.get('auth_user'))) : null;
  todaysdate: string = '';
  institute_details = this.CookieService.get('institute_details') ? JSON.parse(window.atob(this.CookieService.get('institute_details'))) : null;
  current_product = this.CookieService.get('current_product') ? JSON.parse(window.atob(this.CookieService.get('current_product'))) : null;
  institute_id = this.CookieService.get('institute_details') && JSON.parse(window.atob(this.CookieService.get('institute_details'))).institute ? JSON.parse(window.atob(this.CookieService.get('institute_details'))).institute.id : null;
  //product_details = this.CookieService.get('product_details') ? JSON.parse(window.atob(this.CookieService.get('product_details'))) : null;
  sweetalertmessage: string = "<span class='close_msg'>This message box will auto close in <b></b>seconds!</span>";
  sweetalerttimer: number = 5000;
  permissionText = permissionText;
  permissions = {};
  maximumSearchCriteriaDisplay = 4;
  CookieDomainUrl: string = environment.CookieDomainUrl;

  sweetAlert(icon, title, html) {
    let timerInterval;
    Swal.fire({
      icon: icon,
      title: title,
      showConfirmButton: true,
      timer: this.sweetalerttimer,
      html: html + this.sweetalertmessage,
      timerProgressBar: true,
      didOpen: () => {
        // timerInterval = setInterval(() => {
        //   const content = Swal.getContent()
        //   if (content) {
        //     const b = content.querySelector('b')
        //     if (b) {
        //       b.textContent = (Swal.getTimerLeft() / 1000).toFixed()
        //     }
        //   }
        // }, 100)
        const b = Swal.getHtmlContainer().querySelector('b')
        timerInterval = setInterval(() => {
          b.textContent = (Swal.getTimerLeft() / 1000).toFixed()
        }, 100)

      },
      willClose: () => {
        clearInterval(timerInterval)
      }
    });
  }

  errorSweetAlert() {
    // let timerInterval;
    // Swal.fire({
    //   icon: 'error',
    //   title: 'Oops...',
    //   showConfirmButton: true,
    //   timer: this.sweetalerttimer,
    //   html: 'Something went wrong.' + this.sweetalertmessage,
    //   timerProgressBar: true,
    //   onBeforeOpen: () => {
    //     timerInterval = setInterval(() => {
    //       const content = Swal.getContent()
    //       if (content) {
    //         const b = content.querySelector('b')
    //         if (b) {
    //           b.textContent = (Swal.getTimerLeft() / 1000).toFixed()
    //         }
    //       }
    //     }, 100)
    //   },
    //   onClose: () => {
    //     clearInterval(timerInterval)
    //   }
    // });
  }

}
